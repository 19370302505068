import env from '../env'
import axios from 'axios'

const api_url = env.api;

const api = {

    // Auth
    authCheck: async () => {
        return axios
            .post(api_url + 'Auth/', {
                funct: 'check'
            })
            .then(response => {
                return response.data;
            });
    },
    authLogin: async (email, password) => {
        return axios
            .post(api_url + 'Auth/', {
                funct: 'login',
                email,
                password
            })
            .then(response => {
                if (response.status=='200')
                    return response.data;
                else
                    return false;
            });
    },
    authLogout: async () => {
        return axios
            .post(api_url + 'Auth/', {
                funct: 'logout'
            })
            .then(response => {
                return response.data;
            });
    },

    log: {
        getAll: async () => {
            return axios
                .post(api_url + 'Log/', {
                    funct: 'getAll'
                })
                .then(response => {
                    return response.data;
                });
        },
    },

    // News
    publishNews: async form => {
        return axios
            .post(api_url + 'News/', {
                funct: 'publish',
                title: form.title,
                description: form.description,
                content: form.content,
                user_level: form.level_required,
            })
            .then(response => {
                if (response.status=='200')
                    return response.data;
                else
                    return false;
            });
    },

    logVehicle: {

        createNew: async values => {
            return axios.post(api_url + 'LogVehicle/', {funct: 'createNew', values})
                .then(response => {
                    return response.data;
                });
        },


        getAll: async () => {
            return axios.post(api_url + 'LogVehicle/', {funct: 'getAll'})
                .then(response => {
                    return response.data;
                });
        },

        getAllVehicle: async () => {
            return axios.post(api_url + 'LogVehicle/', {funct: 'getAllVehicle'})
                .then(response => {
                    return response.data;
                });
        },

        getLogVehicleFromUser: async () => {
            return axios.post(api_url + 'LogVehicle/', {funct: 'getLogVehicleFromUser'})
                .then(response => {
                    return response.data;
                });
        },

           getFromId: async (id) => {
               return axios.post(api_url + 'LogVehicle/', {funct: 'getFromId', id})
                   .then(response => {
                       return response.data;
                   });
           },


        deleteLogVehicle: async id => {
            return axios.post(api_url + 'LogVehicle/', {funct: 'deleteLogVehicle', id})
                .then(response => {
                    return response.data;
                });
        },


        /*
        getFromId: async id => {
            return axios.post(api_url + 'Memo/', {funct: 'getFromId', id})
                .then(response => {
                    return response.data;
                });
        },
        */

        updateLogVehicle: async values => {
            // Adjust values to controller
            return axios.post(api_url + 'LogVehicle/', {funct: 'updateLogVehicle', values})
                .then(response => {
                    return response.data;
                });
        },

    },

    box: {
        getAll: async () => {
            return axios.post(api_url + 'Box/', {funct: 'getAll'})
                .then(response => {
                    return response.data;
                });
        },

        getLog: async () => {
            return axios.post(api_url + 'Box/', {funct: 'getLog'})
                .then(response => {
                    return response.data;
                });
        },

        getOrders: async () => {
            return axios.post(api_url + 'Box/', {funct: 'getOrders'})
                .then(response => {
                    return response.data;
                });
        },

        insert: async (values, files) => {
            let formData = new FormData();
            formData.append('funct', 'insert');

            if (files.length>0) {
                files.forEach(file => {
                    formData.append('files[]', file);
                });
            }

            formData.append('values', JSON.stringify(values));

            return axios
            .post(api_url + 'Box/', formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                if (response.status=='200') {
                    return response.data;
                } else {
                    return false;
                }
            });
        },

        getLine: async (id_line) => {
            return axios.post(api_url + 'Box/', {funct: 'getLine', id_line})
                .then(response => {
                    return response.data;
                });
        },

        modify: async (id_line, values, files) => {
            let formData = new FormData();
            formData.append('funct', 'modify');

            if (files.length>0) {
                files.forEach(file => {
                    formData.append('files[]', file);
                });
            }

            formData.append('id_line', id_line);
            formData.append('values', JSON.stringify(values));

            return axios
            .post(api_url + 'Box/', formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                if (response.status=='200') {
                    return response.data;
                } else {
                    return false;
                }
            });
        },

        delete: async id_line => {
            return axios.post(api_url + 'Box/', {funct: 'delete', id_line})
                .then(response => {
                    return response.data;
                });
        },

    },

    logWorkers: {

        createNew: async values => {
            return axios.post(api_url + 'LogWorkers/', {funct: 'createNew', values})
                .then(response => {
                    return response.data;
                });
        },

        getAll: async () => {
            return axios.post(api_url + 'LogWorkers/', {funct: 'getAll'})
                .then(response => {
                    return response.data;
                });
        },

        getAllWorkers: async () => {
            return axios.post(api_url + 'LogWorkers/', {funct: 'getAllWorkers'})
                .then(response => {
                    return response.data;
                });
        },

        getLogWorkersFromUser: async () => {
            return axios.post(api_url + 'LogWorkers/', {funct: 'getLogWorkersFromUser'})
                .then(response => {
                    return response.data;
                });
        },

        getFromId: async (id) => {
            return axios.post(api_url + 'LogWorkers/', {funct: 'getFromId', id})
                .then(response => {
                    return response.data;
                });
        },


        deleteLogWorkers: async id => {
            return axios.post(api_url + 'LogWorkers/', {funct: 'deleteLogWorkers', id})
                .then(response => {
                    return response.data;
                });
        },



        updateLogWorkers: async values => {
            // Adjust values to controller
            return axios.post(api_url + 'LogWorkers/', {funct: 'updateLogWorkers', values})
                .then(response => {
                    return response.data;
                });
        },

    },

    // News
    getNews: async (page, userLevel) => {
        return axios
            .post(api_url + 'News/', {
                // Post data
                funct: 'getNews',
                page,
                user_level: userLevel,
            })
            .then(response => {
                return response;
            });
    },

    readNews: async id => {
        return axios.post(api_url + 'News/', { funct: 'readNews', id })
            .then(response => {
                return response.data;
            });
    },

    sendReport: async report => {

        let formData = new FormData();
        formData.append('funct', 'sendNew');
        formData.append('photo', report.photo);
        formData.append('category', report.category);
        formData.append('message', report.message);

        return axios.post(api_url + 'Report/', formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            return response.data;
        });
    },

    toggleReportStatus: async (id, status) => {

        return axios.post(api_url + 'Report/', {funct: 'toggleStatus', id, status})
        .then(response => {
            return response.data;
        });
    },

    getAllReports: async () => {

        return axios.post(api_url + 'Report/', {funct: 'getAllReports'})
        .then(response => {
            return response.data;
        });
    },

    getUserReports: async () => {

        return axios.post(api_url + 'Report/', {funct: 'getUserReports'})
        .then(response => {
            return response.data;
        });
    },

    getProviderReports: async () => {

        return axios.post(api_url + 'Report/', {funct: 'getProviderReports'})
        .then(response => {
            return response.data;
        });
    },

    // Accounts
    // TODO: Implement restriction to admin only
    accounts: {
        getAll: async () => {

            /*
            let accounts = [
                { name: 'Sample user', email: 'test@email.com', dateCreated: "hoy", lastAccess: 'ayer', level: '1', status: "1" },
                { name: 'Sample user', email: 'test@email.com', dateCreated: "hoy", lastAccess: 'ayer', level: '1', status: "1" },
                { name: 'Sample user', email: 'test@email.com', dateCreated: "hoy", lastAccess: 'ayer', level: '1', status: "1" },
            ];
            */

            return axios.post(api_url + 'Account/', {funct: 'getAll'})
            .then(response => {
                return response.data.values;
            });
        },

        createNew: async (values) => {
            return axios.post(api_url + 'Account/', {funct: 'createNew', values})
            .then(response => {
                return response.data;
            });
        },

        registerNew: async values => {
            return axios.post(api_url + 'Account/', {funct: 'registerNew', values})
            .then(response => {
                return response.data;
            });
        },

    },

    providers: {

        sharedReports: async (report) => {
            return axios.post(api_url + 'Providers/', {funct: 'sharedReports', report})
            .then(response => {
                return response.data;
            });
        },

        saveSharedReports: async (report, boxes) => {
            return axios.post(api_url + 'Providers/', {funct: 'saveSharedReports', report, boxes })
            .then(response => {
                return response.data;
            });
        },

    },

    // Locations
    locations: {

        getAll: async () => {
            return axios.post(api_url + 'Location/', {funct: 'getAll'})
            .then(response => {
                return response.data;
            });
        },

    },

    client: {
        createNew: async values => {
            return axios.post(api_url + 'Client/', {funct: 'createNew', values})
            .then(response => {
                return response.data;
            });
        },

        search: async values => {
            return axios.post(api_url + 'Client/', {funct: 'search', values})
            .then(response => {
                return response.data;
            });
        },

        getAll: async () => {
            return axios.post(api_url + 'Client/', {funct: 'getAll'})
            .then(response => {
                return response.data;
            });
        },

        getFromId: async id => {
            return axios.post(api_url + 'Client/', {funct: 'getFromId', id})
            .then(response => {
                return response.data;
            });
        },

        modifyClient: async (values, id_client) => {
            return axios.post(api_url + 'Client/', {funct: 'modifyClient', values, id_client})
            .then(response => {
                return response.data;
            });
        },

        // Not used
        updateClient: async values => {
            return axios.post(api_url + 'Client/', {funct: 'updateClient', values})
            .then(response => {
                return response.data;
            });
        },

        deleteClient: async id => {
            return axios.post(api_url + 'Client/', {funct: 'deleteClient', id})
            .then(response => {
                return response.data;
            });
        },

        // Pickup places
        addPickupPlace: async (id, values) => {
            return axios.post(api_url + 'Client/', {funct: 'addPickupPlace', id, values})
            .then(response => {
                return response.data;
            });
        },

        deletePickupPlace: async id => {
            return axios.post(api_url + 'Client/', {funct: 'deletePickupPlace', id})
            .then(response => {
                return response.data;
            });
        },

        getPickupFromId: async id => {
            return axios.post(api_url + 'Client/', {funct: 'getPickupFromId', id})
            .then(response => {
                return response.data;
            });
        },

        updatePickup: async values => {
            return axios.post(api_url + 'Client/', {funct: 'updatePickup', values})
            .then(response => {
                return response.data;
            });
        },
    },

    agents: {
        getStats: async (dateStart, dateEnd) => {
            return axios.post(api_url + 'Agents/', {funct: 'getStats', dateStart, dateEnd})
            .then(response => {
                return response.data;
            });
        },

        getAgentsStats: async (dateStart, dateEnd) => {
            return axios.post(api_url + 'Agents/', {funct: 'getAgentsStats', dateStart, dateEnd})
            .then(response => {
                return response.data;
            });
        },
    },

    appointment: {
        createNew: async values => {
            return axios.post(api_url + 'Appointment/', {funct: 'createNew', values})
            .then(response => {
                return response.data;
            });
        },

        createNewWithNewClient: async (values, client) => {
            return axios.post(api_url + 'Appointment/', {funct: 'createNewWithNewClient', values, client})
            .then(response => {
                return response.data;
            });
        },

        getClientAddress: async id_client => {
            return axios.post(api_url + 'Appointment/', {funct: 'getClientAddress', id_client})
            .then(response => {
                return response.data;
            });
        },

        getAll: async () => {
            return axios.post(api_url + 'Appointment/', {funct: 'getAll'})
            .then(response => {
                return response.data;
            });
        },

        deleteAppointment: async id => {
            return axios.post(api_url + 'Appointment/', {funct: 'deleteAppointment', id})
            .then(response => {
                return response.data;
            });
        },

        getFromId: async id => {
            return axios.post(api_url + 'Appointment/', {funct: 'getFromId', id})
            .then(response => {
                return response.data;
            });
        },

        updateAppointment: async values => {
            // Adjust values to controller
            return axios.post(api_url + 'Appointment/', {funct: 'updateAppointment', values})
            .then(response => {
                return response.data;
            });
        },

    },

    //carmine

    memo: {
       createNew: async values => {
            return axios.post(api_url + 'Memo/', {funct: 'createNew', values})
                .then(response => {
                    return response.data;
                });
        },


        getAll: async () => {
            return axios.post(api_url + 'Memo/', {funct: 'getAll'})
                .then(response => {
                    return response.data;
                });
        },

        getMemoFromUser: async () => {
            return axios.post(api_url + 'Memo/', {funct: 'getMemoFromUser'})
                .then(response => {
                    return response.data;
                });
        },

        getFromId: async (id) => {
            return axios.post(api_url + 'Memo/', {funct: 'getFromId', id})
                .then(response => {
                    return response.data;
                });
        },

        deleteMemo: async id => {
            return axios.post(api_url + 'Memo/', {funct: 'deleteMemo', id})
                .then(response => {
                    return response.data;
                });
        },

        /*
        getFromId: async id => {
            return axios.post(api_url + 'Memo/', {funct: 'getFromId', id})
                .then(response => {
                    return response.data;
                });
        },
        */
        updateMemo: async values => {
            // Adjust values to controller
            return axios.post(api_url + 'Memo/', {funct: 'updateMemo', values})
                .then(response => {
                    return response.data;
                });
        },

    },

    orders: {

        getMaterialsHistory: async () => {
            return axios.post(api_url + 'Orders/', {funct: 'getMaterialsHistory'})
            .then(response => {
                return response.data;
            });
        },

        getMaterials: async () => {
            return axios.post(api_url + 'Orders/', {funct: 'getMaterials'})
            .then(response => {
                return response.data;
            });
        },

        createNew: async id => {
            return axios.post(api_url + 'Orders/', {funct: 'createNew', id})
            .then(response => {
                return response.data;
            });
        },

        insert: async values => {
            return axios.post(api_url + 'Orders/', {funct: 'insert', values})
            .then(response => {
                return response.data;
            });
        },

        insertWithNewClient: async values => {
            return axios.post(api_url + 'Orders/', {funct: 'insertWithNewClient', values})
            .then(response => {
                return response.data;
            });
        },

        deleteOrder: async id => {
            return axios.post(api_url + 'Orders/', {funct: 'deleteOrder', id})
            .then(response => {
                return response.data;
            });
        },

        getIndex: async () => {
            return axios.post(api_url + 'Orders/', {funct: 'getIndex'})
            .then(response => {
                return response.data;
            });
        },

        getFromId: async id => {
            return axios.post(api_url + 'Orders/', {funct: 'getFromId', id})
            .then(response => {
                return response.data;
            });
        },

        modify: async (id, values) => {
            return axios.post(api_url + 'Orders/', {funct: 'modify', id, values})
            .then(response => {
                return response.data;
            });
        },

        addWork: async (id, values) => {
            values.id_order = id;
            return axios.post(api_url + 'Orders/', {funct: 'addWork', values})
            .then(response => {
                return response.data;
            });
        },

        editWork: async (id, values) => {
            return axios.post(api_url + 'Orders/', {funct: 'editWork', values, id})
            .then(response => {
                return response.data;
            });
        },

        getWork: async id => {
            return axios.post(api_url + 'Orders/', {funct: 'getWork', id})
            .then(response => {
                return response.data;
            });
        },

        saveNotesClaudio: async (id, notes) => {
            return axios.post(api_url + 'Orders/', {funct: 'saveNotesClaudio', id, notes})
            .then(response => {
                return response.data;
            });
        },

        deleteWork: async (id) => {
            return axios.post(api_url + 'Orders/', {funct: 'deleteWork', id})
            .then(response => {
                return response.data;
            });
        },

        getWorkResources: async (id) => {
            return axios.post(api_url + 'Orders/', {funct: 'getWorkResources', id})
            .then(response => {
                return response.data;
            });
        },

		getWorkResourcesB: async (id) => {
            return axios.post(api_url + 'Orders/', {funct: 'getWorkResourcesB', id})
            .then(response => {
                return response.data;
            });
        },

        saveWorkResources: async (id_work, values) => {
            return axios.post(api_url + 'Orders/', {funct: 'saveWorkResources', id_work, values})
            .then(response => {
                return response.data;
            });
        },

		saveWorkResourcesB: async (id_work, values) => {
            return axios.post(api_url + 'Orders/', {funct: 'saveWorkResourcesB', id_work, values})
            .then(response => {
                return response.data;
            });
        },

        getResources: async () => {
            return axios.post(api_url + 'Orders/', {funct: 'getResources'})
            .then(response => {
                return response.data;
            });
        },

        addResource: async values => {
            return axios.post(api_url + 'Orders/', {funct: 'addResource', values})
            .then(response => {
                return response.data;
            });
        },

        editResource: async (id, values) => {
            return axios.post(api_url + 'Orders/', {funct: 'editResource', id, values})
            .then(response => {
                return response.data;
            });
        },

        newMaterial: async (id_order, values) => {
            return axios.post(api_url + 'Orders/', {funct: 'addMaterial', id_order, values})
            .then(response => {
                return response.data;
            });
        },

        editMaterial: async (material, values) => {
            return axios.post(api_url + 'Orders/', {funct: 'editMaterial', material, values})
            .then(response => {
                return response.data;
            });
        },

        deleteMaterial: async id => {
            return axios.post(api_url + 'Orders/', {funct: 'deleteMaterial', id})
            .then(response => {
                return response.data;
            });
        },

        saveNotesPatrizio: async (id, notes) => {
            return axios.post(api_url + 'Orders/', {funct: 'saveNotesPatrizio', id, notes})
            .then(response => {
                return response.data;
            });
        },

        saveMaterials: async (id_order, materials, date, delivered) => {
            return axios.post(api_url + 'Orders/', {funct: 'saveMaterials', id_order, materials, date, delivered})
            .then(response => {
                return response.data;
            });
        },

        saveMaterialsList: async (values, date) => {
            return axios.post(api_url + 'Orders/', {funct: 'saveMaterialsList', values, date})
            .then(response => {
                return response.data;
            });
        },

    },

    works: {

        getMaterials: async id_work => {
            return await axios.post(api_url + 'Works/', {funct: 'getMaterials', id_work})
            .then(response => {
                return response.data;
            });
        },

        saveMaterials: async (id_work, materials) => {
            return await axios.post(api_url + 'Works/', {funct: 'saveMaterials', id_work, materials})
            .then(response => {
                return response.data;
            });
        },

        getWorkersVehiclesList: async (date) => {
            return axios.post(api_url + 'Works/', {funct: 'getWorkersVehiclesList', date})
            .then(response => {
                return response.data;
            });
        },

        setWorkers: async (id, workers) => {
            return axios.post(api_url + 'Works/', {funct: 'setWorkers', id, workers})
            .then(response => {
                return response.data;
            });
        },

        getWorkers: async id => {
            return axios.post(api_url + 'Works/', {funct: 'getWorkers', id})
            .then(response => {
                return response.data;
            });
        },

        setVehicles: async (id, vehicles) => {
            return axios.post(api_url + 'Works/', {funct: 'setVehicles', id, vehicles})
            .then(response => {
                return response.data;
            });
        },

        getVehicles: async id => {
            return axios.post(api_url + 'Works/', {funct: 'getVehicles', id})
            .then(response => {
                return response.data;
            });
        },

        getResourcesLabels: async () => {
            return axios.post(api_url + 'Works/', {funct: 'getResourcesLabels'})
            .then(response => {
                return response.data;
            });
        },

        addSingleWorker: async (id_work, id_line, id_worker) => {
            return axios.post(api_url + 'Works/', {funct: 'addSingleWorker', id_work, id_line, id_worker})
            .then(response => {
                return response.data;
            });
        },

        addSingleVehicle: async (id_work, id_line, id_vehicle) => {
            return axios.post(api_url + 'Works/', {funct: 'addSingleVehicle', id_work, id_line, id_vehicle})
            .then(response => {
                return response.data;
            });
        },

        deleteSingleWorker: async (id_line) => {
            return axios.post(api_url + 'Works/', {funct: 'deleteSingleWorker', id_line})
            .then(response => {
                return response.data;
            });
        },

        deleteSingleVehicle: async (id_line) => {
            return axios.post(api_url + 'Works/', {funct: 'deleteSingleVehicle', id_line})
            .then(response => {
                return response.data;
            });
        },

        addDailyWorker: async (date, name) => {
            return axios.post(api_url + 'Works/', {funct: 'addDailyWorker', date, name})
            .then(response => {
                return response.data;
            });
        },

        addDailyVehicle: async (date, name) => {
            return axios.post(api_url + 'Works/', {funct: 'addDailyVehicle', date, name})
            .then(response => {
                return response.data;
            });
        },

        deleteDailyWorker: async (date, id) => {
            return axios.post(api_url + 'Works/', {funct: 'deleteDailyWorker', date, id})
            .then(response => {
                return response.data;
            });
        },

        deleteDailyVehicle: async (date, id) => {
            return axios.post(api_url + 'Works/', {funct: 'deleteDailyVehicle', date, id})
            .then(response => {
                return response.data;
            });
        },

    },

    calendar: {

        getWeek: async monday => {
            return await axios.post(api_url + 'Calendar/', {funct: 'getWeek', monday})
            .then(response => {
                return response.data;
            });
        },

        getAppointment: async id => {
            return await axios.post(api_url + 'Calendar/', {funct: 'getAppointment', id})
            .then(response => {
                return response.data;
            });
        },

        getMemo: async id => {
            return await axios.post(api_url + 'Calendar/', {funct: 'getMemo', id})
                .then(response => {
                    return response.data;
                });
        },

        getWork: async id => {
            return await axios.post(api_url + 'Calendar/', {funct: 'getWork', id})
            .then(response => {
                return response.data;
            });
        },

    },

    dailyWorks: {
        getWorks: async date => {
            return await axios.post(api_url + 'Works/', {funct: 'getByDate', date})
            .then(response => {
                return response.data;
            });
        },
    },

    documents: {

        getInfo: async id => {
            return axios.post(api_url + 'Appointment/', {funct: 'getInfo', id})
            .then(response => {
                return response.data;
            });
        },

        getFromId: async id => {
            return axios.post(api_url + 'Files/', {funct: 'getFromId', id})
            .then(response => {
                return response.data;
            });
        },

        upload: async (values) => {

            let formData = new FormData();
            formData.append('funct', 'upload');
            formData.append('file', values.file);
            formData.append('id_appointment', values.appointmentId);
            formData.append('showInCharge', values.showInCharge);

            return axios
            .post(api_url + 'Files/', formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                if (response.status=='200') {
                    return response.data;
                } else {
                    return false;
                }
            });
        },

        delete: async id => {
            return axios.post(api_url + 'Files/', {funct: 'delete', id})
            .then(response => {
                return response.data;
            });
        },

        setShowInCharge: async (id, value) => {
            return axios.post(api_url + 'Files/', {funct: 'setShowInCharge', id, value})
            .then(response => {
                return response.data;
            });
        },

    },

};

export default api
