<template>
  <div class="home">
    <!-- Logo hidden -->
    <!--
      <br /><br />
      <img class="home-logo" src="https://www.sokan.it/struttura/logo_sokan_communication.png" />
      <br /><br />
      <h4><span class="subtitle">-- Sito in costruzione --</span></h4>
    -->
  </div>
</template>

<script>

export default {
  name: 'Home',

  // Data
  data() {
    return {
      
    }
  },  
}

</script>

<style scoped>

  .home {
    text-align: center;
  }

  .home-logo {
    width: 90%;
    max-width: 500px;
  }

  .subtitle {
    font-style: italic;
    color: #3d3d3f;
  }

</style>