<template>
  <div>  

    <v-subheader><h1 class="accent--text">Elenco clienti</h1></v-subheader>
    
    

    <div v-if="clients">

            <template>
              <v-data-table
                :headers="headers"
                :items="clients"
                :items-per-page="25"
                class="elevation-5"
              >

                <template v-slot:item.actions="{ item }">                        
                  <v-btn v-if="$store.getters.rules.clients.edit" class="mr-1" color="info" @click="addPickupPlace(item.id)" x-small fab>
                    <v-icon>mdi-home-plus</v-icon>
                  </v-btn>
                  <v-btn v-if="$store.getters.rules.clients.edit" class="mr-1" color="success" @click="$router.push({path: '/client/modify/'+item.id})" x-small fab>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn v-if="$store.getters.rules.clients.edit" color="error" @click="deleteClient(item.id)" x-small fab>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>

                  <div v-if="!$store.getters.rules.clients.edit">
                    -
                  </div>

                </template>

                <!-- Client type 0 = Azienda 1 = Privato 2 = Ente -->
                <template v-slot:item.private_client="{ item }">
                    {{$predefined.clientTypes[parseInt(item.private_client)]}}
                </template>

                <template v-slot:item.pickupPlaces="{ item }">
                  <div v-if="item.pickupPlaces==''">
                    -
                  </div>
                  
                  <div v-if="item.pickupPlaces!=''" class="limit-width">
                    <div v-for="(place, index) in item.pickupPlaces" v-bind:key="index" @mouseover="showPopupButtons(place.id)" @mouseleave="hidePopupButtons">

                      <div>
                        <div v-html="place.text"></div>
                        <span v-if="popupButtons==place.id && $store.getters.rules.clients.edit">
                          <v-btn icon x-small color="success" @click="addressModify(place.id)"><v-icon>mdi-pencil</v-icon></v-btn>
                          <v-btn icon x-small color="error" @click="addressDelete(place.id)"><v-icon>mdi-close</v-icon></v-btn>
                        </span>
                      </div>                           

                    </div>
                  </div>
                </template>
  
              </v-data-table>
            </template>

    </div>


    <!-- Modify -->
    <v-overlay v-model="modifyOverlay">
      <v-card class="mx-auto" max-width="800" :light="!this.$vuetify.theme.dark">
        <v-card-title>
            Modifica cliente
        </v-card-title>
        <v-card-text>
            <v-form ref="modifyForm" v-model="modifyForm" lazy-validation>
              
                <v-container>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="modifyValues.name" label="Nome:" required></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="modifyValues.surname" :rules="rules.required" label="Cognome*:" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="modifyValues.email" :rules="rules.email" label="E-mail:"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="modifyValues.phone" :rules="rules.phone" label="Telefono:"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="modifyValues.cellphone" :rules="rules.phone" label="Cellulare:"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>

                <h4>I campi contrassegnati * sono obbligatori.</h4>                   
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="modifyOverlay = false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmModify">Modifica</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <!-- Delete -->
    <v-overlay v-model="deleteClientOverlay" :light="!this.$vuetify.theme.dark">
      <v-card class="mx-auto" max-width="800" :light="!this.$vuetify.theme.dark">
        <v-card-title>
            Elimina cliente
        </v-card-title>
        <v-card-text>
          Sei sicuro di voler eliminare questo cliente? 
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="deleteClientOverlay = false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmDeleteClient">Si</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <!-- Add pickup -->
    <v-overlay v-model="addPickupPlaceOverlay">

      <!-- Add -->
      <LocationForm :create="true" :userId="addPickupPlaceClientId" @confirm="confirmPickupAddress()" @cancel="addPickupPlaceOverlay=false" />

    </v-overlay>

    <!-- Delete address -->
    <v-overlay v-model="deleteAddressOverlay">
      <v-card class="mx-auto" max-width="800" :light="!this.$vuetify.theme.dark">
        <v-card-title>
            Elimina luogo di carico
        </v-card-title>
        <v-card-text>
          Sei sicuro di voler eliminare questo luogo? 
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="deleteAddressOverlay = false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmDeleteAddress">Si</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

   <!-- Modify address -->
    <v-overlay v-model="modifyAddressOverlay">

      <!-- ModifyLocation -->
      <LocationForm :create="false" :id="pickupPlace" @confirm="confirmPickupAddress()" @cancel="modifyAddressOverlay=false" />

    </v-overlay>

  </div>
</template>

<script>

import LocationForm from '../../components/location/CreateModify'

export default {

  components: {LocationForm},
  
  data() {

    return {

      popupButtons: null,

      rules: {
        required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
        email: [ value => (value && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) ? 'Formato non valido' : true],
        phone: [ value => (value && !/^\d+$/.test(value)) ? 'Inserire i numeri senza spazi né caratteri speciali' : true],
      },

      addPickupPlaceClientId: null,
      addPickupPlaceOverlay: false,
      addPickupPlaceOverlay2: false,

      newPickupForm: false,
      newPickupFormValues: {
        address: null,
        zip: null,
        city: null,
        province: null,
        notes: null,
      },

      modifyOverlay: false,

      deleteAddressOverlay: false,
      deleteAddressId: null,

      modifyAddressOverlay: false,
      pickupPlace: null,
      
      modifyForm: false,
      modifyValues: {
        id: null,
        name: null,
        surname: null,
        email: null,
        phone: null,
        cellphone: null,
      },

      deleteClientOverlay: false,
      deleteClientId: false,

      headers: [
        {text: 'Nome', value: 'name'},
        {text: 'Rag.sociale', value: 'business_name'},
        {text: 'Tipologia', value: 'private_client'},
        {text: 'E-mail', value: 'email'},
        {text: 'Telefono', value: 'phone'},
        {text: 'Cellulare', value: 'cellphone'},
        {text: 'Luoghi', value: 'pickupPlaces'},
        {text: 'Azione', value:'actions'},
      ],

      clients: null, // {name:'a',email:'a',phone:'a',cellphone:'a',address:'a'},
    
    }
  },

  methods: {

    confirmPickupAddress() {
      this.$emit('showSnack', 'Informazione salvata', 'success');
      this.modifyAddressOverlay = false;
      this.addPickupPlaceOverlay = false;
      this.getAll();
    },

    getAll() {
        this.$emit('showProgress');
        this.$api.client.getAll().then(data => {
            this.$emit('hideProgress');

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            this.clients = data.values.clients;
        });
    },

    clientAction(id) {
      this.$emit('showSnack', 'Debug: client ID #' + id, 'info');
    },

    modifyClient(id) {
      this.$emit('showProgress');

      // Get client data
      this.$api.client.getFromId(id).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          this.modifyValues = data.values.client;

          this.$emit('hideProgress');
      });
      
      this.modifyOverlay = true;
    },

    confirmModify() {
      if (!this.$refs.modifyForm.validate())
        return;
      this.$emit('showProgress');

      // Save client data
      this.$api.client.updateClient(this.modifyValues).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          // Update clients list
          this.getAll();
          
          this.modifyOverlay = false;

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Cliente modificato', 'success');
      });
      
    },

    // Delete client
    deleteClient(id) {
      this.deleteClientId = id;
      this.deleteClientOverlay = true;
    },

    confirmDeleteClient() {
      this.$api.client.deleteClient(this.deleteClientId).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          // Update clients list
          this.getAll();

          this.deleteClientOverlay = false;

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Cliente eliminato', 'success');
      });
    },

    // Add pickup place
    addPickupPlace(id) {
      this.addPickupPlaceClientId = id;
      this.addPickupPlaceOverlay = true;
    },

    confirmAddPickupPlace() {
      if (!this.$refs.newPickupForm.validate())
        return;
      this.$emit('showProgress');

      // Insert new place
      this.$api.client.addPickupPlace(this.addPickupPlaceClientId, this.newPickupFormValues).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          // Update clients list
          this.getAll();
          
          this.addPickupPlaceOverlay = false;
          !this.$refs.newPickupForm.reset();

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Luogo inserito', 'success');
      });
    },

    showPopupButtons(id) {
      this.popupButtons = id;      
    },

    hidePopupButtons() {
      this.popupButtons = -1;
    },

    addressDelete(id) {
      this.deleteAddressId = id;
      this.deleteAddressOverlay = true;
    },

    confirmDeleteAddress() {
      this.$api.client.deletePickupPlace(this.deleteAddressId).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          // Update clients list
          this.getAll();

          this.deleteAddressOverlay = false;

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Luogo eliminato', 'success');
      });
    },

    addressModify(id) {
      this.pickupPlace = id;
      this.modifyAddressOverlay = true;
    },

    confirmAddressModify() {
      if (!this.$refs.modifyAddressForm.validate())
        return;
      this.$emit('showProgress');

      // Save address data
      this.$api.client.updatePickup(this.modifyPickupFormValues).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          // Update clients list
          this.getAll();
          
          this.modifyAddressOverlay = false;

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Luogo di carico modificato', 'success');
      });
    },

  },

  // Events
  mounted() {
    this.getAll();    
  },

}

</script>

<style>

  .limit-width {
    max-width: 200px !important;
  }

</style>