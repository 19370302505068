<template>
  <div>  

    <v-container class="mb-8">
        <v-row>
            <v-spacer></v-spacer>
            <v-col class="boxes-description" cols="auto">
                <ul>
                    Depositi Totali: 72

                    <br /><br />

                    <li>1-51 Depositi</li>
                    <li>52-62 Container vecchi</li>
                    <li>63-64-65 Container nuovi</li>
                    <li>66-67 Capannone nuovo su</li>
                    <li>68-69-70-71 Capannone nuovo giù</li>
                    <li>72 Mansarda</li>
                </ul>

                <router-link :to="{name: 'LogBoxes'}">
                    <v-btn class="mt-5" color="info" small>
                        <v-icon class="mr-2">mdi-magnify</v-icon>
                        Storico movimenti
                    </v-btn>
                </router-link>
            </v-col>
            <v-spacer></v-spacer>
            <!-- Leggenda -->
            <v-col>
                Legenda

                <br /><br />

                <li v-if="$store.getters.rules.editDeposit"><v-btn fab color="success" class="mr-4" x-small><v-icon>mdi-plus</v-icon></v-btn> Inserire nuovo deposito</li>

                <li v-if="$store.getters.rules.editDeposit"><v-btn icon color="success" class="mr-4" small><v-icon>mdi-pencil</v-icon></v-btn> Modifica deposito</li>

                <li v-if="$store.getters.rules.editDeposit"><v-btn icon color="error" class="mr-4" small><v-icon>mdi-trash-can</v-icon></v-btn> Cancella deposito</li>

                <li>Per effettuare una ricerca <b>CONTROL+F</b></li>

            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-container>

    <v-simple-table dense class="elevation-1">
        <thead>
        <tr>
            <th class="text-left" width="12%">
            # Dep.
            </th>
            <th class="text-left" width="16%">
            % Occupato
            </th>
            <th class="text-left">
            Clienti
            </th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(box, index) in boxes" :key="index">
            <!-- Box number -->
            <td class="text-center">
                {{index}}
            </td>

            <!-- % Occupied (total) -->
            <td class="text-center">
                <template v-if="box.length==0">
                    <span class="green--text">0%</span>
                </template>
                <template v-else>
                    <span v-if="box.used>=100" class="red--text">{{box.used}}%</span>
                    <span v-else class="orange--text">{{box.used}}%</span>                        
                </template>
            </td>
            
            <!-- Client -->
            <td>
                <template v-if="box.clients">
                    <v-simple-table>
                        <tbody>
                            <tr v-for="(client, index) in box.clients" :key="index">
                                <!-- Client name / deposit title -->
                                <td width="60%">
									<!-- Display field 'client' -->
									<template v-if="false">
                                        <template v-if="client.username">
                                            <router-link :to="'/orders/' + client.id_order">
                                            {{ client.username }}
                                            </router-link>

                                            <!-- Show percentage used by client -->
                                            <span class="partial-used">
                                            [ {{ client.partial_used }}% ] {{ (parseInt(client.position)=='0') ? 'SX' : 'DX' }}
                                            <div v-if="$store.getters.DEBUG">{{client}}</div>
                                            </span>
                                            </template>
                                            <template v-else>
                                            <span v-if="$env.siteA">
                                            {{ client.title }}
                                            </span>
                                            <span v-else>
                                            {{ (client.title2) ? client.title2 : client.title }}
                                            </span>

                                            <!-- Show percentage used by client -->
                                            <span class="partial-used">
                                            [ {{ client.partial_used }}% ] {{ (parseInt(client.position)=='0') ? 'SX' : 'DX' }}
                                            <div v-if="$store.getters.DEBUG">{{client}}</div>
                                            </span>
                                        </template>
									</template>
                                    <template v-if="client">
                                        {{client.client}}
                                    </template>
                                </td>

                                <!-- Actions -->
                                <td v-if="$store.getters.rules.editDeposit">
                                    <v-btn icon small color="success" @click="modifyClient(client.id_line)"><v-icon>mdi-pencil</v-icon></v-btn>
                                    <v-btn icon small color="error" class="ml-3" @click="deleteClient(client.id_line)"><v-icon>mdi-trash-can</v-icon></v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </template>
                <!-- Insert new -->
                <v-btn v-if="$store.getters.rules.editDeposit" fab x-small class="white--text mt-5 mb-5" color="green" @click="addClient(index)"><v-icon>mdi-plus</v-icon></v-btn>
            </td>
        </tr>

        </tbody>
    </v-simple-table>

    <v-overlay v-model="clientOverlay">
        <v-card class="mx-auto" min-width="800" light>
            <v-card-title>
                {{ (this.insertClient) ? 'Nuovo deposito' : 'Modifica deposito' }}
            </v-card-title>
            <v-card-text>
                <v-form ref="clientForm">
                    <v-row>
                        <v-col cols="7">
                            <v-text-field type="number" v-model="client.id_box" label="Numero deposito (1-50)*" :rules="rules.required"></v-text-field>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field type="number" v-model="client.used" label="Percentuale utilizzo*" :rules="rules.required">
                                <template v-slot:append-outer>
                                    %
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="7">
                            <v-autocomplete label="Ordine" :items="orders" v-model="client.id_order"></v-autocomplete>
                        </v-col>
                        <v-col cols="5">
                            <v-select label="Posizione*" :items="positions" v-model="client.position"></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="client.client" label="Cliente*" :rules="rules.required"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="$env.siteA">
                        <v-col cols="7">
                            <v-text-field v-model="client.title" label="Titolo 1"></v-text-field>
                        </v-col>
                        <v-col cols="5">
                            <!-- Date picker -->
                            <template>
                                <v-menu
                                    ref="calendarStart"
                                    v-model="calendar" transition="scale-transition" offset-y min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="client.date" label="Pagato fino al 1:" v-bind="attrs" v-on="on" :rules="rules.required && rules.date"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    ref="picker"
                                    v-model="originalDate"
                                    min="1950-01-01"
                                    locale="it"
                                    :first-day-of-week="1"
                                ></v-date-picker>
                                </v-menu>
                            </template>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="7">
                            <v-text-field v-model="client.title2" label="Titolo 2"></v-text-field>
							Si ricorda di inserire i prezzi a netto d'iva
                        </v-col>
                        <v-col cols="5">
                            <!-- Date picker -->
                            <template>
                                <v-menu
                                    ref="calendarStart2"
                                    v-model="calendar2" transition="scale-transition" offset-y min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="client.date2" label="Pagato fino al 2:" v-bind="attrs" v-on="on" :rules="rules.date"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    ref="picker2"
                                    v-model="originalDate2"
                                    min="1950-01-01"
                                    locale="it"
                                    :first-day-of-week="1"
                                ></v-date-picker>
                                </v-menu>
                            </template>
                        </v-col>
                    </v-row>

                    <!-- If editing, show uploaded files -->
                    <v-row v-if="!insertClient">
                        <v-col cols="12" class="mx-auto">
                            <h4>Allegati</h4>
                            <br />
                            <span v-if="uploadedFiles.length==0">Non ci sono documenti allegati.</span>

                            <!-- Link to files -->
                            <div v-for="(file, index) in uploadedFiles" :key="'file-'+index">
                                <a :href="'/api/uploads/box/'+file" target="_blank">
                                    {{file}}
                                </a>
                                <br />
                            </div>
                            
                        </v-col>
                    </v-row>

                    <!-- Upload documents -->
                    <v-row v-for="(file, index) in files" :key="index">
                        <v-col cols="6" class="mx-auto">
                            <v-file-input @change="cancelFile(index)" v-model="files[index]" label="Caricare allegato"></v-file-input>
                        </v-col>
                    </v-row>      
                    <v-row v-if="files.length<5">
                        <v-col cols="6" class="mx-auto">
                            <v-file-input ref="file" @change="addFile()" v-model="file" label="Caricare allegato"></v-file-input>
                        </v-col>
                    </v-row>

                </v-form>       
                <span class="red--text" v-if="clientError">
                    Errore: scegliere ordine o inserire un titolo
                </span>
            </v-card-text>
            <v-card-actions v-if="$store.getters.rules.editDeposit">
                <v-btn color="error" @click="clientOverlay=false">Annulla</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="saveClient()">Salva</v-btn>
            </v-card-actions>
        </v-card>
    </v-overlay>

    <!-- Pop-up to confirm delete line -->
    <v-overlay v-model="displayConfirmDeleteLine">
        <v-card class="mx-auto" min-width="500" light>
            <v-card-title>
                Conferma eliminazione
            </v-card-title>
            <v-card-text>
                Cancellare il deposito?
            </v-card-text>
            <v-card-actions v-if="$store.getters.rules.editDeposit">
                <v-btn color="error" @click="displayConfirmDeleteLine=false">Annulla</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="deleteClientConfirmed()">Cancella deposito</v-btn>
            </v-card-actions>
        </v-card>
    </v-overlay>

  </div>
</template>

<script>

export default {
  
  data() {
    return {
        insertClient: true,
        orders: [],
        boxes: [],
        clientOverlay: false,
        client: {
            position: 1,
            id_box: null,
            id_order: null,
            title: null,
            used: null,
            date: null,
			title2: null,
			date2: null,
            client: null,
        },
        files: [],
        file: null,

        positions: [
            {value:0, text:'sinistra'},
            {value:1, text:'destra'},
        ],

        clientError: false,

        originalDate: null,
        calendar: null,	
        on: null,
        attrs: null,

        originalDate2: null,
		calendar2: null,

        idLine: null,
        idLineToDelete: null, // Track line id to delete after confirm
        displayConfirmDeleteLine: false,

        uploadedFiles: [],

        rules: {
        required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
        numbersOnly: [ value => (isNaN(value)) ? 'Inserire un valore numerico' : true ],
        integer: [ value => (parseInt(value) != value) ? 'Inserire un numero intero uguale o maggiore di zero' : true ],
        box: [ value => (parseInt(value) < 1 || parseInt(value) > 50) ? 'Inserire un numero intero tra 1 e 50' : true ],
        date: [ value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value))
            ?
            (!value)
            ?
            true
            :
            'Formato non valido. (gg-mm-aaaa)'
            :
            true
        ],
        },
    }
  },

  methods: {

    // Add or remove file inputs
    cancelFile(index) {
        if (this.files[index])
            return;

        this.files.splice(index, 1);
    },

    addFile() {
        if (!this.file)
            return;
        this.files.push(this.file);
        this.$refs.file.reset();
    },

    getAll() {
        this.$emit('showProgress');

        this.$api.box.getAll().then(data => {
            this.$emit('hideProgress');

            if (!data.ok) {
                this.showSnack('Errore', data.values.msg, 'error');
                return;
            }

            this.boxes = data.values.boxes;
        });
    },

    modifyClient(id_line) {
        this.idLine = id_line;
        
        this.$emit('showProgress');
        // Get line
        this.$api.box.getLine(id_line).then(dataLine => {
            if (!dataLine.ok) {
                this.$emit('hideProgress');
                this.$emit('showSnack', dataLine.values.msg, 'error');
                return;
            }

            this.client = dataLine.values.client;
            this.uploadedFiles = dataLine.values.uploadedFiles;
            this.files = [];
            
            // Get orders
            this.$api.box.getOrders().then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.orders = data.values.orders;

                this.clientError = false;

                this.insertClient = false;
                this.clientOverlay = true;
            });
        });
        
        
        
    },

    deleteClient(id_line) {
        this.idLineToDelete = id_line;
        this.displayConfirmDeleteLine = true;
    },

    deleteClientConfirmed() {
        let id_line = this.idLineToDelete;

        this.$emit('hideProgress');
        this.$api.box.delete(id_line).then(data => {
            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                this.displayConfirmDeleteLine = false;
                return;
            }

            this.displayConfirmDeleteLine = false;

            this.$emit('showSnack', 'Deposito cancellato', 'success');
            this.getAll();
        });
    },

    addClient(box) {
        // Get orders
        this.$emit('showProgress');
        this.$api.box.getOrders().then(data => {
            this.$emit('hideProgress');

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            this.orders = data.values.orders;

            this.client = {
                position: 1,
                id_box: null,
                id_order: null,
                title: null,
                used: null,
                date: null,
				title2: null,
				date2: null,
                client: null,
            };
            this.files = [];

            this.clientError = false;

            this.insertClient = true;
            this.client.id_box = box;
            this.clientOverlay = true;
        });
    },

    saveClient() {
        if (!this.$refs.clientForm.validate())
            return;

		// Client title not mandatory
		/*
        if (this.client.title == null)
            this.clientError = true;
        else
            this.clientError = false;

        if (this.clientError)
            return;
		*/

        this.$emit('showProgress');

        if (this.insertClient) {
            // New
            this.$api.box.insert(this.client, this.files).then(data => {
            this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', 'Errore inserimento', 'error');
                    return;
                }

                this.$emit('showSnack', 'Cliente inserito', 'success');
                this.clientOverlay = false;
                this.getAll();
            });
        } else {
            // Modify
            this.$api.box.modify(this.idLine, this.client, this.files).then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.$emit('showSnack', 'Cliente aggiornato', 'success');
                this.clientOverlay = false;
                this.getAll();
            });
        }
        
    },

  },

  watch: {
    originalDate(val) {
        this.client.date = val.split('-').reverse().join('-');
    },
    originalDate2(val) {
        this.client.date2 = val.split('-').reverse().join('-');
    },
  },

  // Events
  mounted() {
       this.getAll();
  },

}

</script>

<style scoped>

.boxes-description {
    font-size: 90%;
}

@media (max-width:768px) {
    .boxes-description {
        font-size: 70%;
    }
}

.borderBottom {
    padding-top: 8px;
    border-bottom: 1px solid #777;
}

.borderBottom2 {
    border-bottom: 1px solid #ccc;
}

li {
    margin-bottom: 5px;
    font-size: 90%;
} 

.partial-used {
    margin-left: 5px;
    font-size: 90%;
}

</style>