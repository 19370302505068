<template>
  <div>  

    <br />

    <v-subheader><h1 class="accent--text">Nuovo carico magazzino</h1></v-subheader>

    <v-card class="mx-auto mt-2" max-width="600"> 
      <v-card-text>
        <v-form ref="form">

        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left">
                Materiale
              </th>
              <th class="text-left">
                Incremento
              </th>
            </tr>
          </thead>
        </v-simple-table>

        <v-container>
          <v-row v-for="(material, index) in materials" class="borderBottom" :key="index">
            <v-col cols="6" class="">
              {{material.name}}
            </v-col>
            <v-col class="lessPadding">
              <v-text-field type="number" class="lessPadding" v-model="material.increment"></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-text-field :rules="rules.required && rules.date" v-model="date" label="Data* (gg-mm-aaaa):" placeholder="gg/mm/aaaa"></v-text-field>
        
        </v-form>
   
        </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveMaterials()">Salva</v-btn>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>

export default {

  data() {
    return {

     materials:null,
     date: null,

     materialsToExclude: [
       'Camion',
       'Avvitatore e Trapano',
       'Ferri',
     ],

     rules: {
      required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
      integer: [ value => (! /^[0-9]+$/.test(value)) ? 'Inserire un numero intero valido.' : true ],
      hour: [ value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true ],
      date: [ value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true ],
      numberOrNull: [ value => (!value) ? 'Inserire un numero intero valido.' : true ],
     },

    }
  },

  methods: {
    
    getMaterials() {
      this.$emit('showProgress');
      this.$api.orders.getMaterials().then(data => {
          this.$emit('hideProgress');

          if (!data.ok) {
              this.$emit('showSnack', 'Database error', 'error');
              return;
          }

          data.values.materials = data.values.materials.filter(el => this.materialsToExclude.indexOf(el.name)==-1);

          data.values.materials.forEach(material => {
            material.increment = 0;
          });

          // Ok
          this.materials = data.values.materials;
      });
    },

    saveMaterials() {
      if (!this.$refs.form.validate())
        return;

      this.$api.orders.saveMaterialsList(this.materials, this.date).then(data => {
          this.$emit('hideProgress');

          if (!data.ok) {
              this.$emit('showSnack', 'Database Error', 'error');
              return;
          }

          // Ok
          this.$emit('showSnack', 'Materiali aggiornati', 'success');
          this.getMaterials();
      });
    }

  },

  // Events
  mounted() {
    this.getMaterials();
  },

}

</script>

<style scoped>

.lessPadding {
  margin: 0px !important;
  padding: 0px !important;
}

.borderBottom {
  border-top: 1px solid #ccc;
}

</style>