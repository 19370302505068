<template>
  <div>  

    <br />

    <v-subheader><h1 class="accent--text">Gestire risorse</h1></v-subheader>

    <v-card class="mx-auto" width="1000"> 
      <v-card-text>

            <v-simple-table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th class="text-center">Tipologia</th>
                  <th class="text-center">Disp.Totale</th>
                  <th class="text-center">Azioni</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(resource, index) in resources" v-bind:key="index">
                  <td class="col-6">{{resource.name}}</td>
                  <td class="text-center">
                    <v-icon :color="(resource.type==0) ? 'orange' : 'blue-grey'" dense>{{ (resource.type==0) ? 'mdi-account-hard-hat' : 'mdi-truck' }}</v-icon>
                  </td>
                  <td class="text-center">{{resource.ammount}}</td>
                  <td class="text-center">
                    <v-btn class="mr-3" color="success" @click="editDialog(resource.id)" icon x-small fab><v-icon>mdi-pencil</v-icon></v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-simple-table>
              <tbody>
                <tr>
                  <td>
                    <v-btn @click="addNewDialog" small icon color="success"><v-icon>mdi-plus</v-icon></v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

      </v-card-text>
    </v-card>

    <v-overlay v-model="overlayForm">
      <v-card light min-width="600">
        <v-card-title>{{(!currentResourceId) ? 'Inserisci risorsa' : 'Modifica risorsa'}}</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-text-field v-model="formValues.name" :rules="rules.required" label="Nome"></v-text-field>
            <v-text-field type="number" v-model="formValues.ammount" :rules="rules.requiredInteger" label="Disponibilità totale"></v-text-field>
            
            <br />

            <h3>Tipologia:</h3>

            <br />

            <v-btn-toggle color="success" v-model="formValues.type" mandatory>
              <v-btn large>
                <v-icon class="mr-3">mdi-account-hard-hat</v-icon> Operaio
              </v-btn>

              <v-btn large>
                <v-icon class="mr-3">mdi-truck</v-icon> Automezzo
              </v-btn>
            </v-btn-toggle>

          </v-form>
          <br />
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="overlayForm = false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="processForm">{{(!currentResourceId) ? 'Inserisci' : 'Modifica'}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay> 

  </div>
</template>

<script>

export default {
 
  data() {
    return {

     rules: {
      required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
      requiredInteger: [value => (!value) ? 'Inserire un numero intero valido' : true ],
     },

     overlayForm: false,
     resources: [],
     currentResourceId: false,

     formValues: {
       name: null,
       ammount: null,
       type: 0,
     },

    }
  },

  methods: {
    
    processForm() {
      if (!this.$refs.form.validate())
        return;

      this.$emit('showProgress');

      if (!this.currentResourceId) {
        //Add new
        this.$api.orders.addResource(this.formValues).then(data => {
          this.$emit('hideProgress');
          this.overlayForm = false;
          if (data.ok) {
            this.$refs.form.reset();
            this.currentResourceId = false;
            this.loadResources();
            this.$emit('showSnack', 'Risorsa inserita', 'success');
          } else {
            this.$emit('showSnack', data.values.msg, 'Errore');
          }
        }); 
      } else {
        // Edit
        this.$api.orders.editResource(this.currentResourceId, this.formValues).then(data => {
          this.$emit('hideProgress');
          this.overlayForm = false;
          if (data.ok) {
            this.$refs.form.reset();
            this.currentResourceId = false;
            this.loadResources();
            this.$emit('showSnack', 'Risorsa modificata', 'success');
          } else {
            this.$emit('showSnack', data.values.msg, 'Errore');
          }
        }); 
      }
    },

    debug(test) {
      alert(test);
    },

    loadResources() {
      this.$emit('showProgress');

      this.$api.orders.getResources().then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.resources = data.values.resources;
        } else {
          this.$emit('showSnack', data.values.msg, 'Errore');
        }
      });
    },

    editDialog(id) {
      this.currentResourceId = id;
      let currentRes = this.resources.find(el => el.id==id);
      this.formValues.name = currentRes.name;
      this.formValues.ammount = currentRes.ammount;
      this.formValues.type = parseInt(currentRes.type);
      this.overlayForm = true;
    },

    addNewDialog() {
      this.currentResourceId = false;
      this.overlayForm = true;
    },

  },

  watch: {

  },

  // Events
  mounted() {
    this.loadResources();
  },

}

</script>