<template>
<div>

    <v-container>
        <v-row>
            <v-col>
                <v-subheader><v-btn x-large color="primary" icon @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn> <h1 class="accent--text">{{logVehicle.intervention_type}}</h1></v-subheader>
            </v-col>
        </v-row>
    </v-container>

    <v-card
        class="mx-auto my-12"
        max-width="700">

        <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
        </template>


        <v-card-title> <h3>{{ logVehicle.intervention_type ? capitalize(logVehicle.intervention_type) : null }}</h3></v-card-title>

        <div class="grey--text ms-4">
            Creato il: {{ logVehicle.date_created }}
        </div>

        <v-card-text>
            <v-row align="center" class="mx-0">
                <div class="my-4 text-subtitle-1">
                    Nome veicolo: {{ logVehicle.name }}
                </div>
            </v-row>

            <v-row align="center" class="mx-0">
                <div class="my-4 text-subtitle-1">
                    Targa: {{ logVehicle.plate }}
                </div>
            </v-row>

            <v-row align="center" class="mx-0 mt-8">
                <div class="my-4 text-subtitle-1">
                    Descrizione: {{ logVehicle.description }}
                </div>
            </v-row>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>

         <v-card-actions>

            <v-btn
                color="deep-purple lighten-2"
                text
                @click="$router.push({path: '/logVehicle/modify/'+ logVehicle.id})"
            >
                Modifica
            </v-btn>
        </v-card-actions>
    </v-card>
</div>
</template>

<script>
export default {
    data() {
        return {

            logVehicle: [],
            logVehicleId: null,

        }
    },

    methods: {

        getFromId(id) {
            this.$emit('showProgress');
            this.$api.logVehicle.getFromId(id).then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                console.log(data.values.logVehicle);

                this.logVehicle = data.values.logVehicle;
            });
        },

        capitalize(word) {
            return word
                .toLowerCase()
                .replace(/\w/, firstLetter => firstLetter.toUpperCase());
        },



    },

    mounted() {

        this.logVehicleId = this.$route.params.id;
        this.getFromId(this.logVehicleId);
    },
}
</script>

<style scoped>

</style>
