import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
//import colors from 'vuetify/lib/util/colors'
import env from './env'
import api from './api'
import rules from './rules'
import axios from 'axios'
import predefined from './global/predefined' // Contain global values, labels, etc.

Vue.prototype.$env = env;
Vue.prototype.$api = api;
Vue.prototype.$rules = rules;
Vue.prototype.$axios = axios;
Vue.prototype.$predefined = predefined;

// Filters
Vue.filter('hour', function (value) {
  let parts = value.split(':');
  return parts[0].padStart(2, '0') + ':' + parts[1].padStart(2, '0');
});

/**
 * return 'surname, name' if private client or bussiness name if company or entity
 *
 * @param {Object} client contains name
 */
Vue.filter('clientName', function (client, surnameOnly = false) {
  if (client.private_client==1) {
    if (surnameOnly)
      return client.surname;

    // Get surname, name
    if (client.name)
      return client.surname + ' ' + client.name;
    else
      return client.surname;
  } else {
    if (!client.business_name
        || client.business_name == '.'
        || client.business_name == ','
      )
      return 'Nessuna ragione sociale inserita';

    return client.business_name;
  }
});

Vue.use(Vuetify)

let opts = {
  theme: {
    themes: {
      light: {
        primary: '#929497',
        accent: '#ec1c24',
        /*
        primary: colors.grey.lighten2, // White
        secondary: '#97C8A3', // Green
        accent: '#B71C1C', // Red
        error: colors.red.accent3,*/
      },
      dark: {
        primary: '#929497',
        /*
        primary: colors.grey.darken4, // Black
        secondary: colors.grey.darken4, // Black
        accent: colors.grey.darken2, // Dark grey
        */
      },
    },
  },
}

const vuetify = new Vuetify(opts)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')