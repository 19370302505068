<template>
  <div>  

    <br />

    <v-container>
      <v-row>
        <v-col>
          <v-subheader><h1 class="accent--text">Elenco appuntamenti</h1></v-subheader>
          
          
          <div v-if="appointments">
            <v-container>
              <v-row>
                <v-col>
                  <template>
                    <v-data-table
                      :headers="headers"
                      :items="appointments"
                      :items-per-page="20"
                      class="elevation-5"
                    >

                      <template v-slot:item.client="{ item }">
                        {{item | clientName}}
                      </template>

                      <template v-slot:item.date="{ item }">
                        {{ item.date }} {{ item.time }}
                      </template>

                      <template v-slot:item.id_agent="{ item }">
                        {{ itemsAgents[item.id_agent] }}
                      </template>

                      <template v-slot:item.state="{ item }">
                        {{ itemsState[item.state] }}
                      </template>

                      <template v-slot:item.actions="{ item }">

                        <v-tooltip v-if="!item.order_created && $store.getters.rules.orders.create" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="createOrder(item.id)" class="mr-3" color="info" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-text-box-plus-outline</v-icon></v-btn>
                          </template>
                          <span>Crea ordine</span>
                        </v-tooltip>

                        <v-tooltip v-if="item.order_created && $store.getters.rules.orders.view" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-3 grey--text" @click="$router.push({path: '/orders/'+item.order_created})" small icon v-bind="attrs" v-on="on"><v-icon>mdi-text-box-outline</v-icon></v-btn>
                          </template>
                          <span>Visionare ordine</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="!item.order_created && $store.getters.rules.appointments.edit">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-3" color="success" @click="$router.push({path: '/appointment/modify/'+item.id})" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
                          </template>
                          <span>Modifica appuntamento</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="!item.order_created">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mr-3" @click="$router.push({path: '/documents/'+item.id})" x-small fab v-bind="attrs" color="info" v-on="on"><v-icon>mdi-file</v-icon></v-btn>
                          </template>
                          <span>Documenti allegati</span>
                        </v-tooltip>

                        <v-tooltip bottom v-if="!item.order_created && $store.getters.rules.appointments.delete">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="error" @click="deleteAppointment(item.id)" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
                          </template>
                          <span>Eliminare appuntamento</span>
                        </v-tooltip>                        
                      </template>                      

                    </v-data-table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modify -->
    <v-overlay v-model="modifyOverlay">
      <v-card class="mx-auto" max-width="800" light>
        <v-card-title>
            Modifica appuntamento
        </v-card-title>
        <v-card-text>
            <v-form ref="modifyForm" v-model="modifyForm" lazy-validation>

              <template>
                  <v-menu
                      ref="calendarMenu"
                      v-model="calendarMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                      <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="modifyValues.date"
                          label="Data*:"
                          v-bind="attrs"
                          v-on="on"
                          :rules="rules.required && rules.date" 
                          required
                      ></v-text-field>
                      </template>
                      <v-date-picker
                      ref="picker"
                      v-model="dateOriginalFormat"
                      min="1950-01-01"
                      @change="calendarSave"
                      locale="it"
                      :first-day-of-week="1"
                      ></v-date-picker>
                  </v-menu>
              </template>

                <v-text-field
                    v-model="modifyValues.time"
                    label="Ora inizio*:"
                    v-bind="attrs"
                    v-on="on"
                    :rules="rules.required && rules.hour"
                    required
                ></v-text-field>

                <v-text-field
                    v-model="modifyValues.time_end"
                    label="Ora fine*:"
                    v-bind="attrs"
                    v-on="on"
                    :rules="rules.required && rules.hour"
                    required
                ></v-text-field>                

            <v-autocomplete
                :rules="rules.required"
                v-model="modifyValues.id_client"
                :items="itemsClients"
                label="Cliente collegato*:"
            required ></v-autocomplete>

            <v-select :items="itemsAgentsForm" v-model="modifyValues.id_agent" label="Agente:"></v-select>
            <v-select :items="itemsHowKnew" v-model="modifyValues.how_knew_about_us" label="Come ci hai conosciuto:"></v-select>
            <v-textarea v-model="modifyValues.description" label="Descrizione Appuntamento Google Calendar"></v-textarea>
            <v-select :items="itemsStateForm" v-model="modifyValues.state" label="Stato Appuntamento:"></v-select>
            <!-- <v-file-input label="PDF Allegato"></v-file-input> -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="modifyOverlay = false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmModify">Modifica</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <!-- Delete -->
    <v-overlay v-model="deleteAppointmentOverlay">
      <v-card class="mx-auto" max-width="800" light>
        <v-card-title>
          Elimina appuntamento
        </v-card-title>
        <v-card-text>
          Sei sicuro di voler eliminare questo appuntamento? 
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="deleteAppointmentOverlay = false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmDeleteAppointment">Si</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

  </div>
</template>

<script>

import moment from 'moment'

export default {
    
  data() {
    return {

      on: false,
      attrs: false,

      dateOriginalFormat: '',
      calendarMenu: false,
      hourMenu: false,

      itemsAgentsForm: [
        {text: 'Nessun Agente', value: '0'},
        {text: 'Patrizio Ponzo', value: '1'},
        {text: 'Claudio D’Auria', value: '2'},
        {text: 'Antonio Amodio', value: '3'},
      ],

      itemsAgents: [
        'Nessun Agente',
        'Patrizio Ponzo',
        'Claudio D’Auria',
        'Antonio Amodio',
      ],

      itemsState: [
        'In trattativa',
        'Confermato',
        'Fallito',
      ],

      itemsStateForm: [
        {text: 'In trattativa', value: '0'},
        {text: 'Confermato', value: '1'},
        {text: 'Fallito', value: '2'},
      ],

      itemsHowKnew: [
          {text: 'Nessun valore', value: '0'},
          {text: 'Passaparola', value: '1'},
          {text: 'Sito Web', value: '2'},
          {text: 'E-Mail', value: '3'},
          {text: 'Vecchio cliente', value: '4'},
      ],

      itemsClients: [],

      rules: {
        required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
        hour: [ value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true ],
        date: [ value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true ],
      },

      modifyOverlay: false,

      modifyForm: false,
      modifyValues: {
        date: null,           
        time: null,
        time_end: null,
        id_client: null,
        id_agent: null,
        how_knew_about_us: null,
        description: null,
        state: 0,
      },

      deleteAppointmentOverlay: false,
      deleteAppointmentId: false,

      headers: [
        {text: 'Data e ora', value: 'date', sortable: true, sort: this.sortDates},
        {text: 'Cliente', value: 'client', sortable: false},
        {text: 'Agente', value: 'id_agent'},
        {text: 'Stato', value: 'state'},
        {text: 'Azione', value:'actions', sortable: false},
      ],

      appointments: null, // {name:'a',email:'a',phone:'a',cellphone:'a',address:'a'},
    
    }
  },

  methods: {
    // Sorts
    sortDates(a, b) {
      return moment(a, 'DD-MM-YYYY hh:ss').toDate() - moment(b, 'DD-MM-YYYY hh:ss').toDate();
    },

    calendarSave (date) {
      this.$refs.calendarMenu.save(date)
    },    

    getAll() {
        this.$emit('showProgress');
        this.$api.appointment.getAll().then(data => {
            this.$emit('hideProgress');

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            this.appointments = data.values.appointments.sort((a, b) => {
              return moment(b.date, 'DD-MM-YYYY hh:ss').toDate() - moment(a.date, 'DD-MM-YYYY hh:ss').toDate();
            })

			if(!this.$env.siteA) {
				let dateLimit = moment('15-10-2023', 'DD-MM-YYYY');

				this.appointments = this.appointments.filter(el => {
					let date = moment(el.date, 'DD-MM-YYYY');

					return !(date > dateLimit && el.state == 1 && el.flag_renata == 0);
				
				})
			}
        });
    },

    getAllClients() {
        this.$api.client.getAll().then(data => {

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            this.itemsClients = data.values.clients.map( el => { return {text: el.name, value: el.id} });
        });
    },

    modifyAppointment(id) {
      this.$emit('showProgress');

      // Get clients list
      this.getAllClients();

      // Get appointment data
      this.$api.appointment.getFromId(id).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          this.modifyValues = data.values.appointment;

          this.$emit('hideProgress');
      });
      
      this.modifyOverlay = true;
    },

    confirmModify() {
      if (!this.$refs.modifyForm.validate())
        return;
      this.$emit('showProgress');

      // Save appointment data
      this.$api.appointment.updateAppointment(this.modifyValues).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          // Update appointments list
          this.getAll();
          
          this.modifyOverlay = false;

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Appuntamento modificato', 'success');
      });
      
    },

    // Delete appointment
    deleteAppointment(id) {
      this.deleteAppointmentId = id;
      this.deleteAppointmentOverlay = true;
    },

    confirmDeleteAppointment() {
      this.$api.appointment.deleteAppointment(this.deleteAppointmentId).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          // Update appointments list
          this.getAll();

          this.deleteAppointmentOverlay = false;

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Appuntamento eliminato', 'success');
      });
    },

    createOrder(id) {
      this.$api.orders.createNew(id).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          this.$emit('hideProgress');
          // Redirect to new order
          this.$router.push({path: '/orders/'+data.values.id_order})

          // Update appointments list
          /*
          this.getAll();

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Ordine creato', 'success');
          */
      });
    },
  },
  
  watch: {
    dateOriginalFormat(val) {
        this.modifyValues.date = val.split('-').reverse().join('-');
    },

    'modifyValues.time'(val) {
      // Validate hour
      if (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(val) || this.values.time_end)
          return false;

      let time = val.split(':');
      let hour = new Date();
      hour.setHours(time[0], time[1], 0);
      hour.setHours(hour.getHours()+1);
      this.values.time_end = hour.getHours() + ':' + hour.getMinutes();
    },    
  },

  // Events
  mounted() {    
    this.getAll();
  },

}

</script>