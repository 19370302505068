<template>
  <div>  

    <br />

    <v-subheader><h1 class="accent--text">Storico magazzino</h1></v-subheader>

      <div v-if="materials">
        <v-container>
          <v-row>
            <v-col>

                <v-data-table
                  :headers="headers"
                  :items="materials"
                  :items-per-page="20"
                  class="elevation-5"
                >
                </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </div>

  </div>
</template>

<script>

export default {

  data() {
    return {

    headers: [
      {text:'Data e ora', value:'date'},
      {text:'Materiale', value:'name'},
      {text:'Incremento', value:'increment'},
    ],
    materials:null,

    }
  },

  methods: {
    
    getMaterialsHistory() {
      this.$emit('showProgress');
      this.$api.orders.getMaterialsHistory().then(data => {
          this.$emit('hideProgress');

          if (!data.ok) {
              this.$emit('showSnack', 'Database error', 'error');
              return;
          }

          // Ok
          this.materials = data.values.materials;
      });
    },

  },

  // Events
  mounted() {
    this.getMaterialsHistory();
  },

}

</script>

<style scoped>

.noPadding {
    padding: 0px !important;
}

</style>