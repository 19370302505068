<template>
    <div>

        <v-container>
            <v-row>
                <v-col>
                    <v-subheader><v-btn x-large color="primary" icon @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn> <h1 class="accent--text">Modifica Log Automezzo</h1></v-subheader>
                </v-col>
            </v-row>
        </v-container>
        <br/>

        <v-card class="mx-auto" max-width="800">

            <v-card-text>
                <v-form ref="form" v-model="form" lazy-validation>

                    <v-select :items="getAllVehicleData"
                              :rules="rules.required"
                              v-model="values.automezzo_id"
                              item-value="id"
                              item-text="name"
                              label="Automezzo*:"
                    >
                    </v-select>

                    <v-text-field
                        v-model="values.intervention_type"
                        :rules="rules.required"
                        label="Tipo intervento"
                        required
                    ></v-text-field>

                    <v-textarea
                        v-model="values.description"
                        :rules="rules.required"
                        label="Descrizione...">
                    </v-textarea>


                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="$router.go(-1)">Annulla</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="accent" @click="confirmModify">modifica</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {

            rules: {
                required: [value => (!value) ? 'Il campo non può essere vuoto.' : true],
                hour: [value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true],
                date: [value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true],
            },

            getAllVehicleData: [],

            form: false,
            showForm: true,
            values: {
                user_id: null,
                automezzo_id: null,
                intervention_type: null,
                //date: null,
                description: null,
            },

            logVehicleId: false,

        }
    },

    methods: {

        getAllVehicle() {
            this.$emit('showProgress');
            this.$api.logVehicle.getAllVehicle().then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                console.log(data.values.vehicle)
                this.getAllVehicleData = data.values.vehicle;


            });
        },

        getLogVehicle(id) {
            this.$emit('showProgress');

            // Get memo data
            this.$api.logVehicle.getFromId(id).then(data => {
                if (!data.ok) {
                    this.$emit('showSnack', 'Error connecting to database', 'error');
                    return;
                }

                let logVehicle = data.values.logVehicle;



                this.values = {
                    user_id: logVehicle.user_id,
                    automezzo_id: logVehicle.automezzo_id,
                    intervention_type: logVehicle.intervention_type,
                    description: logVehicle.description,
                };

                this.$emit('hideProgress');
            });

            this.modifyOverlay = true;
        },

        confirmModify() {
            if (!this.$refs.form.validate())
                return;
            this.$emit('showProgress');

            // Save logVehicle data
            this.values.id = this.logVehicleId;
            this.$api.logVehicle.updateLogVehicle(this.values).then(data => {
                if (!data.ok) {
                    this.$emit('showSnack', 'Error connecting to database', 'error');
                    return;
                }

                this.$emit('hideProgress');
                this.$emit('showSnack', 'Appuntamento modificato', 'success');
            });
        },

    },

    mounted() {
        this.logVehicleId = this.$route.params.id;
        this.getLogVehicle(this.logVehicleId);
        this.getAllVehicle();

    }
}
</script>

<style scoped>

</style>
