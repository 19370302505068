<template>
  <div>  

    <v-subheader><h2>Agenda</h2></v-subheader>

    <!-- Next events -->

        <v-card class="mx-auto" max-width="800" color="secondary">
            <v-card-title>Prossimi eventi</v-card-title>
            <v-card-text>
                Non ci sono eventi registrati.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="accent">
                <v-icon left>
                  mdi-plus
                </v-icon>
                Inserire evento
              </v-btn>
            </v-card-actions>
        </v-card>

    <!-- Next events -->

    <br /><br />

    <v-card class="mx-auto" max-width="800">
        <v-card-title>Prossime scadenze</v-card-title>
        <v-card-text>
            Non ci sono date di scadenza.
        </v-card-text>
    </v-card>

  </div>
</template>

<script>

export default {
  
  data() {
    return {
      
      }
  },

  methods: {

  },

}

</script>