<template>
  <div>

      <br />

    <v-container>
      <v-row>
        <v-col>
          <v-subheader><v-btn x-large color="primary" icon @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn> <h1 class="accent--text">Modifica appuntamento</h1></v-subheader>
        </v-col>
      </v-row>
    </v-container>

    <br />

        <v-card class="mx-auto" max-width="800">

            <v-card-text>
                <v-form ref="form" v-model="form" lazy-validation>

                    <template>
                        <v-menu
                            ref="calendarMenu"
                            v-model="calendarMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="values.date"
                                label="Data*:"
                                v-bind="attrs"
                                v-on="on"
                                :rules="rules.required && rules.date"
                                required
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            ref="picker"
                            v-model="dateOriginalFormat"
                            min="1950-01-01"
                            @change="calendarSave"
                            locale="it"
                            :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </template>

                    <v-text-field
                        v-model="values.time"
                        label="Ora inizio*:"
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules.required && rules.hour"
                        required
                    ></v-text-field>

                    <v-text-field
                        v-model="values.time_end"
                        label="Ora fine*:"
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules.required && rules.hour"
                        required
                    ></v-text-field>

                    <v-autocomplete
                        :rules="rules.required"
                        v-model="values.client"
                        :items="itemsClients"
                        label="Cliente collegato*:"
                        @change="getClientAddress"
                    required ></v-autocomplete>

                    <v-select :items="clientAddresses"
                        :rules="rules.required"
                        v-if="values.client"
                        v-model="values.client_address"
                        label="Indirizzo*:"
                        >
                    </v-select>

                    <v-select :items="itemsAgents" v-model="values.agent" label="Agente:"></v-select>
                    <v-select :items="itemsHowKnew" v-model="values.howKnew" label="Come ci hai conosciuto:"></v-select>
                    <v-textarea v-model="values.description" label="Descrizione Appuntamento Google Calendar"></v-textarea>

                    <v-row>
                        <v-col cols="8">
                            <v-select :items="itemsState" v-model="values.state" label="Stato Appuntamento:"></v-select>
                        </v-col>                        
                        <v-col>
                            <v-switch
                                class="ml-5"
                                label="Fuori dalla campania"
                                color="success"
                                :false-value="0"
                                :true-value="1"
                                v-model="values.is_out_campania"
                            ></v-switch>
                        </v-col>
                    </v-row>                                            
                    <!-- <v-file-input label="PDF Allegato"></v-file-input> -->
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="$router.go(-1)">Annulla</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="$store.getters.rules.appointments.edit" color="success" @click="confirmModify">Modifica appuntamento</v-btn>
            </v-card-actions>
        </v-card>

  </div>
</template>

<script>

export default {

  data() {
    return {

        // Date select
        date: null,
        calendarMenu: false,
        dateOriginalFormat: '',
        hourMenu: false,

        itemsAgents: [
            {text: 'Nessun Agente', value: 0},
            {text: 'Patrizio Ponzo', value: 1},
            {text: 'Claudio D’Auria', value: 2},
            {text: 'Antonio Amodio', value: 3},
        ],

        itemsHowKnew: [
            {text: 'Nessun valore', value: 0},
            {text: 'Passaparola', value: 1},
            {text: 'Sito Web', value: 2},
            {text: 'E-Mail', value: 3},
            {text: 'Vecchio cliente', value: 4},
        ],

        itemsState: [
            {text: 'Confermato', value: 1},
            {text: 'Fallito', value: 2},
            {text: 'In trattativa', value: 0},
        ],

        itemsClients: [],

        rules: {
          required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
          hour: [ value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true ],
          date: [ value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true ],
        },

        form: false,
        showForm: true,
        values: {
            date: null,
            time: null,
            time_end: null,
            client: null,
            client_address: null,
            agent: null,
            howKnew: null,
            description: null,
            is_out_campania: 0,
            state: 0,
        },
        clientAddresses: [],

        appointmentId: false,

      }
  },

  methods: {

    getAppointment(id) {
      this.$emit('showProgress');

      // Get clients list
      this.getAllClients();

      // Get appointment data
      this.$api.appointment.getFromId(id).then(data => {
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          let ap = data.values.appointment;

          this.clientAddresses = data.values.addresses;

          this.values = {
              date: ap.date,
              time: ap.time,
              time_end: ap.time_end,
              client: ap.id_client,
              client_address: ap.id_address,
              agent: parseInt(ap.id_agent),
              howKnew: parseInt(ap.how_knew_about_us),
              description: ap.description,
              is_out_campania: parseInt(ap.is_out_campania) ,
              state: parseInt(ap.state),
          };

          this.$emit('hideProgress');
      });

      this.modifyOverlay = true;
    },


    getClientAddress() {
        this.$api.appointment.getClientAddress(this.values.client).then(data => {
            if (!data.ok) {
                this.$emit('showSnack', 'Il cliente non ha nessun indirizzo associato.', 'error');
            }

            this.clientAddresses = data.values.addresses;
            this.values.client_address = this.clientAddresses[0].value;
        });
    },

    calendarSave (date) {
        this.$refs.calendarMenu.save(date)
    },

    getAllClients() {
        this.$api.client.getAll().then(data => {

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            this.itemsClients = data.values.clients.map( el => { return {text: el.name, value: el.id} });
        });
    },

    insertAppointment() {
        if (!this.$refs.newAppointment.validate())
            return;

        // Send new appointment to database
        this.$emit('showProgress');
        this.$api.appointment.createNew(this.values).then(data => {
            this.$emit('hideProgress');

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            // Ok
            this.$refs.newAppointment.reset();
            this.$emit('showSnack', 'Appuntamento inserito', 'success');

        });
    },

    confirmModify() {
      if (!this.$refs.form.validate())
        return;
      this.$emit('showProgress');

      // Save appointment data
      this.values.id = this.appointmentId;
      this.$api.appointment.updateAppointment(this.values).then(data => {
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Appuntamento modificato', 'success');
      });
    },

  },

  watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },

      dateOriginalFormat(val) {
          this.values.date = val.split('-').reverse().join('-');
      },

      'values.time'(val) {
        // Validate hour
        if (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(val) || this.values.time_end)
            return false;

        let time = val.split(':');
        let hour = new Date();
        hour.setHours(time[0], time[1], 0);
        hour.setHours(hour.getHours()+1);
        this.values.time_end = hour.getHours() + ':' + hour.getMinutes();
      },
  },

    mounted() {
        this.appointmentId = this.$route.params.id;
        this.getAppointment(this.appointmentId);
    },

}

</script>
