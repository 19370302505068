import Vue from 'vue'
import Vuex from 'vuex'

import users from '../global/permissions.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    username: false,
    userEmail: false,
    userLevel: 0,
    rules: false,
    user: null,

    DEBUG: false,
  },

  getters: {
    DEBUG: state => {
      return state.DEBUG;
    },

    loggedIn: state => {
      return state.loggedIn;
    },
    username: state => {
      return state.username;
    },
    userLevel: state => {
      return state.userLevel;
    },
    user: state => {
      return state.user;
    },
    isAdmin: state => {
      return (state.userLevel > 1) ? true : false;
    },
    rules: state => {
      return state.rules;
    },
  },

  mutations: {

    login: state => {
      state.loggedIn = true;     
    },

    setUserLevel: (state, level) => {
      state.userLevel = level;
    },

    setUser: (state, user) => {
      state.user = user;
    },

    setUsername: (state, username) => {
      state.username = username;
      state.rules = users[username];
    },

    logout: state => {
      // Reset all values
      state.user = null;
      state.loggedIn = false; 
      state.username = false;
      state.rules = false;
      state.userEmail = false;
      state.userLevel = 0;
    },

    toggleDebug: state => {
      state.DEBUG = !state.DEBUG;
    }

  },

  actions: {

    login: ({commit}, params) => {
      commit('login');
      commit('setUser', params.user);
      commit('setUsername', params.username);
    },

    logout: ({commit}) => {
      commit('logout');
    },

    toggleDebug: ({commit}) => {
      commit('toggleDebug');
    },

  },

  modules: {
  },

})