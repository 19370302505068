<template>
    <div>

        <v-container>
            <v-row>
                <v-col>
                    <v-subheader><v-btn x-large color="primary" icon @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn> <h1 class="accent--text">{{logWorkers.surname}} {{logWorkers.name}}</h1></v-subheader>
                </v-col>
            </v-row>
        </v-container>

        <v-card
            class="mx-auto my-12"
            max-width="700">

            <template slot="progress">
                <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                ></v-progress-linear>
            </template>


            <v-card-title> <h3>{{ logWorkers.surname ? capitalize(logWorkers.surname) : null }} {{ logWorkers.name ? capitalize(logWorkers.name) : null}}</h3></v-card-title>

            <div class="grey--text ms-4">
                Creato il: {{ logWorkers.date_created }}
            </div>

            <v-card-text>
                <v-row align="center" class="mx-0">
                    <div class="my-4 text-subtitle-1">

                    </div>
                </v-row>

                <v-row align="center" class="mx-0">
                    <div class="my-4 text-subtitle-1">
                        Importo: {{ logWorkers.amount }}
                    </div>
                </v-row>

                <v-row align="center" class="mx-0 mt-8">
                    <div class="my-4 text-subtitle-1">
                        Descrizione: <br> {{ logWorkers.description }}
                    </div>
                </v-row>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>

            <v-card-actions>

                <v-btn
                    color="deep-purple lighten-2"
                    text
                    @click="$router.push({path: '/logWorkers/modify/'+ logWorkers.id})"
                >
                    Modifica
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {

            logWorkers: [],
            logWorkersId: null,

        }
    },

    methods: {

        getFromId(id) {
            this.$emit('showProgress');
            this.$api.logWorkers.getFromId(id).then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                console.log(data.values.logWorkers);

                this.logWorkers = data.values.logWorkers;
            });
        },

        capitalize(word) {
            return word
                .toLowerCase()
                .replace(/\w/, firstLetter => firstLetter.toUpperCase());
        },

    },

    mounted() {

        this.logWorkersId = this.$route.params.id;
        this.getFromId(this.logWorkersId);
    },
}
</script>

<style scoped>

</style>
