<template>
    <div>
        <br/>

        <v-container>
            <v-row>
                <v-col>

                    <memo-card 
                        :title="capitalize(this.memo.title)"
                        :user_id="this.memo.user_id"
                        :date="this.memo.date"
                        :description="this.memo.description"
                        :id-link="this.memo.id"
                    />

                </v-col>
            </v-row>
        </v-container>

    </div>
</template>

<script>
import MemoCard from "../../components/MemoCard.vue";
export default {
    components: {MemoCard},

    data() {
        return {

            memo: {},
            memoId: null,

        }
    },

    methods: {

        getFromId(id) {
            this.$emit('showProgress');
            this.$api.memo.getFromId(id).then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.memo = data.values.memo;
            });
        },

        capitalize(word) {
            return word
                .toLowerCase()
                .replace(/\w/, firstLetter => firstLetter.toUpperCase());
        },



    },

    mounted() {

        this.memoId = this.$route.params.id;
        this.getFromId(this.memoId);
    },
}
</script>

<style scoped>

</style>
