<template>
    <div class="d-inline">
        <v-icon :color="color" small>mdi-square</v-icon>
        <span class="legend-label" :style="{color}" >
            {{label}}
        </span>
    </div>
</template>

<script>
    export default {
        name: 'Legend',
        props: {
            label: null,
            color: null,
        },
    }
</script>

<style>

    .legend-label {
        font-size: 80% !important;
    }

    @media (max-width:768px) {
        .legend-label {
            font-size: 90%;
        }
    }

</style>
