<template>
  <div>  

    <br />

    <v-subheader><v-btn x-large color="primary" icon @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn> <h1 class="accent--text">Scheda Ordine nº {{order.id}}</h1></v-subheader>

    <!-- Edit agent dialog -->
    <v-overlay v-model="showEditAgentDialog" class="no-y-scroll">
      <v-card min-width="600" light>
        <v-card-title>
          Imposta agente appuntamento
        </v-card-title>
        <v-card-text>
          <v-select v-model="newAgent" :items="agents"></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="showEditAgentDialog=false">
            Annulla
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveNewAgent">
            Conferma
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <!-- client -->
    <v-card class="mx-auto" flat> 
      <v-card-title>Riepilogo cliente</v-card-title>
      <v-card-text>        
        {{order | clientName}}  

        <div v-if="order.email">
          {{ order.email }}
        </div>

        <div v-if="order.phone">            
          {{ order.phone }}
        </div>

        <div v-if="order.cellphone">
          {{ order.cellphone }}
        </div>            

        <br /><br />

        <b>Luoghi di carico</b>            
        <span v-for="(place, index) in order.places" :key="index">
          <br />
          {{place.text}}
        </span>
      </v-card-text>
    </v-card>

    <!-- appointment -->
    <v-card class="mx-auto" flat> 
      <v-card-title>Appuntamento</v-card-title>
      <v-card-text>
        <h4>Data e ora appuntamento</h4>
        {{ order.date }} {{ order.time }}        
        <span v-if="order.time_end">- {{ order.time_end }}</span>

        <h4>Indirizzo</h4>
        {{ (order.places.find(el => el.id==appointment.id_address)) ? order.places.find(el => el.id==appointment.id_address).text : 'indirizzo non valido' }}
        <h4>Fuori campania</h4>
        {{ (appointment.is_out_campania == '0') ? 'No' : 'Sì' }}

        <h4>Agente</h4>
        {{agents.find(el => el.value == appointment.id_agent).text || '-'}}
        <!-- Edit agent -->
        <v-btn color="success" v-if="$store.getters.rules.appointments.edit" icon @click="editAgent">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>

      </v-card-text>
    </v-card>

    <v-card class="mx-auto" flat>      
      <v-card-title>Altre info</v-card-title>

      <v-card-text>

        <v-card >
          <v-card-text> 


            <v-card flat>
              <v-card-text>
                <v-simple-table>
                  <tbody>
                    <tr v-if="$env.siteA">
                      <td width="40%">Prezzo Pattuito</td>
                      <td class="text-center"><b>€ {{ order.agreed_price }}</b></td>
                    </tr>
                    <tr v-else>
                      <td width="40%">Prezzo Pattuito</td>
                      <td class="text-center"><b>€ {{ order.agreed_price_2 }}</b></td>
                    </tr>
                    <tr>
                      <td>Tipologia pagamento</td>
                      <td class="text-center">{{paymentTypeText[order.payment_type]}}</td>
                    </tr>
                  </tbody>
                </v-simple-table>

                <br />
                <v-divider></v-divider>
                <br />
                <h3 v-if="$env.siteA">Renata:
                  <span v-if="order.flag_renata==true" class="success--text" color="success">Sì <v-icon color="success">mdi-check</v-icon></span>
                  <span v-if="order.flag_renata!=true" class="error--text" color="success">No <v-icon color="error">mdi-close</v-icon></span>
                </h3> 
                <br />
              </v-card-text>
            </v-card>

            <v-card outlined v-if="$env.siteA">
              <v-card-title>
                Note ordine:
              </v-card-title>
              <v-card-text>
                {{ order.notes || '-' }}
              </v-card-text>
            </v-card>

            <br />

            <v-card outlined v-if="$env.siteA">
              <v-card-title>
                Note Patrizio:
              </v-card-title>
              <v-card-text>
                <span v-if="!$store.getters.rules.orders.notesPatrizio">
                  {{ order.notes_patrizio || '-' }}
                </span>
                <div v-if="$store.getters.rules.orders.notesPatrizio">
                  <v-textarea v-model="order.notes_patrizio">
                  </v-textarea>
                  <v-btn color="success" @click="saveNotesPatrizio">Salva note</v-btn>
                </div>
              </v-card-text>
            </v-card>

            <br />

            <v-card outlined>
              <v-card-title>
                Costi aggiuntivi:
              </v-card-title>
              <v-card-text>
                <v-simple-table>
                  <tbody>
                    <!--
                    <tr>
                      <td width="40%">
                        Somma dei costi lavorazioni
                      </td>
                      <td class="text-center">
                        <b>€ {{ order.totalCostWorks || '0' }}</b>
                      </td>                    
                    </tr>
                    -->
                    <tr>
                      <td width="40%">
                        Somma dei costi materiali
                      </td>
                      <td class="text-center">
                      <!-- TODO: calculate total cost with materials claudio -->
                        <b>€ {{ order.totalCostMaterials || '0' }}</b>
                      </td>                    
                    </tr>
                    <tr>
                      <td>
                        Somma costi permessi
                      </td>
                      <td class="text-center">
                        <b>€ {{ order.totalCostPermissions || '0' }}</b>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>

            <br />
            
            <v-simple-table class="mt-2 mb-1">
              <tbody>
                <tr>
                  <td width="80%">
                    <h3>Costo totale</h3> (somma di prezzo pattuito + somma dei costi dei materiali + somma costi permessi)
                  </td>
                  <td class="text-center">
                    <h3>€ {{ totalOrderCost }}</h3>
                  </td>                    
                </tr>
              </tbody>
            </v-simple-table>


            <br />
            <v-btn v-if="$env.siteA" color="info" @click="$router.push({path: '/documents/'+ order.id_appointment })">Documenti allegati</v-btn>
            
          </v-card-text>
          <v-card-actions>
            
            <v-spacer></v-spacer><v-btn v-if="$store.getters.rules.documents.edit" @click="modifyOrderOverlay=true" small icon color="success"><v-icon>mdi-pencil</v-icon></v-btn>
          </v-card-actions>
        </v-card>

      </v-card-text>
    </v-card>

    <v-overlay v-model="modifyOrderOverlay">
      <v-card light min-width="700">
        <v-card-title>Modifica ordine</v-card-title>
        <v-card-text>

          <v-form ref="modifyForm">

            <v-container>
              <v-row>
                <v-col v-if="$env.siteA">
                  <v-text-field v-model="order.agreed_price" label="Prezzo pattuito:"></v-text-field>                  
                  <v-text-field v-model="order.agreed_price_2" label="Prezzo pattuito 2:"></v-text-field>
                    Si ricorda di inserire i prezzi a netto d'iva
                </v-col>
                <v-col v-else>                  
                  <v-text-field v-model="order.agreed_price_2" label="Prezzo pattuito:"></v-text-field>
                  Si ricorda di inserire i prezzi a netto d'iva
                </v-col>				
              </v-row>
              <v-row>
                <v-col>
                  <v-select v-model="order.payment_type" label="Tipologia pagamento:" :items="paymentType"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="$env.siteA && $store.getters.rules.editFlagRenata">
                  <v-switch
                    label="Renata"
                    color="success"
                    :false-value="0"
                    :true-value="'1'"
                    v-model="order.flag_renata"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row v-if="$env.siteA">
                <v-col>
                  <v-textarea v-model="order.notes" label="Note ordine:"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="modifyOrderOverlay = false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmModifyOrder">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay> 

    <v-card class="mx-auto"> 
      <v-card-title>Lavorazioni</v-card-title>

      <v-card-text>

        <v-card>
          <v-card-text> 
            
            <v-data-table
              :headers="($env.siteA) ? headersWorks : headersWorksSiteB"
              :items="order.works"
              disable-pagination
              hide-default-footer
              class="elevation-0"
            >

              <template v-slot:item.notes_claudio="{ item }">
                <div style="max-width: 300px;">
                  {{item.notes_claudio}}

                  <!-- Modify note if user is claudio -->
                    <v-tooltip bottom v-if="canEditNotesClaudio">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="success" icon x-small fab @click="edtNotesClaudio(item)" v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
                      </template>
                    <span>Modifica note</span>
                  </v-tooltip>
                </div>
              </template>

              <template v-slot:item.actions="{ item }">

                <v-tooltip bottom v-if="$store.getters.rules.workersVehicles.edit">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="showEditWorkersVehicles(item.id, item.date)" class="mr-1" color="orange" icon x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-account-hard-hat</v-icon></v-btn>
                  </template>
                  <span>Gestire operai e automezzi</span>
                </v-tooltip>
                <!--
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="showEditWorkers(item.id)" class="mr-2" color="orange" icon x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-account-hard-hat</v-icon></v-btn>
                  </template>
                  <span>Gestire operai</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="showEditVehicles(item.id)" class="mr-2" color="blue-grey" icon x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-truck</v-icon></v-btn>
                  </template>
                  <span>Gestire automezzi</span>
                </v-tooltip>
                -->

                <v-tooltip bottom v-if="$store.getters.rules.resources.view">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="showEditResources(item.id)" class="mr-1" color="info" icon x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-hammer-screwdriver</v-icon></v-btn>
                  </template>
                  <span>Gestire risorse</span>
                </v-tooltip>

                <v-tooltip bottom v-if="$env.siteA && $store.getters.rules.editResourcesB">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="showEditResourcesB(item.id)" class="mr-1" color="warning" icon x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-hammer-screwdriver</v-icon></v-btn>
                  </template>
                  <span>Gestire risorse B</span>
                </v-tooltip>                
               
                <v-tooltip bottom v-if="$store.getters.rules.resources.view">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success" icon x-small fab @click="editWork(item.id)" v-bind="attrs" v-on="on" v-if="$store.getters.rules.works.edit"><v-icon>mdi-pencil</v-icon></v-btn>
                  </template>
                  <span>Modifica lavorazione</span>
                </v-tooltip>
                

                <v-tooltip bottom v-if="$store.getters.rules.works.edit || ($store.getters.rules.works.deleteRenata && item.flag_renata==1)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="error" @click="showDeleteWorkDialog(item.id)" icon x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
                  </template>
                  <span>Elimina lavorazione</span>
                </v-tooltip>   

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a :href="'/api/works/pdf/' + item.id" target="_blank">
                      <v-btn color="red" icon v-bind="attrs" v-on="on">
                        <v-icon>
                          mdi-file-pdf-outline
                        </v-icon>
                      </v-btn>
                    </a>
                  </template>
                  <span>Stampa scheda lavorazione</span>
                </v-tooltip>  

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a :href="'/api/materiali/pdf/' + item.id" target="_blank">
                      <v-btn color="error" icon v-bind="attrs" v-on="on">
                        <v-icon color="blue">
                          mdi-file-pdf-outline
                        </v-icon>
                      </v-btn>
                    </a>
                  </template>
                  <span>Riepilogo materiali</span>
                </v-tooltip>   

              </template>     

              <!--
              <template v-slot:item.summary="{ item }">

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <a :href="'/api/works/pdf/' + item.id" target="_blank"><v-btn color="error" icon x-small fab v-bind="attrs" v-on="on">
                    <v-icon>mdi-file-pdf-outline</v-icon>
                    </v-btn></a>
                  </template>
                  <span>Riepilogo materiali</span>
                </v-tooltip>   

              </template>
              -->

              <template v-slot:item.show_worksheet="{ item }">
                <v-btn class="mr-3" :color="(item.show_worksheet==1) ? 'success' : 'error'" icon x-small fab><v-icon>{{ (item.show_worksheet==1) ? 'mdi-check' : 'mdi-close' }}</v-icon></v-btn>
              </template>

              <template v-slot:item.flag_renata="{ item }">
                <v-btn class="mr-3" :color="(item.flag_renata==1) ? 'success' : 'error'" icon x-small fab><v-icon>{{ (item.flag_renata==1) ? 'mdi-check' : 'mdi-close' }}</v-icon></v-btn>
              </template>

              <!-- Show work cost -->
              <!--
              <template v-slot:item.work_cost="{ item }">
                {{ (item.work_cost) ? '€' + item.work_cost : '-' }}
              </template>
              -->

              <template v-slot:item.type="{ item }" v-if="itemsWorkType.length > 0">
                {{
                  (item.type) ? itemsWorkType[item.type].text : '-'
                }}
              </template>

            </v-data-table>

            <v-simple-table v-if="$store.getters.rules.resources.edit">
              <tbody>
                <tr>
                  <td>
                    <v-btn @click="newWork" small icon color="success"><v-icon>mdi-plus</v-icon></v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

          </v-card-text>
        </v-card>

      </v-card-text>
    </v-card>

    <v-overlay v-model="overlayWorks">
      <v-card light min-width="700">
        <v-card-title>{{(!currentWorkId) ? 'Inserisci lavorazione' : 'Modifica lavorazione'}}</v-card-title>
        <v-card-text>
          <v-form ref="formWorks">
              <v-row>
                <v-col cols="8">
                  <template>
                      <v-menu
                          ref="calendarFormWorks"
                          v-model="calendarFormWorks"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="worksForm.date"
                              label="Data*:"
                              v-bind="attrs"
                              v-on="on"
                              :rules="rules.required && rules.date" 
                              required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                        ref="picker"
                        v-model="worksFormOriginalFormat"
                        min="1950-01-01"
                        locale="it"
                        :first-day-of-week="1"
                        ></v-date-picker>
                      </v-menu>
                  </template>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="worksForm.hour"
                    label="Ora*:"
                    v-bind="attrs"
                    v-on="on"
                    :rules="rules.required && rules.hour"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              
              <v-row>
                <v-col>
                  <v-switch class="m-0" v-model="worksForm.show_worksheet" color="success" label="Visibile in schermata lavorazioni (claudio)"></v-switch>
                </v-col>
                <v-col v-if="$env.siteA && $store.getters.rules.editFlagRenata">
                  <v-switch
                    class="m-0"
                    label="Renata"
                    color="success"
                    :false-value="0"
                    :true-value="'1'"
                    v-model="worksForm.flag_renata"
                  ></v-switch>
                </v-col>
              </v-row>

            <v-row>
            <!--
              <v-col cols="12" md="4">
                <v-text-field v-model="worksForm.work_cost" type="number" label="Costo lavorazione:" prefix="€"></v-text-field> 
              </v-col>
            -->
              <v-col cols="12" md="8">
                <v-select v-model="worksForm.type" label="Tipologia lavoro:" :items="itemsWorkType"></v-select>
              </v-col>
            </v-row>
                                               
            <v-select v-model="worksForm.id_pickupPlace" label="Indirizzo del lavoro:" :items="itemsWorksPlaces"></v-select>
            <v-textarea v-model="worksForm.notes" label="Note:" rows="2" v-if="$env.siteA"></v-textarea>
            
            <!-- Costo permesso and flag 'permesso in schermata lavorazioni' -->
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="worksForm.police_permission" :rules="rules.integer" type="number" label="Costo Permesso:" prefix="€"></v-text-field> 
              </v-col>
              <v-col>
                <v-switch
                  class="m-0"
                  label="Permesso in schermata lavorazioni"
                  color="success"
                  :false-value="0"
                  :true-value="1"
                  v-model="worksForm.flag_in_schermata_lavorazioni"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="overlayWorks = false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="processWorkForm">{{(!currentWorkId) ? 'Inserisci' : 'Modifica'}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay> 

    <v-overlay v-model="overlayConfirmDeleteWork">
      <v-card light min-width="600">
        <v-card-title>Elimina lavorazione</v-card-title>
        <v-card-text>
          Clicca "conferma" per eliminare la lavorazione
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="overlayConfirmDeleteWork = false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmDeleteWork">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>  

    <!-- Resources -->
    <v-dialog
      v-model="overlayResources"
      scrollable
      max-width="800px"
    >
      <v-card light min-width="800">
        <v-card-title>Gestire risorse</v-card-title>
        <v-card-text>

          <v-simple-table>
            <thead>
              <tr>
                <th>
                  Risorsa
                </th>
                <th>
                  Quantità
                </th>
                <th>
                  Disp. / Totali
                </th>  
              </tr>
            </thead>
            <tbody>
              <tr v-for="(resource, index) in resourcesLabels" v-bind:key="index">
                <td>
                  {{resource.name}}
                </td>
                <td>
                  <v-text-field type="number" hide-details dense outlined v-model="resourcesValues[resource.id]" v-if="$store.getters.rules.works.edit"></v-text-field>
                  <span v-if="!$store.getters.rules.works.edit">
                    {{ resourcesValues[resource.id] }}
                  </span>
                </td>
                <td>
                  <span :class="(resourcesAvailable[resource.id]<0) ? 'red--text' : ''">
                    {{resourcesAvailable[resource.id]}}

                    <!-- Hide total amount if resource is Operai (Op) -->
                    <span v-if="resource.name != 'Op'">
                      / {{resource.ammount}}
                    </span>
                    </span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="overlayResources = false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveResources" v-if="$store.getters.rules.works.edit">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <resources-b-dialog
        :overlayResourcesB="overlayResourcesB"
        :resourcesLabels="resourcesLabels"
        :resourcesValues="resourcesValues"
        :resourcesAvailable="resourcesAvailable"

        @close="overlayResourcesB = false"
        @save="saveResourcesB"
    />

    <v-card class="mx-auto" flat v-if="$store.getters.rules.materials.view"> 
      <v-card-title>Elenco materiali</v-card-title>

      <v-card-text>

        <v-card>
          <v-card-text> 
            
            <v-data-table
              :headers="headersMaterials"
              :items="order.materials"
              disable-pagination
              hide-default-footer
              class="elevation-0"
            >
              <template v-slot:item.id_material="{ item }">               
                {{ itemsMaterials.find(el => el.id == item.id_material).name}}
              </template>
              <template v-slot:item.price="{ item }">               
                € {{
                  itemsMaterials.find(el => el.id == item.id_material).cost * item.quantity                  
                }}
              </template>
              <template v-slot:item.actions="{ item, index }">
                <div v-if="item.conteggio_lavori=='0'"> 

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="$store.getters.rules.materials.edit" @click="editMaterial(item.id)" color="success" icon x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
                    </template>
                    <span>Modifica</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="$store.getters.rules.materials.edit" @click="deleteMaterial(item.id)" color="error" icon x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
                    </template>
                    <span>Cancella</span>
                  </v-tooltip>

                  <template v-if="isBlockHead(index)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <a :href="'/api/materiali/pdf-magazzino-operaio-block/' + item.id_block" target="_blank"><v-btn color="error" icon x-small fab v-bind="attrs" v-on="on">
                        <v-icon>mdi-file-pdf-outline</v-icon>
                        </v-btn></a>
                      </template>
                      <span>CONSEGNA OPERAIO-MAGAZZINO</span>
                    </v-tooltip> 

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <a :href="'/api/materiali/pdf-operaio-cliente-block/' + item.id_block" target="_blank"><v-btn color="error" icon x-small fab v-bind="attrs" v-on="on">
                        <v-icon>mdi-file-pdf-outline</v-icon>
                        </v-btn></a>
                      </template>
                      <span>CONSEGNA OPERAIO-CLIENTE</span>
                    </v-tooltip>  
                  </template>
                  
                  
                </div>
                <div v-else>
                  -
                </div>
              </template>
              <template v-slot:item.delivered="{ item }">
                <v-btn class="mr-3" :color="(item.delivered=='1') ? 'success' : 'error'" icon x-small fab><v-icon>{{ (item.delivered=='1') ? 'mdi-check' : 'mdi-close' }}</v-icon></v-btn>
              </template>
            </v-data-table>

            <v-simple-table v-if="$store.getters.rules.materials.edit">
              <tbody>
                <tr>
                  <td>
                    <v-btn @click="showManageMaterials" small icon color="success"><v-icon>mdi-plus</v-icon></v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

          </v-card-text>
        </v-card>

      </v-card-text>
    </v-card>

    <v-overlay v-model="overlayMaterials">
      <v-card light min-width="600">
        <v-card-title>{{(!currentMaterialId) ? 'Inserisci materiale' : 'Modifica materiale'}}</v-card-title>
        <v-card-text>
          <v-form ref="formMaterials">


              <template>
                  <v-menu
                      ref="calendarMaterial"
                      v-model="calendarMaterial"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                      <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="materialsForm.date"
                          label="Data:"
                          v-bind="attrs"
                          v-on="on"
                          :rules="rules.required && rules.date" 
                          required
                      ></v-text-field>
                      </template>
                      <v-date-picker
                      ref="pickerMaterial"
                      v-model="dateMaterialOriginalFormat"
                      min="2021-05-05"
                      @change="materialsFormCalendarSave"
                      locale="it"
                      :first-day-of-week="1"
                      ></v-date-picker>
                  </v-menu>
              </template>

            <v-select v-model="materialsForm.id_material" @change="updateCostAndPrice" :rules="rules.required" label="Materiale"  :items="itemsMaterials" item-text="name" item-value="id"></v-select>     
            <v-text-field type="number" v-model="materialsForm.quantity" @input="updateCostAndPrice" :rules="rules.required" label="Quantità"></v-text-field>
            <br />
            <v-card outlined>
              <v-card-text>
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td>
                        Prezzo per unità:
                      </td>
                      <td>
                        € {{ (materialCostPerUnit) ? materialCostPerUnit.toString() : '0' }}
                      </td>                    
                    </tr>
                    <tr>
                      <td>
                        Totale:
                      </td>
                      <td>
                        € {{ (materialsForm.price) ? materialsForm.price.toString() : '0' }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>

            <br />
            <v-switch v-model="materialsForm.delivered" color="success" label="Consegnato"></v-switch>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="cancelMaterialForm">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmMaterials">{{(!currentMaterialId) ? 'Inserisci' : 'Modifica'}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>    

    <v-overlay v-model="confirmDeleteMaterial">
      <v-card light min-width="600">
        <v-card-title>Elimina materiale</v-card-title>
        <v-card-text>
          Clicca "conferma" per eliminare
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="confirmDeleteMaterial = false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="deleteMaterialConfirmed">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <!-- New popup manage materials overlayManageMaterials -->
    <v-dialog
      v-model="overlayManageMaterials"
      scrollable
      max-width="800px"
    >
    <v-card light min-width="800">
        <v-card-title>Gestire materiali</v-card-title>
        <v-card-text>
          <v-form ref="manageMaterialsForm">

            <v-row>
              <v-col class="col-4">

                <template>
                    <v-menu
                      ref="calendarManageMaterial"
                      v-model="calendarManageMaterial"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="manageMaterials.date"
                          label="Data:"
                          v-bind="attrs"
                          v-on="on"
                          :rules="rules.required && rules.date" 
                          required
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="pickerManageMaterial"
                        v-model="dateManageMaterialOriginalFormat"
                        min="2021-05-05"
                        @change="manageMaterialsFormCalendarSave"
                        locale="it"
                        :first-day-of-week="1"
                        ></v-date-picker>
                    </v-menu>
                </template>

              </v-col>
              <v-col>
                <v-switch color="success" v-model="manageMaterials.delivered" :label="(manageMaterials.delivered) ? 'Consegnato' : 'Non consegnato'">
                </v-switch>              
              </v-col>
            </v-row>

            <v-simple-table>
              <thead class="h-0">
                <tr class="h-0">
                  <td class="col-6 h-0">Materiale</td>
                  <td>Quantità</td>
                  <td>P/unità</td>
                  <td>Totale</td>
                </tr>
              </thead>
              <tbody class="h-0">
                <tr class="h-0" v-for="(material, index) in manageMaterials.materialsList" v-bind:key="index">
                  <td class="h-0">
                    {{material.name}}
                  </td>
                  <td>
                    <v-text-field class="h-30" v-model="material.quantity" type="number"></v-text-field>
                  </td>
                  <td class="text-center">
                    {{material.price}}
                  </td>
                  <td class="text-center">
                    {{ material.price * material.quantity }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="overlayManageMaterials=false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveManageMaterials">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit notes claudio -->
    <v-dialog
      v-model="editNotesClaudio.showDialog"
      scrollable
      max-width="800px"
    >
    <v-card light min-width="800">
        <v-card-title>Note Claudio</v-card-title>
        <v-card-text>

          <v-textarea v-model="editNotesClaudio.notes">
          </v-textarea>
          
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="editNotesClaudio.showDialog=false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveNotesClaudio()">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Workers and vehicles -->
    <v-dialog
      v-model="overlayEditWorkers"
      scrollable
      max-width="800px"
    >
    <v-card light min-width="800">
        <v-card-title>Gestire operai ({{selectedWorkers.length}})</v-card-title>
        <v-card-text>
          <br />
          <h4>Operai: </h4>
          <br />
          <div v-if="selectedWorkers.length>0">
            <h3>{{ selectedWorkersString }}</h3>
          </div>
          <div v-if="selectedWorkers.length==0">
            Nessun operaio selezionato.
          </div>
          <br />
          <div v-if="$store.getters.rules.workersVehicles.edit">
            <v-divider></v-divider>
            <br />
            <v-form ref="formWorkers">

              <v-row>
                <v-col cols="6" v-for="(worker, index) in workers" v-bind:key="index" style="padding: 5px">
                  <v-checkbox  :value="worker.value" v-model="selectedWorkers" dense style="margin: 0px" :label="worker.text"></v-checkbox>
                </v-col>
              </v-row>            

            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="overlayEditWorkers=false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn small text @click="selectedWorkers=[]" v-if="$store.getters.rules.workersVehicles.edit"><v-icon color="red">mdi-close</v-icon> Cancella tutto</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmSelectedWorkers" v-if="$store.getters.rules.workersVehicles.edit">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="overlayEditVehicles"
      scrollable
      max-width="800px"
    >
    <v-card light min-width="800">
        <v-card-title>Gestire automezzi ({{selectedVehicles.length}})</v-card-title>
        <v-card-text>

          <br />
          <h4>Automezzi: </h4>
          <br />
          <div v-if="selectedVehicles.length>0">
            <h3>{{ selectedVehiclesString }}</h3>
          </div>
          <div v-if="selectedVehicles.length==0">
            Nessun automezzo selezionato.
          </div>
          <br />
          <div v-if="$store.getters.rules.workersVehicles.edit">
            <v-divider></v-divider>
            <br />

            <v-form ref="formVehicles">

              <v-row>
                <v-col cols="6" v-for="(vehicle, index) in vehicles" v-bind:key="index" style="padding: 5px">
                  <v-checkbox :value="vehicle.value" v-model="selectedVehicles" dense style="margin: 0px" :label="vehicle.text"></v-checkbox>
                </v-col>
              </v-row>  

            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="overlayEditVehicles=false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn small text @click="selectedVehicles=[]" v-if="$store.getters.rules.workersVehicles.edit"><v-icon color="red">mdi-close</v-icon> Cancella tutto</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmSelectedVehicles" v-if="$store.getters.rules.workersVehicles.edit">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="overlayWorkersVehicles"
      scrollable
      max-width="1200px">

      <v-card light min-width="1200">
        <v-card-title>Gestire operai e automezzi</v-card-title>
        <v-card-text>
        <worker-vehicle-single-work v-bind:workId="workersVehiclesId" v-bind:workDate="workersVehiclesDate"></worker-vehicle-single-work>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="overlayWorkersVehicles=false">Chiudi</v-btn>
        </v-card-actions>
      </v-card>   
         
    </v-dialog>

  </div>
</template>

<script>

import {workers, vehicles} from '../../global';
import WorkerVehicleSingleWork from '../../components/WorkerVehicleSingleWork.vue'
import ResourcesBDialog from '../../components/ResourcesBDialog'

export default {
  components: {
    WorkerVehicleSingleWork, ResourcesBDialog
  },
 
  data() {
    return {
      appointment: null,

      manageMaterials: {
        date: '',
        materialsList: [], // id, name, price, quantity
        delivered: true,
      },
      calendarManageMaterial: false,
      dateManageMaterialOriginalFormat: null,

      workers,
      vehicles,
      selectedWorkers: [],
      selectedVehicles: [],

      workersVehiclesId: false,
      workersVehiclesDate: false,
      overlayWorkersVehicles: false,

     rules: {
      required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
      integer: [ value => (! /^[0-9]+$/.test(value)) ? 'Inserire un numero intero valido.' : true ],
      hour: [ value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true ],
      date: [ value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true ],
      numberOrNull: [ value => (!value) ? 'Inserire un numero intero valido.' : true ],
     },

     overlayManageMaterials: false,

     modifyOrderOverlay: false,

      modifyForm: {
        agreed_price: null,      
        agreed_price_2: null,      
        payment_type: null,
        flag_renata: null,
        notes: null,
      },

      paymentTypeText: [
        'Contanti',
        'Assegno',
        'Bonifico',
      ],

      paymentType: [
        {text: 'Contanti', value:'0'},
        {text: 'Assegno', value:'1'},
        {text: 'Bonifico', value:'2'},
      ],

      /* Works */     
      calendarFormWorks: false,
      worksFormOriginalFormat: '',
      hourFormWorks: false,
      currentWorkId: false,
      deleteWorkId: false,
      overlayConfirmDeleteWork: false,

      calendarMaterial: false,
      dateMaterialOriginalFormat: null,

      worksForm: {
        date: null,
        hour: null,
        show_worksheet: true,        
        flag_renata: null,
        flag_in_schermata_lavorazioni: 0,
        // work_cost: null,
        type: null,
        id_pickupPlace: null,
        notes: null,
        police_permission: 0,
        notes_claudio: null,
      },

      overlayWorks: false,
      headersWorks: [
        {text:'Data', value:'date'},
        {text:'Ora', value:'hour'},
        // {text:'Costo', value:'work_cost'},
        {text:'Tipologia', value:'type'},
        {text:'Conteggio', value:'show_worksheet'},
        {text:'Renata', value:'flag_renata'},
        {text:'Note Claudio', value:'notes_claudio'},
        {text:'Azioni', value:'actions'},
        {text:'', value:'summary'},
      ],
        headersWorksSiteB: [
        {text:'Data', value:'date'},
        {text:'Ora', value:'hour'},
        // {text:'Costo', value:'work_cost'},
        {text:'Tipologia', value:'type'},
        {text:'Conteggio', value:'show_worksheet'},
        {text:'Azioni', value:'actions'},
        {text:'', value:'summary'},
      ],
      itemsWorkType: [],

      itemsWorksPlaces: [],

      // Materials
      headersMaterials: [
        {text:'Materiale', value:'id_material'},
        {text:'Data', value:'date'},
        {text:'Quantità', value:'quantity'},
        {text:'Prezzo', value:'price'},
        {text:'Consegnato', value:'delivered'},
        {text:'Azioni', value:'actions'},
      ],

      confirmDeleteMaterial: false,

      materialsForm: {
        data: null,
        id_material: null,
        quantity: null,
        price: null,
        delivered: null,
      },

      currentMaterialPopupButton: '-1',
      overlayMaterials: false,

      // Load materials from database
      itemsMaterials: [],

      currentMaterialId: false,
      materialCostPerUnit: false,

      // Order
      order: {},

      // Resources
      overlayResources: false,
      overlayResourcesB: false,
      resourcesNames: [
        'test 1',
        'test 2'
      ],
      resourcesLabels: [],
      resourcesValues: [],
      resourcesAvailable: [],
      resources_raw: [],

      overlayEditWorkers: false,
      overlayEditVehicles: false,
      on: false,
      attrs: false,

      editNotesClaudio: {
        showDialog: false,
        id: null,
        notes: '',
      },

      agents: [
        {
          text: 'Nessun agente',
          value: '0',   
        },
        {
          text: 'Patrizio Ponzo',
          value: '1'
        },
        {
          text: 'Claudio D’Auria',
          value: '2'
        },
        {
          text: 'Antonio Amodio',
          value: '3'
        },
      ],

      showEditAgentDialog: false,
      newAgent: 0,
    }
  },

  methods: {

    editAgent() {
      this.newAgent = this.appointment.id_agent;
      this.showEditAgentDialog = true;
    },

    saveNewAgent() {
      // Send data to API
      this.$emit('showProgress');
      this.$axios.post(this.$env.api+'Appointment/', {
        funct: 'editAgent',
        id: this.appointment.id,
        id_agent: this.newAgent
      }).then(({data}) => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.appointment.id_agent = this.newAgent;
          this.showEditAgentDialog = false;
          this.$emit('showSnack', 'Agente aggiornato', 'success');
        } else {
          this.$emit('showSnack', 'Errore di collegamento', 'error');
        }
      });
    },

    edtNotesClaudio(item) {
      this.editNotesClaudio.id = item.id;
      this.editNotesClaudio.notes = item.notes_claudio;
      this.editNotesClaudio.showDialog = true;
    },

    saveNotesClaudio() {
      this.$axios.post(this.$env.api+'Orders/', {
        funct: 'saveNotesClaudio',
        id: this.editNotesClaudio.id,
        notes: this.editNotesClaudio.notes
      }).then(({data}) => {
        if (data.ok) {
          this.editNotesClaudio.showDialog = false;
          this.getOrder();
        }
      });
    },

  /*
    showMaterialsPopupButtons: function(id) {      
      this.currentMaterialPopupButton = id;
    },

    hideMaterialsPopupButtons: function() {
      this.currentMaterialPopupButton = '-1';
    },
  */

    // Materials
    // Get materials info and price from database
    getMaterials() {
      this.$axios.post(this.$env.api+'Orders/', {funct: 'getMaterials'}).then(res => {
        // Convert from object to array
        this.itemsMaterials = res.data.values.materials;
      });      
    },

    editMaterial(id) {
      this.currentMaterialId = id;
      let currentMaterial = this.order.materials.find(el => el.id==id); 
      this.materialsForm = currentMaterial;
      this.overlayMaterials = true;
      this.updateCostAndPrice();
    },

    deleteMaterial(id) {
      this.currentMaterialId = id;
      this.confirmDeleteMaterial = true;
    },

    deleteMaterialConfirmed() {
      this.$api.orders.deleteMaterial(this.currentMaterialId).then(data => {
          this.$emit('hideProgress');

          if (!data.ok) {
              this.$emit('showSnack', data.values.msg, 'error');
              return;
          }

          // Ok
          this.$emit('showSnack', 'Materiale eliminato', 'success');
          this.confirmDeleteMaterial = false;
          this.getOrder();
      });
    },

    newMaterial() {
      this.currentMaterialId = false;
      this.materialsForm = {
        data: null,
        id_material: null,
        quantity: null,
        price: null,
        delivered: true,
      };    

      this.overlayMaterials = true;
      this.updateCostAndPrice();
    },

    showManageMaterials() {

      let materialsToExclude = [
        'Camion',
        'Avvitatore e Trapano',
        'Coperte',
        'Ferri',
      ];

      // Get materials list
      this.$api.orders.getMaterials().then(data => {
        if (data.ok) {

          let materials = [];

          data.values.materials.forEach(material => {

            if (materialsToExclude.findIndex(el => el==material.name) !== -1)
              return;

            // TODO: Add id_material, material info, id, name, price, etc
            let newMaterial = {
              id: material.id,
              name: material.name,
              quantity: 0,
              price: material.cost,
              stock: material.stock,
              total: 0,
              delivered: true,
            };
            materials.push(newMaterial);            
          });

          this.manageMaterials.materialsList = materials;
        } else {
          this.$emit('showSnack', 'Errore collegamento database', 'error');
        }
      });

      // Get materials for current order
      
      this.overlayManageMaterials = true;
    },

    cancelMaterialForm() {
      this.overlayMaterials = false;
      this.getOrder();
    },

    confirmMaterials() {
      if (!this.$refs.formMaterials.validate())
        return;
        
      this.$emit('showProgress');

      if (!this.currentMaterialId) {
        // New        
        this.$api.orders.newMaterial(this.order.id, this.materialsForm).then(data => {
            this.$emit('hideProgress');

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            // Ok
            this.getOrder();
            this.$emit('showSnack', 'Materiale inserito', 'success');
            this.overlayMaterials = false;
        });
      } else {
        // Edit
        this.$api.orders.editMaterial(this.currentMaterialId, this.materialsForm).then(data => {
            this.$emit('hideProgress');

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            // Ok
            this.getOrder();
            this.$emit('showSnack', 'Materiale modificato', 'success');
            this.overlayMaterials = false;
        });
      }      
    },

    materialsFormCalendarSave(date) {
      this.$refs.calendarMaterial.save(date);
    },

    manageMaterialsFormCalendarSave(date) {
      this.$refs.calendarManageMaterial.save(date);
    },
 
    confirmModifyOrder() {
      if (!this.$refs.modifyForm.validate())
        return;

      this.modifyForm = {
        agreed_price: this.order.agreed_price,
        agreed_price_2: this.order.agreed_price_2,
        payment_type: this.order.payment_type,
        flag_renata: (this.order.flag_renata) ? 1 : 0,
        notes: this.order.notes,
      };

      this.$emit('showProgress');
      this.$api.orders.modify(this.order.id, this.modifyForm).then(data => {
          this.$emit('hideProgress');
          this.modifyOrderOverlay = false;

          if (!data.ok) {
              this.$emit('showSnack', data.values.msg, 'error');
              return;
          }

          // Ok
          this.$emit('showSnack', 'Ordine modificato correttamente', 'success');
      });
    },

    getOrder() {
      this.$api.orders.getFromId(this.$route.params.id).then(data => {      
        //data.values.order.flag_renata = (data.values.order.flag_renata==1) ? true : false;

        this.order = data.values.order;

        // Materials boolean string to int
        this.order.materials.forEach((el, index) => {
          this.order.materials[index].delivered = (el.delivered=="1") ? 1 : 0;
        });

        // Works boolean string to int
        this.order.works.forEach((el, index) => {
          this.order.works[index].show_worksheet = (el.show_worksheet=="1") ? 1 : 0;
          this.order.works[index].flag_in_schermata_lavorazioni = (el.flag_in_schermata_lavorazioni=="1") ? 1 : 0;
        });

        this.itemsWorksPlaces = data.values.order.places;
        this.resourcesLabels = data.values.order.resources; // id, text

        // Get appointment
        this.$api.appointment.getFromId(this.order.id_appointment).then(app => {
          this.appointment = app.values.appointment;
        });
      });
    },

    // Works
    newWork() {
      this.currentWorkId=false;
      this.worksForm = {
        date: null,
        hour: null,
        show_worksheet: true,
        // work_cost: null,
        type: null,
        id_pickupPlace: null,
        notes: null,
        police_permission: 0,
        flag_in_schermata_lavorazioni: 0,
        notes_claudio: null,
      };
      this.overlayWorks=true;
    },
    
    processWorkForm() {
      if (!this.$refs.formWorks.validate())
        return;

      this.$emit('showProgress');

      if (!this.currentWorkId) {
        //Add new
        this.$api.orders.addWork(this.order.id, this.worksForm).then(data => {
          this.$emit('hideProgress');
          this.overlayWorks = false;
          if (data.ok) {
            this.$refs.formWorks.reset();
            this.getOrder();
            this.$emit('showSnack', 'Lavorazione inserita', 'success');
          } else {
            this.$emit('showSnack', data.values.msg, 'Errore');
          }
        }); 
      } else {
        // Edit
        this.$api.orders.editWork(this.currentWorkId, this.worksForm).then(data => {
          this.$emit('hideProgress');
          this.overlayWorks = false;
          if (data.ok) {
            this.$refs.formWorks.reset();
            this.getOrder();
            this.$emit('showSnack', 'Lavorazione modificata', 'success');
          } else {
            this.$emit('showSnack', data.values.msg, 'Errore');
          }
        }); 
      }
    },

    editWork(id) {
      // Get work info from order
      this.currentWorkId = id;

      let index = this.order.works.findIndex(el => el.id == id);
      this.worksForm = this.order.works[index];
      console.log(this.worksForm);
      /*
        date: null,
        hour: null,
        show_worksheet: null,
        type: null,
        id_pickupPlace: null,
        notes: null,
        police_permission: null,
      */
      this.overlayWorks = true;
    },

    debug(test) {
      alert(test);
    },

    showDeleteWorkDialog(id) {
      this.deleteWorkId = id;
      this.overlayConfirmDeleteWork = true;
    },

    confirmDeleteWork() {
      this.$emit('showProgress');

      this.$api.orders.deleteWork(this.deleteWorkId).then(data => {
        this.$emit('hideProgress');
        this.overlayConfirmDeleteWork = false;
        if (data.ok) {
          this.getOrder();
          this.$emit('showSnack', 'Lavorazione eliminata', 'success');
        } else {
          this.$emit('showSnack', data.values.msg, 'Errore');
        }
      }); 
    },

    // Resources
    showEditResources(id) {
      this.currentWorkId_resource = id;
      // Load resources values for current work
      this.$emit('showProgress');

      this.$api.orders.getWorkResources(id).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {

          // Set all values to 0
          // Set availability by default to total ammount
          this.resourcesValues = [];
          this.resourcesAvailable = [];
          this.resourcesLabels.forEach(el => {
            this.resourcesValues[el.id] = 0;
            this.resourcesAvailable[el.id] = el.ammount;
          });        

          data.values.resources.forEach(el => {
            this.resourcesValues[el.id_resource] = el.value;
          });
          
          // Get used resources and then do available - used
          if (data.values.availability) {
            data.values.availability.forEach(el => {
              // This will show me all used resources during the day of current order
              /*
                Example
                  id_resource	  used 	
                  4 			      2
                  5 			      2
              */
              let resourceTotal = this.resourcesAvailable[el.id_resource];             
              this.resourcesAvailable[el.id_resource] = parseInt(resourceTotal) - parseInt(el.used);
            });
          }

          // Show dialog
          this.overlayResources = true;
        } else {
          this.$emit('showSnack', data.values.msg, 'Errore');
        }
      }); 
    },

    showEditResourcesB(id) {
      this.currentWorkId_resource = id;
      // Load resources values for current work
      this.$emit('showProgress');

      this.$api.orders.getWorkResourcesB(id).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          // Set all values to 0
          // Set availability by default to total ammount
          this.resourcesValues = [];
          this.resourcesAvailable = [];
          this.resourcesLabels.forEach(el => {
            this.resourcesValues[el.id] = 0;
            this.resourcesAvailable[el.id] = el.ammount;
          });        

          data.values.resources.forEach(el => {
            this.resourcesValues[el.id_resource] = el.value;
          });
          
          // Get used resources and then do available - used
          if (data.values.availability) {
            data.values.availability.forEach(el => {
              // This will show me all used resources during the day of current order
              /*
                Example
                  id_resource	  used 	
                  4 			      2
                  5 			      2
              */
              let resourceTotal = this.resourcesAvailable[el.id_resource];             
              this.resourcesAvailable[el.id_resource] = parseInt(resourceTotal) - parseInt(el.used);
            });
          }

          // Show dialog
          this.overlayResourcesB = true;
        } else {
          this.$emit('showSnack', data.values.msg, 'Errore');
        }
      }); 
    },

  saveResources() {

    this.$emit('showProgress');

    // Create array of resource ID and new value
    let values = [];
    this.resourcesValues.forEach((el, index) => {
      // index => el
      values.push({id_work: this.currentWorkId_resource, id_resource: index, value: el});
    });



    this.$api.orders.saveWorkResources(this.currentWorkId_resource, values).then(data => {
      this.$emit('hideProgress');
      if (data.ok) {
        // Hide dialog
        this.overlayResources = false;
        this.$emit('showSnack', 'Risorse aggiornate', 'success');
      } else {
        this.$emit('showSnack', data.values.msg, 'error');
      }
    }); 

  },

  saveResourcesB() {
    this.$emit('showProgress');

    // Create array of resource ID and new value
    let values = [];
    this.resourcesValues.forEach((el, index) => {
      values.push({id_work: this.currentWorkId_resource, id_resource: index, value: el});
    });

    this.$api.orders.saveWorkResourcesB(this.currentWorkId_resource, values).then(data => {
      this.$emit('hideProgress');
      if (data.ok) {
        // Hide dialog
        this.overlayResourcesB = false;
        this.$emit('showSnack', 'Risorse aggiornate', 'success');
      } else {
        this.$emit('showSnack', data.values.msg, 'error');
      }
    }); 
  },

    updateCostAndPrice() {
      if (this.materialsForm.id_material && !isNaN(this.materialsForm.quantity)) {
        this.materialCostPerUnit = this.itemsMaterials.find(el => el.id == this.materialsForm.id_material).cost;
        this.materialsForm.price = this.materialsForm.quantity * this.materialCostPerUnit;
      } else {
        this.materialCostPerUnit = null;
        this.materialsForm.price = null;
      }
    },

    showEditWorkers(id) {
      this.currentWorkId = id; 
      
      // Load workers for current work
      this.$emit('showProgress');

      this.$api.works.getWorkers(this.currentWorkId).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.selectedWorkers = data.values.workers;
        } else {
          this.$emit('showSnack', 'Errore: lista operai non non trovata', 'error');
        }
      });

      this.overlayEditWorkers = true;
    },

    showEditWorkersVehicles(id, date) {
      this.workersVehiclesId = id;
      this.workersVehiclesDate = date;      
      this.overlayWorkersVehicles = true;
    },

    confirmSelectedWorkers() {
      this.$emit('showProgress');

      this.$api.works.setWorkers(this.currentWorkId, this.selectedWorkers).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.overlayEditWorkers = false;
          this.$emit('showSnack', 'Lista operai aggiornata', 'success');
        } else {
          this.$emit('showSnack', 'Errore: lista operai non aggiornata', 'error');
        }
      });
    },

    showEditVehicles(id) {
      this.currentWorkId = id; 
      
      // Load workers for current work
      this.$emit('showProgress');

      this.$api.works.getVehicles(this.currentWorkId).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.selectedVehicles = data.values.vehicles;
        } else {
          this.$emit('showSnack', 'Errore: lista automezzi non trovata', 'error');
        }
      });

      this.overlayEditVehicles = true;
    },

    confirmSelectedVehicles() {
      this.$emit('showProgress');

      this.$api.works.setVehicles(this.currentWorkId, this.selectedVehicles).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.overlayEditVehicles = false;
          this.$emit('showSnack', 'Lista automezzi aggiornata', 'success');
        } else {
          this.$emit('showSnack', 'Errore: lista automezzi non aggiornata', 'error');
        }
      });
    },

    saveNotesPatrizio() {
     this.$emit('showProgress');

      this.$api.orders.saveNotesPatrizio(this.order.id, this.order.notes_patrizio).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.$emit('showSnack', 'Note salvate', 'success');
        } else {
          this.$emit('showSnack', 'Errore: note non salvate', 'error');
        }
      });
    },

    saveManageMaterials() {
      if (!this.$refs.manageMaterialsForm.validate())
        return;

      let materials = this.manageMaterials.materialsList.filter(el => el.quantity!=0);
      this.$api.orders.saveMaterials(this.order.id, materials, this.manageMaterials.date, this.manageMaterials.delivered).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.$emit('showSnack', 'Materiali inseriti', 'success');
          this.overlayManageMaterials = false;
          this.getOrder();
        } else {
          this.$emit('showSnack', 'Errore database', 'error');
        }
      });
    },

    isBlockHead(index) {
      if (index==0)
        return true;

      return (this.order.materials[index].id_block != this.order.materials[index-1].id_block);
    },

    // Load work types from DB
    getWorkTypes() {
      this.$axios.post(this.$env.api+'Works/', {funct: 'getWorkTypes'}).then(({data}) => {
        if (data.ok) {
          this.itemsWorkType = data.values.workTypes.map(el => { return {value: el.value, text: el.text} });
        }                    
      });
    }

  },

  watch: {

    dateMaterialOriginalFormat(val) {
        this.materialsForm.date = val.split('-').reverse().join('-');
    },

    dateManageMaterialOriginalFormat(val) {
        this.manageMaterials.date = val.split('-').reverse().join('-');
    },

     worksFormOriginalFormat(val) {
        this.worksForm.date = val.split('-').reverse().join('-');
    },

  },

  computed: {

    canEditNotesClaudio: function () {
      return this.$store.getters.username == 'claudio';
    },

    selectedWorkersString: function () {
      let list = [];

      this.workers.forEach(el => {
        if (this.selectedWorkers.includes(el.value))
          list.push(el.text);
      });

      return list.join(', ');
    },

    selectedVehiclesString: function () {
      let list = [];

      this.vehicles.forEach(el => {
        if (this.selectedVehicles.includes(el.value))
          list.push(el.text);
      });

      return list.join(', ');
    },

    /*
      Costo totale (somma di prezzo pattuito + somma dei costi dei materiali + somma costi permessi)
    */
    totalOrderCost() {
      let total = 0;
      total += (this.order.agreed_price) ? parseInt(this.order.agreed_price) : 0;
      /*
      total += (this.order.totalCostWorks) ? parseInt(this.order.totalCostWorks) : 0;
      */
      total += (this.order.totalCostMaterials) ? parseInt(this.order.totalCostMaterials) : 0;
      total += (this.order.totalCostPermissions) ? parseInt(this.order.totalCostPermissions) : 0;
      return total;
    },

  },

  // Events
  mounted() {
    this.getWorkTypes();
    this.getMaterials();
    this.getOrder();
  },

}

</script>

<style>

  .h-0 {
    height: 40px !important;
  }

  .h-30 {
    height: 50px !important;
  }

</style>