let workers = [
    {value: '1', text: 'langella'},
    {value: '2', text: 'marco'},
    {value: '3', text: 'celeste'},
    {value: '4', text: 'fiorbello'},
    {value: '5', text: 'Pierro'},
    {value: '6', text: 'giannittone'},
    {value: '7', text: 'granata'},
    {value: '8', text: 'pino'},
    {value: '9', text: 'massimiliano'},
    {value: '10', text: 'Platone'},
    {value: '11', text: 'nello'},
    {value: '12', text: 'Nero'},
    {value: '13', text: 'pappagallo'},
    {value: '14', text: 'michele'},
    {value: '15', text: 'luigi P.'},
    {value: '16', text: 'Basso'},
    {value: '17', text: 'tiberio'},
    {value: '18' , text: 'Coo.Unica Arena Vincenzo'},
    {value: '19', text: 'Coo.Unica Francesco'},
    {value: '20', text: 'Coo.Unica Luca'},
    {value: '21', text: 'Coo.Unica Tortoriello'},
    {value: '22', text: 'Coo.Unica Nerone'},
    {value: '23', text: 'Coo.Unica De Falco'},
    {value: '24', text: 'Coo.Unica Cuccaro'},
    {value: '25', text: 'Coo.Unica Arena Paolo'},
    {value: '26', text: 'Coo.Unica Geremia'},
    {value: '27', text: 'Coo.Unica Pasquale'},
    {value: '28', text: 'Coo.Unica Lello'},
    {value: '29', text: 'Coo.Unica Francesco'},
    {value: '30', text: ' Coo.Unica'},
    {value: '31', text: 'Coo.Unica'},
    {value: '32', text: 'Nuov. Coo. Mosca'},
    {value: '33', text: 'Nuov. Coo Caputo'},
    {value: '34', text: 'Nuov. Coo Caputo domenico'},
    {value: '35', text: 'Nuov. Coo Grimaldi'},
    {value: '36', text: 'Nuov. Coo Badalan'},
    {value: '37', text: 'Nuov. Coo Colantuomo'},
    {value: '38', text: 'Nuov. Coo Pasquale'},
    {value: '39', text: 'Nuov. Coo D\'angelo'},
    {value: '40', text: 'Nuov. Coo Russo'},
    {value: '41', text: 'Nuov. Coo'},
    {value: '42', text: 'Nuov. Coo'},
    {value: '43', text: 'Cucciolo'},
    {value: '44', text: 'Gianni ponzo'},
    {value: '45', text: 'Op.Antica Traslochi'},
    {value: '46', text: 'Op.Antica Traslochi'},
    {value: '47', text: 'Fresella'},
    {value: '48', text: 'Pipolo'},
];

let vehicles = [
    {value: '1', text: 'AN 667 PR'},
    {value: '2', text: 'AN 668 PR'},
    {value: '3', text: 'AG 135 DX'},
    {value: '4', text: 'EJ631GE'},
    {value: '5', text: 'DF993EM'},
    {value: '6', text: 'CR 157 GE'},
    {value: '7', text: 'DY 685 EN'},
    {value: '8', text: 'FC 227 NL'},
    {value: '9', text: 'GC 799 SW ( 75/15)'},
    {value: '10', text: 'GF 294 EW'},
    {value: '11', text: 'FL 351 MY ( PORTER )'},
    {value: '12', text: 'DR 448 LS ( PORTER )'},
    {value: '13', text: 'DR 605 CF ( TOY.ELETTR)'},
    {value: '14', text: 'DS 156 DY ( TOY)'},
    {value: '15', text: 'GA 974 AB ( ISUZU)'},
    {value: '16', text: 'EZ 934 BB ( NISSAN )'},
    {value: '17', text: 'FJ 179 ZE ( PORTER )'},
    {value: '18', text: 'EW 706 GC'},
    {value: '19', text: 'DJ 996 YP ( 120)'},
];

export { workers, vehicles }