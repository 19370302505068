<template>
    <div>

        <br />

        <v-container>
            <v-row>
                <v-col>
                    <v-subheader><h1 class="accent--text">Elenco Log Operai</h1></v-subheader>


                    <div v-if="!isEmpty(logWorkers)">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <template>
                                        <v-data-table
                                            :headers="headers"
                                            :items="logWorkers"
                                            :items-per-page="10"
                                            class="elevation-5"
                                        >

                                            <template v-slot:[`item.id`]="{ item }">
                                                {{item.id}}
                                            </template>

                                            <template v-slot:[`item.name`]="{ item }">
                                                {{item.name}}
                                            </template>

                                            <template v-slot:[`item.surname`]="{ item }">
                                                {{item.surname}}
                                            </template>

                                            <template v-slot:[`item.description`]="{ item }">
                                                {{ truncateString(item.description, 100)}}
                                            </template>

                                            <template v-slot:[`item.amount`]="{ item }">
                                                {{ item.amount }} €
                                            </template>

                                            <template v-slot:[`item.date_created`]="{ item }">
                                                {{ item.date_created }}
                                            </template>

                                            <template v-slot:[`item.actions`]="{ item }">

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }"
                                                    v-if="$store.getters.rules.logWorkers">
                                                        <v-btn class="mr-3" color="info" @click="$router.push({path: '/logWorkers/show/'+item.id})" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-text-box-outline</v-icon></v-btn>
                                                    </template>
                                                    <span>Vedi log operaio</span>
                                                </v-tooltip>

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }"
                                                    v-if="$store.getters.rules.logWorkers">
                                                        <v-btn class="mr-3" color="success" @click="$router.push({path: '/logWorkers/modify/'+item.id})" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
                                                    </template>
                                                    <span>Modifica log veicolo</span>
                                                </v-tooltip>

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }"
                                                    v-if="$store.getters.rules.logWorkers">
                                                        <v-btn color="error" @click="deleteLogWorkers(item.id)" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
                                                    </template>
                                                    <span>Eliminare log operaio</span>
                                                </v-tooltip>

                                            </template>


                                        </v-data-table>
                                    </template>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                    <!-- fine logWorkers  -->
                    <div v-else>
                        <v-container class="box-no-vehicle">
                            <v-row>
                                <v-col>
                                    <div class="d-flex justify-center align-center flex-column">
                                        <div class="box-title">
                                            <p>Non ci sono ancora dati</p>
                                        </div>
                                        <div class="box-btn">
                                            <v-btn color="primary" @click="$router.push({path: '/logWorkers/insert'})" class="btn-no-memo"><v-icon>mdi-plus</v-icon>Aggiungi dati</v-btn>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <!-- Delete -->
        <v-overlay v-model="deleteLogWorkersOverlay">
            <v-card class="mx-auto" max-width="800" light>
                <v-card-title>
                    Elimina log operaio
                </v-card-title>
                <v-card-text>
                    Sei sicuro di voler eliminare questo log operaio?
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="deleteLogWorkersOverlay = false">No</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="confirmDeleteLogWorkers">Si</v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>

    </div>
</template>

<script>
export default {

    data() {
        return {

            deleteLogWorkersOverlay: false,
            deleteAppointmentId: false,

            headers: [
                {text: 'id', value: 'id'},
                {text: 'Cognome ', value: 'surname'},
                {text: 'Nome ', value: 'name'},
                //{text: 'Autore', value: 'user_id'},
                {text: 'Descrizione', value: 'description'},
                {text: 'Importo', value: 'amount'},
                //{text: 'Data', value: 'date'},
                {text: 'Data completa', value:'date_created'},
                {text: 'Azioni', value:'actions'},
            ],

            logWorkers: null,

        }
    },

    methods: {
        truncateString(str, num) {
            if (num > str.length) {
                return str;
            } else {
                str = str.substring(0, num);
                return str + "...";
            }

        },

        getAll() {
            this.$emit('showProgress');
            this.$api.logWorkers.getAll().then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.logWorkers = data.values.logWorkers;
            });
        },

        getLogWorkersFromUser() {
            this.$emit('showProgress');
            this.$api.logWorkers.getLogWorkersFromUser().then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                console.log(data.values.logWorkers);

                this.logWorkers = data.values.logWorkers;
            });
        },


        // Delete logWorkers
        deleteLogWorkers(id) {
            this.deleteLogWorkersId = id;
            this.deleteLogWorkersOverlay = true;
        },

        confirmDeleteLogWorkers() {
            this.$api.logWorkers.deleteLogWorkers(this.deleteLogWorkersId).then(data => {
                if (!data.ok) {
                    this.$emit('showSnack', 'Error connecting to database', 'error');
                    return;
                }

                // Update logWorkers list from id
                this.getLogWorkersFromUser();

                this.deleteLogWorkersOverlay = false;

                this.$emit('hideProgress');
                this.$emit('showSnack', 'Log eliminato', 'success');
            });
        },

        isEmpty(str) {
            return (!str || str.length === 0 );
        },
    },

    mounted() {
        this.getLogWorkersFromUser();
    },
}
</script>

<style scoped>
.box-no-vehicle {
    margin-top: 50px;
}

.box-no-vehicle p {
    font-size: 18px;
    margin: 0;
}

.box-no-vehicle .box-btn {
    margin-top: 20px;
}
</style>
