<template>
  <div class="login">

    <v-subheader><h2>Accesso</h2></v-subheader>
   
    <v-card class="mx-auto" max-width="800">
        <v-card-text>
            <v-form>
                <v-text-field label="Nome utente:" v-model="email" :append-icon="'mdi-person-outlined'"></v-text-field>
                <v-text-field label="Password:" v-model="password" :type="show_password ? 'text' : 'password'" :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show_password = !show_password"></v-text-field>
                Tutti i campi sono obbligatori
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" @click="login">Accedere</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
<!--
    <v-container>
      <v-row>
        <v-spacer></v-spacer>
        <v-col xs="12" md="6" color="secondary">
            <v-card color="secondary">
                <v-card-title>Accesso utenti</v-card-title>
                <v-card-text>
                    <v-row class="text-center">
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="12" md="8">
                            <v-form>
                                <v-text-field
                                    v-model="email"
                                    :append-icon="'mdi-person'"
                                    :type="'text'"
                                    name="username"
                                    label="E-mail"
                                    :rules="[rules.required, rules.email]"
                                ></v-text-field>

                                <v-text-field
                                    v-model="password"
                                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show_password ? 'text' : 'password'"
                                    name="password"
                                    label="Password"
                                    :rules="[rules.required]"
                                    @click:append="show_password = !show_password"
                                ></v-text-field>

                                <br />
                                
                                <v-btn color="accent" @click="login" large>
                                    Accedere
                                </v-btn>

                                <br />
                                
                            </v-form>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
-->      

<!--
    <v-card>
        <v-card-text>
            <v-form>
                <v-container fluid>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="12" lg="6">

                            <v-text-field
                                v-model="email"
                                :append-icon="'mdi-person'"
                                :type="'text'"
                                name="username"
                                label="E-mail"
                                :rules="[rules.required, rules.email]"
                            ></v-text-field>

                            <v-text-field
                                v-model="password"
                                :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show_password ? 'text' : 'password'"
                                name="password"
                                label="Password"
                                :rules="[rules.required]"
                                @click:append="show_password = !show_password"
                            ></v-text-field>

                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="12" lg="6">
                            <v-spacer></v-spacer>
                            <v-btn
                                color="secondary"
                                @click="login"
                            >
                                Accedere
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
    </v-card>
    -->

  </div>
</template>

<script>

//import config from '../config/config.js'

export default {
  name: 'Login',

  data() {
    return {
        email: '',
        password: '',
        show_password: false,
        rules: {
            required: value => !!value || 'Non può essere vuoto.',
            email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'E-mail non valida.'
            }
        },
      }
  },

  methods: {
      login() {

          // todo: adjust rules
          if (this.password !== '' && this.email !== '') {
            // Show progress
            this.$emit('showProgress');
            // Try to log in
            this.$api.authLogin(this.email, this.password)
                .then(response => {
                    if (response) {       
                        this.$store.dispatch('login', {username: response.email, user: response});
                        this.$emit('showSnack', 'Accesso eseguito', 'success');
                        this.$router.push({name: 'Home'});
                    } else {
                        // failed login
                        this.$emit('showSnack', 'Account non trovato o password errata', 'error');
                    }
                    // Hide progress
                    this.$emit('hideProgress');
                });
          }

      }
  },
}

</script>