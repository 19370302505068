<template>
    <div class="d-inline mr-0">
        <v-btn class="pt-8 pb-8 dayButton" small @click="setWeekDay()" :color="color" :outlined="date != currentDate" style="font-size: 100%">
            {{day}}
            <br />
            {{dateNumber}}
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: 'WeekDayBtn',
        props: {
            day: null,
            date: null,
            currentDate: null,
        },

        methods: {
            setWeekDay() {
                this.$emit('change', this.date);
            }
        },

        computed: {
            dateNumber() {
                if (this.date == null)
                    return '-';

                let dateOnly = this.date.split(' ')[0];
                let number = dateOnly.split('-')[2];
                return number; 
            },
            color() {
                if (this.day == 'S' || this.day=='D')
                    return 'blue-grey lighten-1';
                else
                    return 'accent';
            },
        }
    }
</script>

<style>

    .dayButton {
        min-width: 0px !important;
        padding-left: 9px !important;
        padding-right: 9px !important;
    }

    .legend-label {
        font-size: 90%;
    }


    @media (max-width:768px) {
        .legend-label {
            font-size: 90%;
        }
    }

</style>
