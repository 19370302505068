<template>
    <div>
        <template>
            <v-card
                :loading="loading"
                class="mx-auto my-12"
                max-width="374"
            >
                <template slot="progress">
                    <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                    ></v-progress-linear>
                </template>


                <v-card-title><h3>{{ title }}</h3></v-card-title>

                <div class="grey--text ms-4">
                    Data associata: {{ date }}
                </div>

                <v-card-text>
                    <v-row
                        align="center"
                        class="mx-0"
                    >

                        <div class="my-4 text-subtitle-1">
                            Autore: {{ user_id }}
                        </div>

                    </v-row>

                    <div>
                        {{ description }}
                    </div>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>

                <v-card-actions>

                    <v-btn
                        color="deep-purple lighten-2"
                        text
                        @click="$router.push({path: '/memo/modify/'+ idLink})"
                    >
                        Modifica
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </div>
</template>

<script>
export default {
    name: "MemoCard",

    data: () => ({
        loading: false,
        selection: 1,
    }),

    props: {
        id: {
            type: String,
            required: false
        },
        user_id: {
            type: String,
            required: false
        },

        title: {
            type: String,
            required: false
        },

        description: {
            type: String,
            required: false
        },

        date_created: {
            type: String,
            required: false
        },

        idLink: {
            type: String,
            required: false
        },

    },
}
</script>

<style scoped>

</style>
