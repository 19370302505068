<template>
  <div>  

      <br />

        <v-card class="mx-auto" max-width="1200">
            <v-card-title>
                Inserisci appuntamento
            </v-card-title>
            <v-card-text>

            <template>
                <v-tabs color="accent" v-model="currentTab">
                  <v-tab>Cliente registrato</v-tab>
                  <v-tab>Nuovo cliente</v-tab>
                </v-tabs>
              </template>

                <v-tabs-items v-model="currentTab">
                    <v-tab-item>

                    <v-form ref="newAppointment" v-model="form" lazy-validation>
                        <v-autocomplete
                            class="mt-5"
                            :rules="rules.required"
                            v-model="values.client"
                            :items="itemsClients"
                            label="Cliente collegato*:"
                            @change="getClientAddress"
                        required ></v-autocomplete>

                        <v-select :items="clientAddresses"
                            :rules="rules.required"
                            v-if="values.client"
                            v-model="values.client_address"
                            label="Indirizzo*:"
                            >
                        </v-select>
                    </v-form>
                    
                    </v-tab-item>

                    <v-tab-item>
                    
                    <v-form ref="newClient" v-model="form" lazy-validation>                          

                        <v-container>
                        <v-row>
                            <v-col cols="6">
                            <v-text-field v-model="newClient.name" label="Nome:" required></v-text-field>   
                            </v-col>
                            <v-col>
                            <v-text-field v-model="newClient.surname" :rules="rules.required" label="Cognome*:" required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="newClient.email" :rules="rules.email" label="E-mail:"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="newClient.phone" :rules="rules.phone" label="Telefono:"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="newClient.cellphone" :rules="rules.phone" label="Cellulare:"></v-text-field>
                            </v-col>
                        </v-row>

                        <br /><br />

                        <v-row>
                            <v-col cols="12">
                                <h2>Tipologia cliente</h2>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group v-model="newClient.privateClient">
                                    <v-radio
                                        label="Privato"
                                        :value="1"
                                        color="green"
                                    ></v-radio>
                                    <v-radio
                                        label="Azienda"
                                        :value="0"
                                        color="green"
                                    ></v-radio>
                                    <v-radio
                                        label="Ente"
                                        :value="2"
                                        color="green"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <!-- Values private -->
                        <template v-if="newClient.privateClient == 1">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field v-model="newClient.fiscalCode" :rules="rules.required" label="CF (Codice fiscale)*:"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="newClient.privateAddress" :rules="rules.required" label="Indirizzo residenza per fattura*:"></v-text-field>
                                </v-col>
                            </v-row>
                        </template>

                        <!-- Values business -->
                        <template v-if="!newClient.privateClient != 1">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field v-model="newClient.businessName" :rules="rules.required" label="Ragione Sociale*:"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="newClient.businessAddress" :rules="rules.required" label="Sede Legale*:"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field v-model="newClient.uniqueCode" label="Codice Univoco:"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="newClient.pec" label="PEC:"></v-text-field>
                                </v-col>
                            </v-row>
                        </template>

                        <br /><br /><br />

                        <v-row>
                            <v-col>
                            <h2>Luogo di carico</h2>
                            </v-col>
                        </v-row>

    
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="newClient.address" :rules="[$rules.required]" label="Indirizzo*:"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="7">
                                <v-autocomplete v-model="newClient.cityId" :rules="[$rules.required]" cache-items :search-input.sync="search" label="Comune*:" :items="items" @change="setLocationInfo()"></v-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field v-model="newClient.zip" :rules="[$rules.required]" label="CAP*:"></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="newClient.province" label="Provincia:"></v-text-field>
                            </v-col>
                        </v-row>

                        </v-container>

                        <br /><br />
                        
                        <h2>Appuntamento</h2>
                        <br />

                    </v-form>

                    </v-tab-item>
                </v-tabs-items>

                <v-form ref="newAppointment" v-model="form" lazy-validation v-if="currentTab==1 || (values.client!=null && values.client_address != null)">
                    <template>
                        <v-row>
                            <v-col cols="6">
                                <v-menu
                                    ref="calendarMenu"
                                    v-model="calendarMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="values.date"
                                        label="Data*:"
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="rules.required && rules.date" 
                                        required
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    ref="picker"
                                    v-model="dateOriginalFormat"
                                    min="1950-01-01"
                                    @change="calendarSave"
                                    locale="it"
                                    :first-day-of-week="1"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="values.time"
                                    label="Ora inizio*:"
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="rules.required && rules.hour"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field
                                    v-model="values.time_end"
                                    label="Ora fine*:"
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="rules.required && rules.hour"
                                    required
                                ></v-text-field>
                            </v-col>                            
                        </v-row>
                        
                    </template>
             
                    <v-row>
                        <v-col cols="6">
                            <v-select :items="itemsAgents" v-model="values.agent" label="Agente:"></v-select>
                        </v-col>
                        <v-col cols="6">
                            <v-select :items="itemsHowKnew" v-model="values.howKnew" label="Come ci hai conosciuto:"></v-select>
                        </v-col>
                    </v-row>                    
                    
                    <v-textarea v-model="values.description" label="Descrizione Appuntamento Google Calendar"></v-textarea>

                    <v-row>
                        <v-col cols="8">
                            <v-select :items="itemsState" v-model="values.state" label="Stato Appuntamento:"></v-select>
                        </v-col>                        
                        <v-col>
                            <v-switch
                                class="ml-5"
                                label="Fuori dalla campania"
                                color="success"
                                :false-value="0"
                                :true-value="1"
                                v-model="values.is_out_campania"
                            ></v-switch>
                        </v-col>
                    </v-row>                    
                    <!-- <v-file-input label="PDF Allegato"></v-file-input> -->
                </v-form>

                <h4>I campi contrassegnati * sono obbligatori.</h4>
            </v-card-text>
            <v-card-actions>
                <v-btn color="accent" @click="insertAppointment">Inserire appuntamento</v-btn>
            </v-card-actions>
        </v-card>

  </div>
</template>

<script>

export default {
  
  data() {
    return {

        on: false,
        attrs: false,

        currentTab: 0,

        // Date select
        date: null,
        calendarMenu: false,
        dateOriginalFormat: '',
        hourMenu: false,

        itemsAgents: [
            {text: 'Nessun Agente', value: 0},
            {text: 'Patrizio Ponzo', value: 1},
            {text: 'Claudio D’Auria', value: 2},
            {text: 'Antonio Amodio', value: 3},
        ],

        itemsHowKnew: [
            {text: 'Nessun valore', value: 0},
            {text: 'Passaparola', value: 1},
            {text: 'Sito Web', value: 2},
            {text: 'E-Mail', value: 3},
            {text: 'Vecchio cliente', value: 4},
        ],

        itemsState: [
            {text: 'Confermato', value: 1},
            {text: 'Fallito', value: 2},
            {text: 'In trattativa', value: 0},
        ],

        itemsClients: [],

        rules: {
          required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
          hour: [ value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true ],
          date: [ value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true ],
          email: [ value => (value && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) ? 'Formato non valido' : true],
          phone: [ value => (value && !/^\d+$/.test(value)) ? 'Inserire i numeri senza spazi né caratteri speciali' : true],
        },

        form: false,
        showForm: true,
        values: {
            date: null,           
            time: null,
            time_end: null,
            client: null,
            client_address: null,
            agent: null,
            howKnew: null,
            description: null,
            state: 0,
            is_out_campania: 0,
        },
        clientAddresses: [],

        newClient: {
          name: null,
          surname: null,

          address: null,
          zip: null,
          cityId: null,
          city: null,
          province: null,
          notes: null,

          email: null,
          phone: null,
          cellphone: null,
          privateClient: 1,

          fiscalCode: null,
          privateAddress: null,
          businessName: null,
          businessAddress: null,
          uniqueCode: null,
          pec: null,
        },

        // Comune items
        items: [],
        isLoading: false,

        // Value to be watched by autocomplete function
        search: null,
      
      }
  },

  methods: {

    // Comune
    /**
     * Triggered after selecting in autoselect in order to get (if possible) info about cap, province, etc.
     */
    setLocationInfo() {        
        if (!this.newClient.cityId)
            return;

        let locationInfo = this.items.find(el => el.value==this.newClient.cityId);

        if (!locationInfo)
            return;

        this.newClient.zip = locationInfo.cap;
        this.newClient.province = locationInfo.province;
        this.newClient.city = locationInfo.comune;
    },
      
    calendarSave (date) {
        this.$refs.calendarMenu.save(date)
    },

    getClientAddress() {
        this.$api.appointment.getClientAddress(this.values.client).then(data => {
            if (!data.ok) {
                this.$emit('showSnack', 'Il cliente non ha nessun indirizzo associato.', 'error');
            }

            this.clientAddresses = data.values.addresses;
            this.values.client_address = this.clientAddresses[0].value;
        });
    },

    getAllClients() {
        this.$emit('showProgress');
        this.$api.client.getAll().then(data => {
            this.$emit('hideProgress');

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            this.itemsClients = data.values.clients.map( el => { return {text: el.name, value: el.id} });

        });
    },

    insertAppointment() {
        if (this.currentTab==1) { // New client
            if (!this.$refs.newAppointment.validate() || !this.$refs.newClient.validate())
                return;

            // Send new appointment to database with new client info
            this.$emit('showProgress');
            this.$api.appointment.createNewWithNewClient(this.values, this.newClient).then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                // Ok
                this.$refs.newAppointment.reset();
                this.$refs.newClient.reset();
                this.values.client = null;
                this.values.client_address = null;
                
                this.$emit('showSnack', 'Appuntamento inserito', 'success');
            });
        } else {
            if (!this.$refs.newAppointment.validate())
                return;

            // Send new appointment to database
            this.$emit('showProgress');
            this.$api.appointment.createNew(this.values).then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                // Ok
                this.values.client = null;
                this.values.client_address = null;
                this.$refs.newAppointment.reset();

                this.$emit('showSnack', 'Appuntamento inserito', 'success');
            });
        }        
        
    },

  },

  watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },

      dateOriginalFormat(val) {
          this.values.date = val.split('-').reverse().join('-');
      },

      'values.time'(val) {
        // Validate hour
        if (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(val) || this.values.time_end)
            return false;

        let time = val.split(':');
        let hour = new Date();
        hour.setHours(time[0], time[1], 0);
        hour.setHours(hour.getHours()+1);
        this.values.time_end = hour.getHours() + ':' + hour.getMinutes();
      },

        // Dynamically get list of comune from DB
        search (query) {
            // Items have already been requested
            if (this.isLoading)
                return

            this.isLoading = true

            // Lazily load input items
            this.$axios.post(this.$env.api+'Comune/', {funct: 'search', query}).then(({data}) => {
                this.items = data.values.items;
            })
            .finally(() => {
                this.isLoading = false;
            });
        },
  },

  mounted() {
    this.getAllClients();    
  },

}

</script>