<template>
<div>

    <br />

    <v-container>
        <v-row>
            <v-col>
                <v-subheader><h1 class="accent--text">Elenco memo</h1></v-subheader>


                <div v-if="memo">
                    <v-container>
                        <v-row>
                            <v-col>
                                <template>
                                    <v-data-table
                                        :headers="headers"
                                        :items="memo"
                                        :items-per-page="10"
                                        class="elevation-5"
                                    >

                                        <template v-slot:[`item.id`]="{ item }">
                                            {{item.id}}
                                        </template>

                                        <template v-slot:[`item.title`]="{ item }">
                                            {{ truncateString(item.title, 25) }}
                                        </template>

                                        <template v-slot:[`item.description`]="{ item }">
                                            {{ (item.description) ? truncateString(item.description, 50) : ''}}
                                        </template>

                                        <template v-slot:[`item.date`]="{ item }">
                                            {{ item.date }} {{ item.time }}
                                        </template>

                                            <template v-slot:[`item.actions`]="{ item }">

                                              <v-tooltip top>
                                                  <template v-slot:activator="{ on, attrs }">
                                                      <v-btn class="mr-3 white--text" color="purple" @click="showShareMemoDialog(item.id)" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-share</v-icon></v-btn>
                                                  </template>
                                                  <span>Condividi con altri utenti</span>
                                              </v-tooltip>

                                              <v-tooltip top>
                                                  <template v-slot:activator="{ on, attrs }">
                                                      <v-btn class="mr-3" color="info" @click="showMemo(item.id)" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-text-box-outline</v-icon></v-btn>
                                                  </template>
                                                  <span>Vedi memo</span>
                                              </v-tooltip>

                                              <v-tooltip top>
                                                  <template v-slot:activator="{ on, attrs }">
                                                      <v-btn class="mr-3" color="success" @click="modifyMemo(item.id)" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
                                                  </template>
                                                  <span>Modifica memo</span>
                                              </v-tooltip>

                                              <v-tooltip top>
                                                  <template v-slot:activator="{ on, attrs }">
                                                      <v-btn color="error" @click="deleteMemo(item.id)" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
                                                  </template>
                                                  <span>Eliminare memo</span>
                                              </v-tooltip>

                                      </template>


                                    </v-data-table>
                                </template>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-col>
        </v-row>
    </v-container>

    <!-- Delete -->
    <v-overlay v-model="deleteMemoOverlay">
        <v-card class="mx-auto" max-width="800" light>
            <v-card-title>
                Elimina memo
            </v-card-title>
            <v-card-text>
                Sei sicuro di voler eliminare questa memo?
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="deleteMemoOverlay = false">No</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="confirmDeleteMemo">Si</v-btn>
            </v-card-actions>
        </v-card>
    </v-overlay>

    <v-overlay v-model="showMemoOverlay">
        <v-card class="mx-auto" min-width="800" max-width="800" light>
            <v-card-title>
                {{selectedMemo.title}}
            </v-card-title>
            <v-card-text>
                <b>Data e ora:</b> {{selectedMemo.date}} {{selectedMemo.time}}
                <br /><br />
                {{selectedMemo.description}}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="modifyMemo(selectedMemo.id)">Modifica</v-btn>
                <v-btn color="info" @click="showMemoOverlay=false">Chiudi</v-btn>
            </v-card-actions>
        </v-card>
    </v-overlay>

    <v-overlay v-model="shareMemo.show">
        <ShareMemo :id="parseInt(shareMemo.id)" @cancel="cancelShareMemo()" @confirm="confirmShareMemo()" />
    </v-overlay>

    <v-overlay v-model="editMemoOverlay">
        <v-card class="mx-auto" min-width="800" max-width="800" light>
            <v-card-title>
                Modifica memo
            </v-card-title>
            <v-card-text>
                <v-form ref="formMemo">
                    <v-text-field
                        v-model="selectedMemo.title"
                        :rules="rules.required"
                        label="Titolo*"
                        required
                    ></v-text-field>

                    <template>
                        <v-menu
                            ref="calendarMenu"
                            v-model="calendarMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="selectedMemo.date"
                                    label="Data*:"
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="rules.required && rules.date" 
                                    required
                                ></v-text-field>
                            </template>
                            <v-date-picker
                            ref="picker"
                            v-model="dateOriginalFormat"
                            min="1950-01-01"
                            @change="calendarSave"
                            locale="it"
                            :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </template>

                    <v-text-field
                        v-model="selectedMemo.time"
                        label="Ora*:"
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules.required && rules.hour"
                        required
                    ></v-text-field>

                    <v-textarea
                        v-model="selectedMemo.description"
                        label="Contenuto">
                    </v-textarea>
                    <h4>I campi contrassegnati * sono obbligatori.</h4>  
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="editMemoOverlay=false">Annulla</v-btn>
                <v-btn color="success" @click="confirmModify(selectedMemo.id)">Salva</v-btn>
            </v-card-actions>
        </v-card>
    </v-overlay>

</div>
</template>

<script>

import ShareMemo from '../../components/memo/Share'

export default {

    components: {ShareMemo},

    data() {
        return {

            date: null,
            calendarMenu: false,
            dateOriginalFormat: '',
            hourMenu: false,

            on: null,
            attrs: null,

            deleteMemoOverlay: false,
            deleteAppointmentId: false,

            headers: [
                {text: 'Id', value: 'id'},
                {text: 'Titolo', value: 'title'},
                {text: 'Contenuto', value: 'description'},
                {text: 'Data associata', value:'date'},
                {text: 'Azioni', value:'actions'},
            ],

            memo: false,
            
            selectedMemo: {
                title: null,
                description: null,
                date: null,
                time: null
            },
            showMemoOverlay: false,
            editMemoOverlay: false,

            // Info for memo to share
            shareMemo: {
                id: null,
                show: false,
            },

            rules: {
                required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
                hour: [ value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true ],
                date: [ value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true ],
            },
        }
    },

    methods: {
        calendarSave (date) {
            this.$refs.calendarMenu.save(date)
        },

        truncateString(str, num) {
            if (num > str.length) {
                return str;
            } else {
                str = str.substring(0, num);
                return str + "...";
            }
        },

        getAll() {
            this.$emit('showProgress');
            this.$api.memo.getAll().then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.memo = data.values.memo;
            });
        },

        getMemoFromUser() {
            this.$emit('showProgress');
            this.$api.memo.getMemoFromUser().then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.memo = data.values.memo;
            });
        },

        // Delete memo
        deleteMemo(id) {
            this.deleteMemoId = id;
            this.deleteMemoOverlay = true;
        },

        confirmDeleteMemo() {
            this.$api.memo.deleteMemo(this.deleteMemoId).then(data => {
                if (!data.ok) {
                    this.$emit('showSnack', 'Error connecting to database', 'error');
                    return;
                }

                // Update memo list from id
                this.getMemoFromUser();

                this.deleteMemoOverlay = false;

                this.$emit('hideProgress');
                this.$emit('showSnack', 'Memo eliminata', 'success');
            });
        },

        showMemo(id) {
            this.$emit('showProgress');
            this.$api.memo.getFromId(id).then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.selectedMemo = data.values.memo;
                this.showMemoOverlay = true;
            });
        },

        modifyMemo(id) {
            this.$emit('showProgress');
            this.showMemoOverlay = false;
            this.$api.memo.getFromId(id).then(data => {
                this.$emit('hideProgress');
                this.selectedMemo = data.values.memo;

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.editMemoOverlay = true;
            });
        },

        confirmModify() {
            if (!this.$refs.formMemo.validate())
                return;
                
            this.$emit('showProgress');
            this.$api.memo.updateMemo(this.selectedMemo).then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.$emit('showSnack', 'Memo aggiornato', 'success');
                this.editMemoOverlay = false;
                this.getMemoFromUser();
            });
        },

        /**
         * Open share memo dialog
         */
        showShareMemoDialog(id) {
            this.shareMemo.id = id;
            this.shareMemo.show = true;
        },

        confirmShareMemo() {
            this.$emit('showSnack', 'Memo condiviso', 'success');
            this.shareMemo.show = false;
        },

        cancelShareMemo() {
            this.shareMemo.show = false;
        },
    },

    watch: {
        dateOriginalFormat(val) {
          this.selectedMemo.date = val.split('-').reverse().join('-');
        },
    },

    mounted() {
        this.getMemoFromUser();
    },

}
</script>

<style scoped>

</style>
