let predefined = {
    
    clientTypes: [
        'Azienda',
        'Privato',    
        'Ente',
    ],

}

export default predefined