<template>
  <div>  

      <br />

        <v-card class="mx-auto" max-width="1000">
            <v-card-title>
                Inserisci ordine
            </v-card-title>
            <v-card-text>

              <template>
                <v-tabs color="accent" v-model="currentTab">
                  <v-tab>Cliente registrato</v-tab>
                  <v-tab>Nuovo cliente</v-tab>
                </v-tabs>
              </template>

              <v-tabs-items v-model="currentTab">
                <v-tab-item>
                  <v-form ref="newOrder" v-model="form" lazy-validation>
                      <v-autocomplete
                          class="mt-5"
                          :rules="rules.required"
                          v-model="values.client"
                          :items="itemsClients"
                          label="Cliente collegato*:"
                          @change="getClientAddress"
                      required ></v-autocomplete>

                      <v-select :items="clientAddresses"
                          :rules="rules.required"
                          v-if="values.client"
                          v-model="values.client_address"
                          label="Indirizzo*:"
                          >
                      </v-select>

                      <v-select :items="itemsAgentsForm"
                        v-if="values.client"
                        v-model="values.id_agent"
                        label="Agente:"
                        >
                      </v-select>
                  </v-form>
                </v-tab-item>
                <v-tab-item>
                  
                  <v-form ref="newOrderNewClient" v-model="form" lazy-validation>                          

                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field v-model="valuesNewClient.name" label="Nome:" required></v-text-field>   
                        </v-col>
                        <v-col>
                          <v-text-field v-model="valuesNewClient.surname" :rules="rules.required" label="Cognome*:" required></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                          <v-col>
                              <v-text-field v-model="valuesNewClient.email" :rules="rules.email" label="E-mail:"></v-text-field>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col cols="6">
                              <v-text-field v-model="valuesNewClient.phone" :rules="rules.phone" label="Telefono:"></v-text-field>
                          </v-col>
                          <v-col cols="6">
                              <v-text-field v-model="valuesNewClient.cellphone" :rules="rules.phone" label="Cellulare:"></v-text-field>
                          </v-col>
                      </v-row>

                      <v-row>
                          <v-col cols="12">
                              <h3>Tipologia cliente</h3>
                          </v-col>
                      </v-row>
                      <v-row>
                          <v-col cols="12">
                              <v-radio-group v-model="valuesNewClient.privateClient">
                              <v-radio
                                  label="Privato"
                                  :value="true"
                                  color="green"
                              ></v-radio>
                              <v-radio
                                  label="Azienda"
                                  :value="false"
                                  color="green"
                              ></v-radio>
                              </v-radio-group>
                          </v-col>
                      </v-row>
                      <!-- Values private -->
                      <template v-if="valuesNewClient.privateClient">
                          <v-row>
                              <v-col cols="6">
                                  <v-text-field v-model="valuesNewClient.fiscalCode" :rules="rules.required" label="CF (Codice fiscale)*:"></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                  <v-text-field v-model="valuesNewClient.privateAddress" :rules="rules.required" label="Indirizzo residenza per fattura*:"></v-text-field>
                              </v-col>
                          </v-row>
                      </template>

                      <!-- Values business -->
                      <template v-if="!valuesNewClient.privateClient">
                          <v-row>
                              <v-col cols="6">
                                  <v-text-field v-model="valuesNewClient.businessName" :rules="rules.required" label="Ragione Sociale*:"></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                  <v-text-field v-model="valuesNewClient.businessAddress" :rules="rules.required" label="Sede Legale*:"></v-text-field>
                              </v-col>
                          </v-row>
                          <v-row>
                              <v-col cols="6">
                                  <v-text-field v-model="valuesNewClient.uniqueCode" label="Codice Univoco:"></v-text-field>
                              </v-col>
                              <v-col cols="6">
                                  <v-text-field v-model="valuesNewClient.pec" label="PEC:"></v-text-field>
                              </v-col>
                          </v-row>
                      </template>

                      <v-row>
                        <v-col>
                          <h3>Luogo di carico</h3>
                        </v-col>
                      </v-row>

                      <v-row>
                          <v-col cols="12">
                              <v-text-field v-model="valuesNewClient.address" :rules="rules.required" label="Indirizzo*:" required></v-text-field>
                          </v-col>
                      </v-row>

                      <v-row>
                          <v-col cols="7">
                              <v-autocomplete v-model="valuesNewClient.cityId" :rules="[$rules.required]" cache-items :search-input.sync="search" label="Comune*:" :items="items" @change="setLocationInfo()"></v-autocomplete>								
                          </v-col>
                          <v-col cols="2">
                              <v-text-field v-model="valuesNewClient.zip" :rules="[$rules.required]" label="CAP*:"></v-text-field>
                          </v-col>
                          <v-col cols="3">
                              <v-text-field v-model="valuesNewClient.province" label="Provincia:"></v-text-field>
                          </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <h3>Appuntamento</h3>
                        </v-col>
                      </v-row>
                      <v-row>
                          <v-col cols="6">
                            <v-select :items="itemsAgentsForm"
                              v-model="valuesNewClient.id_agent"
                              label="Agente:"
                              >
                            </v-select>
                          </v-col>
                      </v-row>
                    </v-container>

                    <h4 class="mt-2">I campi contrassegnati * sono obbligatori.</h4>

                  </v-form>

                </v-tab-item>
              </v-tabs-items>

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="insertOrder">Creare ordine</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

  </div>
</template>

<script>

export default {
  
  data() {
    return {

        currentTab: 0,

        itemsClients: [],

        rules: {
          required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
          hour: [ value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true ],
          date: [ value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true ],
          email: [ value => (value && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) ? 'Formato non valido' : true],
          phone: [ value => (value && !/^\d+$/.test(value)) ? 'Inserire i numeri senza spazi né caratteri speciali' : true],
        },

        form: false,
        showForm: true,
        
        values: {
            client: null,
            client_address: null,
            id_agent: 0,
        },

        valuesNewClient: {
          name: null,
          surname: null,
          address: null,
          zip: null,
          city: null,
          cityId: null,
          province: null,

          email: null,
          phone: null,
          cellphone: null,
          privateClient: true,

          fiscalCode: null,
          privateAddress: null,
          businessName: null,
          businessAddress: null,
          uniqueCode: null,
          pec: null,

          id_agent: 0,
        },

        itemsAgentsForm: [
          {text: 'Nessun Agente', value: '0'},
          {text: 'Patrizio Ponzo', value: '1'},
          {text: 'Claudio D’Auria', value: '2'},
          {text: 'Antonio Amodio', value: '3'},
        ],

        clientAddresses: [],

        // Comune items
        items: [],
        isLoading: false,

        // Value to be watched by autocomplete function
        search: null,
      
      }
  },

  methods: {

      // Comune
    /**
     * Triggered after selecting in autoselect in order to get (if possible) info about cap, province, etc.
     */
    setLocationInfo() {        
        if (!this.valuesNewClient.cityId)
            return;

        let locationInfo = this.items.find(el => el.value==this.valuesNewClient.cityId);

        if (!locationInfo)
            return;

        this.valuesNewClient.zip = locationInfo.cap;
        this.valuesNewClient.province = locationInfo.province;
        this.valuesNewClient.city = locationInfo.comune;
    },
   
    getClientAddress() {
        this.$api.appointment.getClientAddress(this.values.client).then(data => {
            if (!data.ok) {
                this.$emit('showSnack', 'Il cliente non ha nessun indirizzo associato.', 'error');
            }

            this.clientAddresses = data.values.addresses;
            this.values.client_address = this.clientAddresses[0].value;
        });
    },

    getAllClients() {
        this.$emit('showProgress');
        this.$api.client.getAll().then(data => {
            this.$emit('hideProgress');

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            this.itemsClients = data.values.clients.map( el => { return {text: el.name, value: el.id} });

        });
    },

    insertOrder() {
      if (this.currentTab==0)
        this.insertOrderWithExistingClient();
      else
        this.insertOrderWithNewClient();
    },

    insertOrderWithExistingClient() {
      if (!this.$refs.newOrder.validate())
        return;

      this.$emit('showProgress');
      this.$api.orders.insert(this.values).then(data => {
        this.$emit('hideProgress');

          if (!data.ok) {
            this.$emit('showSnack', 'Errore inserimento', 'error');
            return;
          } else {
            this.$emit('showSnack', 'Ordine inserito', 'success');
            this.$refs.newOrder.reset();
          }
      });
    },

    insertOrderWithNewClient() {
      if (!this.$refs.newOrderNewClient.validate())
        return;

      this.$emit('showProgress');
      this.$api.orders.insertWithNewClient(this.valuesNewClient).then(data => {
        this.$emit('hideProgress');

          if (!data.ok) {
            this.$emit('showSnack', data.values.msg, 'error');
            return;
          } else {            
            this.$emit('showSnack', 'Ordine inserito', 'success');
            this.$refs.newOrderNewClient.reset();
          }
      });
    },

  },

  mounted() {
    this.getAllClients();    
  },

    watch: {
        // Dynamically get list of comune from DB
        search (query) {
            // Items have already been requested
            if (this.isLoading)
                return

            this.isLoading = true

            // Lazily load input items
            this.$axios.post(this.$env.api+'Comune/', {funct: 'search', query}).then(({data}) => {
                this.items = data.values.items;
            })
            .finally(() => {
                this.isLoading = false;
            });
        },
    },
      
}

</script>