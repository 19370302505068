<template>
    <div>

        <v-container>
            <v-row>
                <v-col>
                    <v-subheader><v-btn x-large color="primary" icon @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn> <h1 class="accent--text">Inserisci Log Operaio</h1></v-subheader>
                </v-col>
            </v-row>
        </v-container>
        <br/>

        <v-card class="mx-auto" max-width="800">

            <v-card-text>
                <v-form ref="form" v-model="form" lazy-validation>

                    <v-select :items="getAllWorkersData"
                              :rules="rules.required"
                              v-model="values.operaio_id"
                              item-value="id"
                              item-text="data"
                              label="Operaio:"
                    >
                        <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            {{ data.item.name }} - {{ data.item.surname }}
                        </template>
                        <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render items when the select is open -->
                            {{ data.item.name }} - {{ data.item.surname }}
                        </template>
                    </v-select>

                    <v-text-field
                        v-model="values.amount"
                        type="number"
                        :rules="rules.required"
                        label="Importo:"
                        required
                    ></v-text-field>

                    <v-textarea
                        v-model="values.description"
                        :rules="rules.required"
                        label="Descrizione...">
                    </v-textarea>


                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="$router.go(-1)">Annulla</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="accent" @click="insertLogWorkers">Inserisci</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {

            rules: {
                required: [value => (!value) ? 'Il campo non può essere vuoto.' : true],
                hour: [value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true],
                date: [value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true],
            },

            getAllWorkersData: [],

            form: false,
            showForm: true,
            values: {
                user_id: null,
                operaio_id: null,
                amount: null,
                //date: null,
                description: null,
            },



        }
    },

    methods: {

        getAllWorkers() {
            this.$emit('showProgress');
            this.$api.logWorkers.getAllWorkers().then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                console.log(data.values.workers)
                this.getAllWorkersData = data.values.workers;


            });
        },

        insertLogWorkers() {
            if (!this.$refs.form.validate())
                return;

            // Send new logWorkers to database
            this.$emit('showProgress');
            this.$api.logWorkers.createNew(this.values).then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;

                }
                // Ok
                this.$refs.form.reset();
                this.$emit('showSnack', 'Memo inserita', 'success');

            });
        },


    },

    mounted() {

        this.getAllWorkers();

    }
}
</script>

<style scoped>

</style>
