<template>
    <div>
        <br/>

        <v-card class="mx-auto" max-width="800">
            <v-card-title>
                Inserisci memo
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="form" lazy-validation>


                    <v-text-field
                        v-model="values.title"
                        :rules="rules.required"
                        label="Titolo"
                        required
                    ></v-text-field>

                    <template>
                        <v-menu
                            ref="calendarMenu"
                            v-model="calendarMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="values.date"
                                    label="Data*:"
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="rules.required && rules.date" 
                                    required
                                ></v-text-field>
                            </template>
                            <v-date-picker
                            ref="picker"
                            v-model="dateOriginalFormat"
                            min="1950-01-01"
                            @change="calendarSave"
                            locale="it"
                            :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </template>

                    <v-text-field
                        v-model="values.time"
                        label="Ora*:"
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules.required && rules.hour"
                        required
                    ></v-text-field>

                    <v-textarea
                        v-model="values.description"
                        :rules="rules.required"
                        label="Contenuto">
                    </v-textarea>


                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="$router.go(-1)">Annulla</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="confirmModify">Aggiorna memo</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
export default {

    data() {
        return {

            // Date select
            date: null,
            calendarMenu: false,
            dateOriginalFormat: '',
            hourMenu: false,

            rules: {
                required: [value => (!value) ? 'Il campo non può essere vuoto.' : true],
                hour: [value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true],
                date: [value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true],
            },

            form: false,
            showForm: true,
            values: {
                user_id: null,
                title: null,
                date: null,
                time: null,
                description: null,
            },

            memoId: false,
        }
    },

    methods: {
        calendarSave (date) {
            this.$refs.calendarMenu.save(date)
        },

        getMemo(id) {
            this.$emit('showProgress');

            // Get memo data
            this.$api.memo.getFromId(id).then(data => {
                if (!data.ok) {
                    this.$emit('showSnack', 'Error connecting to database', 'error');
                    return;
                }

                let memo = data.values.memo;

                this.values = {
                    user_id: memo.user_id,
                    title: memo.title,
                    description: memo.description,
                    date: memo.date,
                    time: memo.time
                };

                this.$emit('hideProgress');
            });

            this.modifyOverlay = true;
        },

        confirmModify() {
            if (!this.$refs.form.validate())
                return;
            this.$emit('showProgress');

            // Save memo data
            this.values.id = this.memoId;
            this.$api.memo.updateMemo(this.values).then(data => {
                if (!data.ok) {
                    this.$emit('showSnack', 'Error connecting to database', 'error');
                    return;
                }

                this.$emit('hideProgress');
                this.$emit('showSnack', 'Memo aggiornato', 'success');
            });
        },

    },

    watch: {
        dateOriginalFormat(val) {
          this.values.date = val.split('-').reverse().join('-');
      },
    },

    mounted() {
        this.memoId = this.$route.params.id;
        this.getMemo(this.memoId);
    }
}
</script>

<style scoped>

</style>
