<template>
  <div>  

    <br />

    <v-card class="mx-auto" max-width="800">
      <v-card-title>
          Cerca cliente
      </v-card-title>
      <v-card-text>

        <v-form ref="form" v-model="form" lazy-validation>
          <v-text-field v-model="values.surname" label="Cognome:"></v-text-field>

          <v-text-field v-model="values.email" label="E-mail:"></v-text-field>

          <v-text-field v-model="values.cellphone" :rules="rules.phone" label="Telefono / Cellulare:"></v-text-field>

          <h4>Compilare almeno uno dei campi.</h4>                   
        </v-form>

      </v-card-text>
      <v-card-actions>
        <v-btn @click="search" color="accent">Cerca</v-btn>
      </v-card-actions>
    </v-card>

    <br /><br />

    <v-container>
      <v-row>
        <v-col>
          
          

          <div v-if="clients">
            <v-container>
              <v-row>
                <v-col>
                  <template>
                    <v-data-table
                      :headers="headers"
                      :items="clients"
                      :items-per-page="10"
                      class="elevation-5"
                    >

                      <template v-slot:item.actions="{ item }">
                        <v-btn v-if="$store.getters.rules.clients.edit" class="mr-3" color="info" @click="addPickupPlace(item.id)" x-small fab><v-icon>mdi-home-plus</v-icon></v-btn>
                        <v-btn v-if="$store.getters.rules.clients.edit" class="mr-3" color="success" @click="$router.push({path: '/client/modify/'+item.id})" x-small fab><v-icon>mdi-pencil</v-icon></v-btn>
                        <v-btn v-if="$store.getters.rules.clients.edit" color="error" @click="deleteClient(item.id)" x-small fab><v-icon>mdi-delete</v-icon></v-btn>
                      </template>

                      <template v-slot:item.pickupPlaces="{ item }">
                        <div v-if="item.pickupPlaces==''">
                          -
                        </div>
                        
                        <div v-if="item.pickupPlaces!=''">
                          <div v-for="(place, index) in item.pickupPlaces" v-bind:key="index" @mouseover="showPopupButtons(place.id)" @mouseleave="hidePopupButtons">

                            <div>
                              <span v-html="place.text"></span>
                              <span v-if="popupButtons==place.id">
                                <v-btn icon x-small color="success" @click="addressModify(place.id)"><v-icon>mdi-pencil</v-icon></v-btn>
                                <v-btn icon x-small color="error" @click="addressDelete(place.id)"><v-icon>mdi-close</v-icon></v-btn>
                              </span>
                            </div>                           

                          </div>
                        </div>
                      </template>
       
                    </v-data-table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modify -->
    <v-overlay v-model="modifyOverlay">
      <v-card class="mx-auto" max-width="800" light>
        <v-card-title>
            Modifica cliente
        </v-card-title>
        <v-card-text>
            <v-form ref="modifyForm" v-model="modifyForm" lazy-validation>
              
                <v-container>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="modifyValues.name" label="Nome:" required></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="modifyValues.surname" :rules="rules.required" label="Cognome*:" required></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="modifyValues.email" :rules="rules.email" label="E-mail:"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field v-model="modifyValues.phone" :rules="rules.phone" label="Telefono:"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field v-model="modifyValues.cellphone" :rules="rules.phone" label="Cellulare:"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>

                <h4>I campi contrassegnati * sono obbligatori.</h4>                   
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="modifyOverlay = false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmModify">Modifica</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <!-- Delete -->
    <v-overlay v-model="deleteClientOverlay">
      <v-card class="mx-auto" max-width="800" light>
        <v-card-title>
            Elimina cliente
        </v-card-title>
        <v-card-text>
          Sei sicuro di voler eliminare questo cliente? 
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="deleteClientOverlay = false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmDeleteClient">Si</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <!-- Add pickup -->
    <v-overlay v-model="addPickupPlaceOverlay">

      <!-- Add -->
      <LocationForm :create="true" :userId="addPickupPlaceClientId" @confirm="confirmPickupAddress()" @cancel="addPickupPlaceOverlay=false" />

    </v-overlay>

    <!-- Delete address -->
    <v-overlay v-model="deleteAddressOverlay">
      <v-card class="mx-auto" max-width="800" light>
        <v-card-title>
            Elimina luogo di carico
        </v-card-title>
        <v-card-text>
          Sei sicuro di voler eliminare questo luogo? 
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="deleteAddressOverlay = false">No</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirmDeleteAddress">Si</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

   <!-- Modify address -->
    <v-overlay v-model="modifyAddressOverlay">         

      <!-- ModifyLocation -->
      <LocationForm :create="false" :id="pickupPlace" @confirm="confirmPickupAddress()" @cancel="modifyAddressOverlay=false" />

    </v-overlay>

  </div>
</template>

<script>

import LocationForm from '../../components/location/CreateModify'

export default {

  components: {LocationForm},
  
  data() {

    return {

      pickupPlace: null,

      form: false,
      values: {
        surname: null,
        email: null,
        cellphone: null,
      },

      /* End search */

      popupButtons: null,

      rules: {
        required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
        email: [ value => (value && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) ? 'Formato non valido' : true],
        phone: [ value => (value && !/^\d+$/.test(value)) ? 'Inserire i numeri senza spazi né caratteri speciali' : true],
      },

      addPickupPlaceClientId: null,
      addPickupPlaceOverlay: false,

      newPickupForm: false,
      newPickupFormValues: {
        address: null,
        zip: null,
        city: null,
        province: null,
        notes: null,
      },

      modifyOverlay: false,

      deleteAddressOverlay: false,
      deleteAddressId: null,

      modifyAddressOverlay: false,
      modifyPickupFormValues: {
        address: null,
        zip: null,
        city: null,
        province: null,
        notes: null,
      },

      modifyForm: false,
      modifyValues: {
        id: null,
        name: null,
        surname: null,
        email: null,
        phone: null,
        cellphone: null,
      },

      deleteClientOverlay: false,
      deleteClientId: false,

      headers: [
        {text: 'Cognome e nome', value: 'name'},
        {text: 'E-mail', value: 'email'},
        {text: 'Telefono', value: 'phone'},
        {text: 'Cellulare', value: 'cellphone'},
        {text: 'Luoghi', value: 'pickupPlaces'},
        {text: 'Azione', value:'actions'},
      ],

      clients: null, // {name:'a',email:'a',phone:'a',cellphone:'a',address:'a'},
    
    }
  },

  methods: {

    getAll() {
      this.search();

      /*
        this.$emit('showProgress');
        this.$api.client.getAll().then(data => {
            this.$emit('hideProgress');

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            this.clients = data.values.clients;
        });
      */
    },

    clientAction(id) {
      this.$emit('showSnack', 'Debug: client ID #' + id, 'info');
    },

    modifyClient(id) {
      this.$emit('showProgress');

      // Get client data
      this.$api.client.getFromId(id).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          this.modifyValues = data.values.client;

          this.$emit('hideProgress');
      });
      
      this.modifyOverlay = true;
    },

    confirmModify() {
      if (!this.$refs.modifyForm.validate())
        return;
      this.$emit('showProgress');

      // Save client data
      this.$api.client.updateClient(this.modifyValues).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          // Update clients list
          this.getAll();
          
          this.modifyOverlay = false;

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Cliente modificato', 'success');
      });
      
    },

    // Delete client
    deleteClient(id) {
      this.deleteClientId = id;
      this.deleteClientOverlay = true;
    },

    confirmDeleteClient() {
      this.$api.client.deleteClient(this.deleteClientId).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          // Update clients list
          this.getAll();

          this.deleteClientOverlay = false;

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Cliente eliminato', 'success');
      });
    },

    confirmPickupAddress() {
      this.$emit('showSnack', 'Informazione salvata', 'success');
      this.modifyAddressOverlay = false;
      this.addPickupPlaceOverlay = false;
      this.getAll();
    },

    // Add pickup place
    addPickupPlace(id) {
      this.addPickupPlaceClientId = id;
      this.addPickupPlaceOverlay = true;
    },

    confirmAddPickupPlace() {
      if (!this.$refs.newPickupForm.validate())
        return;
      this.$emit('showProgress');

      // Insert new place
      this.$api.client.addPickupPlace(this.addPickupPlaceClientId, this.newPickupFormValues).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          // Update clients list
          this.getAll();
          
          this.addPickupPlaceOverlay = false;
          !this.$refs.newPickupForm.reset();

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Luogo inserito', 'success');
      });
    },

    showPopupButtons(id) {
      this.popupButtons = id;      
    },

    hidePopupButtons() {
      this.popupButtons = -1;
    },

    addressDelete(id) {
      this.deleteAddressId = id;
      this.deleteAddressOverlay = true;
    },

    confirmDeleteAddress() {
      this.$api.client.deletePickupPlace(this.deleteAddressId).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          // Update clients list
          this.getAll();

          this.deleteAddressOverlay = false;

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Luogo eliminato', 'success');
      });
    },

    addressModify(id) {
      this.pickupPlace = id;
      
      this.modifyAddressOverlay = true;
    },

    confirmAddressModify() {
      if (!this.$refs.modifyAddressForm.validate())
        return;
      this.$emit('showProgress');

      // Save address data
      this.$api.client.updatePickup(this.modifyPickupFormValues).then(data => {   
          if (!data.ok) {
              this.$emit('showSnack', 'Error connecting to database', 'error');
              return;
          }

          // Update clients list
          this.getAll();
          
          this.modifyAddressOverlay = false;

          this.$emit('hideProgress');
          this.$emit('showSnack', 'Luogo di carico modificato', 'success');
      });
    },

    search() {
        if (!this.values.surname && !this.values.email && !this.values.cellphone) {
          this.$emit('showSnack', 'Compilare almeno uno dei campi', 'error');
          return;
        }      

        this.$emit('showProgress');
        this.$api.client.search(this.values).then(data => {
            this.clients = null;
            this.$emit('hideProgress');

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            // Ok
            if (data.values.clients.length > 0)
              this.clients = data.values.clients;
            else
              this.$emit('showSnack', 'Nessun cliente trovato', 'error');

        });
    },

  },

  // Events
  mounted() {
       
  },

}

</script>
