<!-- not used -->
<template>
  <div>

    <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" v-bind="attrs" v-on="on" @click="newWork">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>
        <span>
            Nuova lavorazione
        </span>
    </v-tooltip>

    <v-overlay v-model="overlayWorks">
      <v-card light min-width="700">
        <v-card-title>Inserisci lavorazione</v-card-title>
        <v-card-text>
          <v-form ref="formWorks">
              <v-row>
                <v-col cols="8">
                  <template>
                      <v-menu
                          ref="calendarFormWorks"
                          v-model="calendarFormWorks"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                              v-model="worksForm.date"
                              label="Data*:"
                              v-bind="attrs"
                              v-on="on"
                              :rules="[$rules.required && $rules.date]" 
                              required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                        ref="picker"
                        v-model="worksFormOriginalFormat"
                        min="1950-01-01"
                        locale="it"
                        :first-day-of-week="1"
                        ></v-date-picker>
                      </v-menu>
                  </template>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="worksForm.hour"
                    label="Ora*:"
                    v-bind="attrs"
                    v-on="on"
                    :rules="[$rules.required && $rules.hour]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              
              <v-row>
                <v-col>
                  <v-switch class="m-0" v-model="worksForm.show_worksheet" color="success" label="Visibile in schermata lavorazioni (claudio)"></v-switch>
                </v-col>
                <v-col v-if="$env.siteA && $store.getters.rules.editFlagRenata">
                  <v-switch
                    class="m-0"
                    label="Renata"
                    color="success"
                    :false-value="0"
                    :true-value="'1'"
                    v-model="worksForm.flag_renata"
                  ></v-switch>
                </v-col>
              </v-row>
                                   
            <v-select v-model="worksForm.type" label="Tipologia lavoro:" :items="itemsWorkType"></v-select>
            <v-select v-model="worksForm.id_pickupPlace" label="Indirizzo del lavoro:" :items="itemsWorksPlaces"></v-select>
            <v-textarea v-model="worksForm.notes_claudio" label="Note:" rows="2"></v-textarea>
            
            <!-- Costo permesso and flag 'permesso in schermata lavorazioni' -->
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="worksForm.police_permission" :rules="$rules.integer" type="number" label="Costo Permesso:" prefix="€"></v-text-field> 
              </v-col>
              <v-col>
                <v-switch
                  class="m-0"
                  label="Permesso in schermata lavorazioni"
                  color="success"
                  :false-value="0"
                  :true-value="'1'"
                  v-model="worksForm.flag_in_schermata_lavorazioni"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="overlayWorks = false">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="processWorkForm">Inserisci</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay> 


  </div>
</template>

<script>

const defaultClaudioOrder = 9999;

export default {

  name: 'AddWork',

  data() {
    return {
        on: false,
        attrs: false,

        overlayWorks: false,

        /* Works */     
        calendarFormWorks: false,
        worksFormOriginalFormat: '',
        hourFormWorks: false,
        itemsWorksPlaces: [],
        itemsWorkType: [],

        worksForm: {
            date: null,
            hour: null,
            show_worksheet: true,        
            flag_renata: null,
            flag_in_schermata_lavorazioni: null,
            type: null,
            id_pickupPlace: null,
            notes: null,
            notes_claudio: null,
            police_permission: 0,
        },
    }
  },

  props: {
    today: String,
  },

  methods: {
    newWork() {
        this.worksForm = {
            date: this.today,
            hour: '08:00',
            show_worksheet: true,        
            flag_renata: 0,
            flag_in_schermata_lavorazioni: null,
            type: null,
            id_pickupPlace: this.itemsWorksPlaces[0].id,
            notes: null,
            notes_claudio: null,
            police_permission: 0,
        };

        this.overlayWorks = true;
    },

    processWorkForm() {
      if (!this.$refs.formWorks.validate())
        return;

      //Add new
      this.$api.orders.addWork(defaultClaudioOrder, this.worksForm).then(data => {
          this.overlayWorks = false;
          if (data.ok) {
            this.$refs.formWorks.reset();
            this.$emit('showSnack', 'Lavorazione inserita', 'success');
            this.$emit('refresh');
          } else {
            this.$emit('showSnack', data.values.msg, 'Errore');
          }
      }); 
    },

    getOrder() {
      this.$api.orders.getFromId(defaultClaudioOrder).then(data => {      
        this.itemsWorksPlaces = data.values.order.places;
      });      
    },

    // Load work types from DB
    getWorkTypes() {
      this.$axios.post(this.$env.api+'Works/', {funct: 'getWorkTypes'}).then(({data}) => {
        if (data.ok) {
          this.itemsWorkType = data.values.workTypes.map(el => { return {value: el.value, text: el.text} });
        }                    
      });
    },
  },

  mounted() {
      this.getOrder();
      this.getWorkTypes();
  },

  watch: {

    worksFormOriginalFormat(val) {
        this.worksForm.date = val.split('-').reverse().join('-');
    },

  },

}
</script>