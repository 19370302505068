<template>
  <div>  

    <br />

    <v-container>
      <v-row>
        <v-col>
          <v-subheader><v-btn x-large color="primary" icon @click="$router.go(-1)"><v-icon>mdi-arrow-left</v-icon></v-btn> <h1 class="accent--text">Gestire documenti allegati</h1></v-subheader>
        </v-col>
      </v-row>
    </v-container>

    <br />
          
    <!-- Manage documents -->
    
            <!-- {{ documentsUploadFolder + document.filename}} -->

          <v-card class="mx-auto"  max-width="1000"> 
            <v-card-text>

                <v-row class="text-center">
                  <v-col class="col-4">
                    <v-btn text small>
                      <v-icon class="pr-2" color="primary">mdi-account-box</v-icon>
                      {{ info.client }}
                    </v-btn>
                  </v-col>
                  <v-col class="col-4">
                    <v-btn @click="($store.getters.rules.appointments.edit) ? $router.push({path: '/appointment/modify/'+info.id_appointment}) : ''" text small>
                      <v-icon class="pr-2" color="accent">mdi-notebook-outline</v-icon>
                      Appuntamento: {{ info.appointment_date }}
                    </v-btn>
                  </v-col>
                  <v-col class="col-4" v-if="$store.getters.rules.orders.view">
                    <v-btn @click="$router.push({path: '/orders/'+info.id_order})" text small :disabled="(info.id_order===false) ? true : false">
                      <v-icon class="pr-2" color="info">mdi-message-bulleted</v-icon>
                      Ordine: {{ (info.id_order) ? '#' + info.id_order : 'non creato' }}
                    </v-btn>
                  </v-col>
                </v-row>

                  <!--
                  <br /><br />

                  <b>Data e ora appuntamento</b>
                  <br /> 
                  {{ order.date }} {{ order.time }} <v-btn @click="$router.push({path: '/appointment/modify/'+order.id_appointment})" x-small icon color="success"><v-icon>mdi-pencil</v-icon></v-btn>
                  <br /><br /> 
                  -->

 

            </v-card-text>
          </v-card>

          <br /><br />

          <template>
            <v-data-table
              style="max-width: 1000px"
              v-if="documents.length>0"
              :headers="documentsHeader"
              :items="documents"
              :items-per-page="10"
              class="elevation-1 mx-auto"
            >
              <template v-slot:item.show="{ item }">
                <span
                  :class="((item.showInCharge=='1') ? 'success' : 'error') + '--text'"            
                >

                <v-tooltip right v-if="$store.getters.rules.documents.edit">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="changeShowInCharge(item.id, item.showInCharge)" class="mr-3" :color="(item.showInCharge=='1') ? 'success' : 'error'"  icon v-bind="attrs" v-on="on"><v-icon>{{ (item.showInCharge==1) ? 'mdi-check' : 'mdi-close' }}</v-icon></v-btn>
                  </template>
                  <span>Cambiare visibilità</span>
                </v-tooltip>

                <div v-if="$store.getters.rules.documents.showInCharge">
                  <v-icon color="success">mdi-check</v-icon>
                </div>

                </span>
              </template>

              <template v-slot:item.actions="{ item }">

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="previewDocument(item.filename)" class="mr-3" color="info" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-eye</v-icon></v-btn>
                  </template>
                  <span>Visionare documento</span>
                </v-tooltip>

                <v-tooltip bottom v-if="$store.getters.rules.documents.edit">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="error" @click="deleteDocument(item.id)" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
                  </template>
                  <span>Eliminare documento</span>
                </v-tooltip>                        
              </template>  


            </v-data-table>
          </template>
         
            <v-container style="max-width: 1000px" class="mx-auto" v-if="documents.length==0">
              <v-row>
                <v-col>

                  Nessun documento allegato.

                </v-col>
              </v-row>
            </v-container>


          <br /><br />

          <v-card class="mx-auto" max-width="1000" v-if="$store.getters.rules.documents.edit">
            <v-card-text class="text-center">
              <v-form ref="formUploadDocument">
                <v-file-input v-model="formDocuments.file" label="Caricare documento" :rules="rules.required"></v-file-input>
                <v-switch v-model="formDocuments.showInCharge" label="Visibile in Schermata Lavorazione Giornaliere (Claudio)" color="success"></v-switch>
                <v-btn color="success" @click="documentUpload">Caricare</v-btn>
              </v-form>
            </v-card-text>
          </v-card>


      <!-- Delete -->
      <v-overlay v-model="deleteOverlay">
        <v-card class="mx-auto" max-width="800" light>
          <v-card-title>
            Elimina documento
          </v-card-title>
          <v-card-text>
            Sei sicuro di voler eliminare questo documento? 
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="deleteOverlay = false">No</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="confirmDeleteDocument">Si</v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>


  </div>
</template>

<script>

import env from '../../env';

const documentsUploadFolder = env.uploadsFolder + 'documents/';

export default {
    
  data() {
    return {

      info: {
        name: false,

      },

      documentId: false,

      currentAppointmentId: false,
      documentsHeader: [
        {text: 'Nome', value:'filename'},
        {text: 'Visibile in Schermata Lavorazione Giornaliere (Claudio)', value:'show'},
        {text: 'Azione', value:'actions'},        
      ],

      formDocuments: {
        file: null,
        showInCharge: true,
      },

      documentsUploadFolder,
      documentsOverlay: true,
      documents: [
      ],

      rules: {
        required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
        hour: [ value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true ],
        date: [ value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true ],
      },

      deleteOverlay: false,
      deleteDocumentId: false,
    
    }
  },

  methods: {

    // Documents
    previewDocument(filename) {
      window.open(documentsUploadFolder + filename, '_blank');
      //alert('placeholder: open file in new windows ' + documentsUploadFolder + filename);
    },

    changeShowInCharge(id, value) {

      if (value=='1')
        value = false;
      else 
        value = true;
     
        this.$api.documents.setShowInCharge(id, value).then(data => {   
          if (data.ok) {
            this.showDocuments(this.documentId);

            if (value)
              this.$emit('showSnack', 'Documento impostato come visibile', 'success')
            else 
              this.$emit('showSnack', 'Documento impostato come non visibile', 'success')

          } else {     
              this.$emit('showSnack', 'Errore di connessione', 'error')
          }
        }); 

    },

    deleteDocument(id) {
      this.deleteDocumentId = id;
      this.deleteOverlay = true;
      //alert('placeholder: delete document ' + id);
    },

    confirmDeleteDocument() {
      this.$emit('showProgress');

      this.$api.documents.delete(this.deleteDocumentId).then(data => {   
        if (data.ok) {
          this.$emit('hideProgress');
          this.deleteDocumentId = false;
          this.deleteOverlay = false;
          this.showDocuments(this.documentId);
          this.$emit('showSnack', 'Documento eliminato', 'success')
        } else {
            this.$emit('hideProgress');
            this.$emit('showSnack', 'Errore di connessione', 'error')
        }
      }); 

    },

    showDocuments(id) {
      this.$emit('showProgress');

      this.currentAppointmentId = id;

      // Get info
      this.$api.documents.getInfo(id).then(data => {   
        this.info = data.values;
      });   

      this.$api.documents.getFromId(id).then(data => {   
          if (!data.values.files) {
              this.documents = [];
              this.$emit('hideProgress');
              return;
          }

          if (this.$store.getters.rules.documents.showInCharge) {
            // Show only approved documents
            data.values.files.forEach((doc, index) => {
              if (doc.showInCharge==0)
                data.values.files.splice(index, 1);
            });
          }

          this.documents = data.values.files;          
          this.$emit('hideProgress');
      });      
    },

    documentUpload() {
      if (!this.$refs.formUploadDocument.validate())
        return;

      this.$emit('showProgress');       
      
      this.formDocuments.appointmentId = this.currentAppointmentId;

      this.$api.documents.upload(this.formDocuments) 
        .then(data => {
          if (data.ok) {            
            // Set form values in order to set flag as default true      
            this.formDocuments.file = null;
            this.formDocuments.showInCharge = true;
            this.$refs.formUploadDocument.resetValidation();

            this.$emit('hideProgress');
            this.$emit('showSnack', 'Documento caricato', 'success');
            this.showDocuments(this.currentAppointmentId);
          } else {
            this.$emit('showSnack', 'Errore: documento non caricato', 'error');
            this.$emit('hideProgress');
          }
        });
    },

  },
  
  watch: {
    dateOriginalFormat(val) {
        this.modifyValues.date = val.split('-').reverse().join('-');
    },
  },

  // Events
  mounted() {

    this.documentId = this.$route.params.id;

    // Debug
    this.showDocuments(this.documentId);
  },

}

</script>