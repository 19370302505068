import { render, staticRenderFns } from "./Insert.vue?vue&type=template&id=725fb8b7&scoped=true"
import script from "./Insert.vue?vue&type=script&lang=js"
export * from "./Insert.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "725fb8b7",
  null
  
)

export default component.exports