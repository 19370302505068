<template>
	<div>		
		<v-card max-width="600" class="mx-auto">
			<v-card-title>Gestione sito B</v-card-title>
			<v-card-text>
				<v-switch
					v-model="siteBStatus"
					@click="toggleStatus"
					color="green"
					:label="(siteBStatus) ? 'Stato: Online' : 'Stato: Offline'"
				>
					
				</v-switch>

				<v-divider class="mb-4"></v-divider>

				<v-btn v-if="!showUpdateLink" color="success" @click="update">Aggiorna</v-btn>
				<a href="http://188.245.44.139:9016/api/CloudImport/" target="_blank" v-if="showUpdateLink" class="red--text">
					Clicca qui per completare l'aggiornamento
				</a>
				
			</v-card-text>
		</v-card>
	</div>
</template>

<script>

export default {
	name: 'Home',

	// Data
	data() {
		return {
			siteBStatus: false,
			showUpdateLink: false,
		}
	},

	methods: {
		toggleStatus() {
			this.$emit('showProgress');

			this.$axios.post(this.$env.api + 'SiteB/', {funct: 'toggle', siteBStatus: this.siteBStatus})
            .then(response => {
				this.$emit('hideProgress');

				let message = 'Status sito B: ';

				if(this.siteBStatus)
					message += 'ONLINE';
				else
					message += 'OFFLINE';
				
				this.$emit('showSnack', message, 'success');

				return response.data;
			});
		},

		update() {
			this.$emit('showProgress');

			this.$axios.post(this.$env.api + 'Cloud/')
            .then(() => {				
				this.$emit('hideProgress');

				this.showUpdateLink = true;

				return true;
			});
		},
	},

	mounted() {
		this.$emit('showProgress');

		this.$axios.post(this.$env.api + 'SiteB/', {funct: 'getStatus'})
		.then(response => {
			this.$emit('hideProgress');

			this.siteBStatus = response.data.values.status;
		});
	}

}

</script>
