<template>
  <div>  

    <v-subheader><h1 class="accent--text">Statistiche agenti</h1></v-subheader>

    <!-- Legend -->
    <v-card class="mx-auto mb-3" max-width="1200" flat>
      <v-card-text>
        <ul class="legend-ul">
          <li> 💡 I report sui singoli agenti vengono mostrati quando si inserisce un range di date.</li>
          <li> 💡 <b>"Nessun Agente"</b> raggruppa sia gli appuntamenti che sono stati inseriti come <b>"Nessun Agente"</b> sia gli ordini inseriti diretti senza passare per un appuntamento.</li>
          <li> 💡 <b>Costo Netto</b> è calcolato come la somma di tutti i costi lavorazioni eccetto per le lavorazioni tipologia Imballaggio meno la somma dei permessi, meno la somma dei costi materiali.</li>
          <li> 💡 Il totale dei costi netti esclude gli ordini che sono fuori dalla campania.</li>
          <li> 💡 Gli ordini fuori della Campania vengono segnati a parte e con una cifra di 100€.</li>
        </ul>
      </v-card-text>
    </v-card>

    <v-card class="mx-auto mt-2 mb-3" max-width="1200" flat> 
        <v-card-text>
          <h4>Filtra per data</h4>

          <v-form ref="datesForm">
            <v-container>
              <v-row>

                <v-col>
                  <template>
                    <v-menu ref="calendarStartMenu" v-model="calendarDateStart" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dateStart" label="Data inizio:" v-bind="attrs" v-on="on" :rules="rules.date"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="originalDateStart"
                        min="1950-01-01"
                        @change="calendarStartSave"
                        locale="it"
                        :first-day-of-week="1"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-col>

                <v-col>
                  <template>
                    <v-menu ref="calendarEndMenu" v-model="calendarDateEnd" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dateEnd" label="Data fine:" v-bind="attrs" v-on="on" :rules="rules.date"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="originalDateEnd"
                        min="1950-01-01"
                        @change="calendarEndSave"
                        locale="it"
                        :first-day-of-week="1"
                      ></v-date-picker>
                    </v-menu>
                  </template> 
                </v-col>

              </v-row>
            </v-container>

          </v-form>

          <div class="text-right">
            <v-btn @click="cancelFilters()" color="error">Cancella filtri</v-btn>
            <v-btn @click="filter()" color="success" class="ml-3">Filtra</v-btn>
          </div>                    
        </v-card-text>
    </v-card>

    <v-card class="mx-auto mt-2" max-width="1200"> 
      <v-card-text>

        <template v-if="showStats">

          <v-simple-table>
            <thead>
              <tr>
                <th>Agente</th>
                <th>Tot.appuntamenti</th>
                <th :class="'confirmedColumn-dark-'+$vuetify.theme.dark">Confermati</th>
                <th :class="'text-center confirmedColumn-dark-'+$vuetify.theme.dark">%</th>
                <th :class="'failedColumn-dark-'+$vuetify.theme.dark">Falliti</th>
                <th>In trattativa</th>
                <th>Prezzo Pattuito (app.confermati)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(agent, index) in generalStats" v-bind:key="index">
                <td>
                  {{ agents.find(el => el.value == agent.id_agent).text }}
                </td>
                <td>
                  {{ agent.quantity }}
                </td>
                <td :class="'confirmedColumn-dark-'+$vuetify.theme.dark">
                  {{ agent.confirmed || '-' }}
                </td>

                <!-- % of confirmed -->
                <td :class="'confirmedColumn-dark-'+$vuetify.theme.dark">
                  <span class="percentage-icon">%</span>{{ parseFloat((agent.confirmed || 0) / (agent.quantity || 1) * 100).toFixed(2) }}
                </td>

                <td :class="'failedColumn-dark-'+$vuetify.theme.dark">
                  {{ agent.failed || '-' }}
                </td>
                <td>
                  {{ agent.not_confirmed || '-' }}
                </td>
                <td>
                  € {{ agent.total_price || 0 }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>  

        </template>
        <template v-else>
          Nessun informazione trovata per il periodo scelto.
        </template>

      </v-card-text>
    </v-card>

    <template v-if="filtered && showStats">

      <v-card class="mx-auto mt-10 mb-3" max-width="1200" flat>
        <v-card-title>
          <v-subheader class="pl-0">
            <h2>Statistiche per agente</h2>
          </v-subheader>      
        </v-card-title>        
      </v-card>      

      <!-- Show table for each agent if results were filtered -->
      <v-card class="mx-auto mb-15" max-width="1200" v-for="(agent, index) in generalStats.filter(el => el.id_agent != 0)" v-bind:key="index">
        <v-card-title>          
          {{ agents.find(el => el.value == agent.id_agent).text }}
        </v-card-title>
        <v-card-text>
          
          <!-- Display appointments -->
          <v-simple-table v-if="agentsStats.filter(el => parseInt(el.id_agent) == agent.id_agent).length > 0">
            <thead>
              <tr>
                <th># Ord.</th>
                <th>Cliente</th>
                <th>Campania</th>
                <th>Data</th>
                <th>Prezzo Pattuito</th>
                <th>Costo netto</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(app, index) in agentsStats.filter(el => parseInt(el.id_agent) == agent.id_agent)" v-bind:key="index">
                <td>{{app.order_id}}</td>
                <td>{{ app | clientName }}</td>

                <!-- Order in Campania 1/0 -->
                <td>
                  {{ (!app.is_out_campania || parseInt(app.is_out_campania) == 0) ? 'Sì' : 'No' }}
                </td>

                <td>{{app.date}} {{app.time}}</td>
                <td v-if="$env.siteA">{{app.agreed_price || 0}}</td>
				<td v-else>{{app.agreed_price_2 || 0}}</td>
                <td>
                  <!-- somma di tutti i costi lavorazioni eccetto per le lavorazioni tipologia Imballaggio meno la somma dei permessi, meno la somma dei costi materiali -->
                  {{ parseFloat(app.total_costs) }}
                </td>
              </tr>
            
              <!--
              <tr>
                <td></td>
                <td>
                  <b>
                  
                  </b>
                </td>                
                    Totale lordo 
                <th>                  
                  {{ agentsStats.filter(el => parseInt(el.id_agent) == agent.id_agent).reduce((prev, curr) => prev + parseFloat(curr.agreed_price), 0) }}                  
                </th>                
              </tr>
              -->
              <tr>
                <td colspan="6" class="pt-6 pb-1 agent-summary">
                  <b>Agente {{ agents.find(el => el.value == agent.id_agent).text }}</b>                  

                  <ul>
                    <li>              
                      Riepilogo dal {{dateStart || '-'}} al {{dateEnd || '-'}}
                    </li>
                    <li>
                      Somma dei costi netti esclusi quelli fuori dalla campania: <!-- Totale netto (solo per ordini in Campania) -->
                      € {{
                        agentsStats.filter(el => parseInt(el.id_agent) == agent.id_agent).reduce(
                            (prev, curr) => 
                              prev += (curr.is_out_campania != 1) ? parseFloat(curr.total_costs) : 0
                        , 0)
                      }}
                    </li>
                    <li>
                      Totale lavori fuori dalla Campania:                  
                      {{ agentsStats.filter(el => parseInt(el.id_agent) == agent.id_agent && parseInt(el.is_out_campania) == 1).length }}
                      per un valore di
                      € 
                      {{ agentsStats.filter(el => parseInt(el.id_agent) == agent.id_agent && parseInt(el.is_out_campania) == 1).length * 100 }}
                    </li>
                  </ul>
                </td>
              </tr>

            </tbody>
          </v-simple-table>
          <template v-else>
            Nessun appuntamento confermato nel periodo scelto.
          </template>

        </v-card-text>
      </v-card>

    </template>

  </div>
</template>

<script>

export default {
  
  data() {
    return {
      showStats: false,

      filtered: false,

      rules: {
        required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
        hour: [ value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true ],
        date: [ value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value))
        ?
          (!value)
          ?
            true
          :
            'Formato non valido. (gg-mm-aaaa)'
        :
        true ],
      },

       originalDateStart: null,
       originalDateEnd: null,

       calendarDateStart: false,
       calendarDateEnd: false,

       on: null,
       attrs: null,

       dateStart: null,
       dateEnd: null,

       states: [
        {text: 'In trattativa', value: '0'},
        {text: 'Confermato', value: '1'},
        {text: 'Fallito', value: '2'},
      ],

       agents: [
        {
          text: 'Nessun agente',
          value: 0,   
        },
        {
          text: 'Patrizio Ponzo',
          value: 1
        },
        {
          text: 'Claudio D’Auria',
          value: 2
        },
        {
          text: 'Antonio Amodio',
          value: 3
        },
       ],

      // Data for main table
      generalStats: [],

      // Contains information for each agent (only when filters are applied)
      agentsStats: [],
    }
  },

  methods: {        

    calendarStartSave (date) {
      this.$refs.calendarStartMenu.save(date)
    },

    calendarEndSave (date) {
      this.$refs.calendarEndMenu.save(date)
    },

    loadStats(dateStart, dateEnd) {
      this.showStats = false;

      this.$api.agents.getStats(dateStart, dateEnd).then(data => {
        /**
         * data.values => 
         *  general_stats => [id_agent] => { not_confirmed: x, confirmed: y, failed: z, total_price: xxxx}
         */
		if(!this.$env.siteA) {
			data.values.general_stats.forEach((el, index, object) => {
				if(el.id_agent == 3) {
					object.splice(index, 1);
				}
			});
		}

        this.generalStats = data.values.general_stats;

        if (dateStart || dateEnd) // Get individual agent data
          this.loadAgentsStats(dateStart, dateEnd);
        else
          this.showStats = true;        
      });
    },

    loadAgentsStats(dateStart, dateEnd) {
      this.$api.agents.getAgentsStats(dateStart, dateEnd).then(data => {
        /**
         * data.values => 
         *  general_stats => [id_agent] => { not_confirmed: x, confirmed: y, failed: z, total_price: xxxx}
         */

        this.agentsStats = data.values.stats;
        this.showStats = true;        
      });
    },

    cancelFilters() {
      this.filtered = false;

      this.dateStart = null;
      this.dateEnd = null;
      this.loadStats(this.dateStart, this.dateEnd);
    },

    filter() {
      if (!this.$refs.datesForm.validate())
        return false;

      // Adjust dates
      let dateStart = null;
      let dateEnd = null;
      if (this.dateStart)
        dateStart = this.dateStart.split('-').reverse().join('-');

      if (this.dateEnd)
        dateEnd = this.dateEnd.split('-').reverse().join('-');   

      this.loadStats(dateStart, dateEnd);
      this.filtered = true;
    },
  },

  watch: {
    originalDateStart(val) {
        this.dateStart = val.split('-').reverse().join('-');
    },

    originalDateEnd(val) {
        this.dateEnd = val.split('-').reverse().join('-');
    },
  },

  // Events
  mounted() {
    this.loadStats(null, null); 

    // Debug
    /*
    this.dateStart = '05-04-2022';
    this.dateEnd = '06-05-2022';
    this.filter();
    */
  },
}

</script>

<style scoped>

.confirmedColumn-dark-false {
  background-color: #daffda;
}

.confirmedColumn-dark-true {
  background-color: #030;
}

.failedColumn-dark-false {
  background-color: #ffdada;
}

.failedColumn-dark-true {
  background-color: #300;
}

.percentage-icon {
  margin-right: 2px;
  font-size: 70%;
}

.legend-ul {
  color: #000;
  text-align: justify;
}

.legend-ul > li {
  list-style:none;
  padding-top: 7px;
}

.agent-summary {
  line-height: 2;
}

</style>