import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Agenda from '../views/Agenda.vue'
import Logout from '../views/Logout.vue'
import Register from '../views/Register.vue'
import AdminLog from '../views/log/Index.vue'
import AdminSiteB from '../views/siteB/Manage.vue'
import AgentsStats from '../views/agentsStats/Index.vue'

/* Traslochi */
import LogVehicleIndex from '../views/logVehicle/Index.vue'
import LogVehicleShow from '../views/logVehicle/Show.vue'
import LogVehicleInsert from '../views/logVehicle/Insert.vue'
import LogVehicleModify from '../views/logVehicle/Modify.vue'

import LogWorkersIndex from '../views/logWorkers/Index.vue'
import LogWorkersShow from '../views/logWorkers/Show.vue'
import LogWorkersModify from '../views/logWorkers/Modify.vue'
import LogWorkersInsert from '../views/logWorkers/Insert.vue'

import MemoIndex from '../views/memo/Index.vue'
import MemoShow from '../views/memo/Show.vue'
import MemoInsert from '../views/memo/Insert.vue'
import MemoModify from '../views/memo/Modify.vue'

import AppointmentInsert from '../views/appointment/Insert.vue'
import AppointmentIndex from '../views/appointment/Index.vue'
import AppointmentModify from '../views/appointment/Modify.vue'

import ClientIndex from '../views/client/Index.vue'
import ClientInsert from '../views/client/Insert.vue'
import ClientSearch from '../views/client/Search.vue'
import ClientModify from '../views/client/Modify.vue'

import Orders from '../views/orders/Index.vue'
import InsertOrder from '../views/orders/Insert.vue'
import ManageOrders from '../views/orders/ManageOrders.vue'
import ManageOrdersMaterials from '../views/orders/ManageOrdersMaterials.vue'
import MaterialsHistory from '../views/orders/MaterialsHistory.vue'
import ModifyWork from '../views/orders/ModifyWork.vue'
import Resources from '../views/resources/Index.vue'
import Planning from '../views/planning/Index.vue'
import ManageDocuments from '../views/documents/Index.vue'

import ManageBoxes from '../views/box/Index.vue'
import LogBoxes from '../views/box/Log.vue'

import DailyWorks from '../views/DailyWorks.vue'

Vue.use(VueRouter)

const routes = [


  { path: '/login', name: 'Login', component: Login },
  { path: '/logout', name: 'Logout', component: Logout },

  { path: '/agentsStats/', name: 'AgentsStats', component: AgentsStats },

  { path: '/memo/', name: 'MemoIndex', component: MemoIndex },
  { path: '/memo/show/:id', name: 'MemoShow', component: MemoShow},
  { path: '/memo/insert', name: 'MemoInsert', component: MemoInsert},
  { path: '/memo/modify/:id', name: 'MemoModify', component: MemoModify},

  { path: '/logVehicle', name: 'LogVehicleIndex', component: LogVehicleIndex},
  { path: '/logVehicle/show/:id', name: 'LogVehicleShow', component: LogVehicleShow},
  { path: '/logVehicle/insert', name: 'LogVehicleInsert', component: LogVehicleInsert},
  { path: '/logVehicle/modify/:id', name: 'LogVehicleModify', component: LogVehicleModify},

  { path: '/logWorkers', name: 'LogWorkersIndex', component: LogWorkersIndex},
  { path: '/logWorkers/show/:id', name: 'LogWorkersShow', component: LogWorkersShow},
  { path: '/logWorkers/modify/:id', name: 'LogWorkersModify', component: LogWorkersModify},
  { path: '/logWorkers/insert', name: 'LogWorkersInsert', component: LogWorkersInsert},
  
  { path: '/appointment/insert', name: 'AppointmentInsert', component: AppointmentInsert },
  { path: '/appointment/modify/:id', name: 'AppointmentModify', component: AppointmentModify },
  { path: '/appointment/', name: 'AppointmentIndex', component: AppointmentIndex },

  { path: '/client/', name: 'ClientIndex', component: ClientIndex },
  { path: '/client/insert', name: 'ClientInsert', component: ClientInsert },
  { path: '/client/search', name: 'ClientSearch', component: ClientSearch },
  { path: '/client/modify/:id', name: 'ClientModify', component: ClientModify },

  { path: '/box/', name: 'ManageBoxes', component: ManageBoxes },
  { path: '/box/log/', name: 'LogBoxes', component: LogBoxes },

  { path: '/orders/', name: 'Orders', component: Orders },
  { path: '/orders/insert/', name: 'InsertOrder', component: InsertOrder },
  { path: '/orders/:id', name: 'ManageOrders', component: ManageOrders },
  { path: '/materials/history/', name: 'MaterialsHistory', component: MaterialsHistory }, 
  { path: '/orders/materials/', name: 'ManageOrdersMaterials', component: ManageOrdersMaterials },
  { path: '/orders/works/modify/:id', name: 'ModifyWork', component: ModifyWork },
  { path: '/documents/:id', name: 'ManageDocuments', component: ManageDocuments },
  { path: '/order/documents/:id', name: 'ManageDocumentsOrder', component: ManageDocuments },
  { path: '/resources/', name: 'Resources', component: Resources },
  { path: '/planning/', name: 'Planning', component: Planning },
  { path: '/planning/works', name: 'PlanningWorks', component: Planning },
  { path: '/planning/appointments', name: 'PlanningAppointments', component: Planning },
  { path: '/planning/memo', name: 'PlanningMemo', component: Planning },

  { path: '/dailyWorks/', name: 'DailyWorks', component: DailyWorks },
  { path: '/dailyWorks/:date', name: 'DailyWorksDate', component: DailyWorks },

  { path: '/', name: 'Home', component: Home },
  { path: '/agenda', name: 'Agenda', component: Agenda },
  { path: '/register', name: 'Register', component: Register },
  /* Admin */
  { path: '/admin/log/', name: 'AdminLog', component: AdminLog },

  { path: '/admin/site_b/', name: 'AdminSiteB', component: AdminSiteB },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/*
router.beforeEach((to, from, next) => {

  if (restrictedAreas.includes(to.name)) {
    if (store.getters.userAuth)
      next();
    else
      next({name: 'Login'});
  } else if (adminAreas.includes(to.name)) {
    if (store.getters.isAdmin)
      next();
    else
      next({name: 'Login'});
  } else {
    next();
  }
});
*/

export default router
