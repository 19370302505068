<template>
  <div>  

      <br />

        <v-card class="mx-auto" max-width="800" v-if="showForm">
            <v-card-title>
                Inserire cliente
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="form" lazy-validation>

                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="values.name" label="Nome:" required></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="values.surname" :rules="rules.required" label="Cognome*:" required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="values.email" :rules="rules.email" label="E-mail:"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-text-field v-model="values.phone" :rules="rules.phone" label="Telefono:"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="values.cellphone" :rules="rules.phone" label="Cellulare:"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <h3>Tipologia cliente</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-radio-group v-model="values.privateClient">
                                    <v-radio
                                        label="Privato"
                                        :value="1"
                                        color="green"
                                    ></v-radio>
                                    <v-radio
                                        label="Azienda"
                                        :value="0"
                                        color="green"
                                    ></v-radio>
                                    <v-radio
                                        label="Ente"
                                        :value="2"
                                        color="green"
                                    ></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <!-- Values private -->
                        <template v-if="values.privateClient == 1">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field v-model="values.fiscalCode" :rules="rules.required" label="CF (Codice fiscale)*:"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="values.privateAddress" :rules="rules.required" label="Indirizzo residenza per fattura*:"></v-text-field>
                                </v-col>
                            </v-row>
                        </template>

                        <!-- Values business && ente -->
                        <template v-if="values.privateClient != 1">
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field v-model="values.businessName" :rules="rules.required" label="Ragione Sociale*:"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="values.businessAddress" :rules="rules.required" label="Sede Legale*:"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field v-model="values.uniqueCode" label="Codice Univoco:"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="values.pec" label="PEC:"></v-text-field>
                                </v-col>
                            </v-row>
                        </template>

						<!-- Confirmed -->
						<v-template v-if="$env.siteA">
							<v-col>
								<v-switch class="m-0" v-model="values.confirmed" color="success" label="Cliente attivo"></v-switch>
							</v-col>
						</v-template>

                    </v-container>

                    <h4>I campi contrassegnati * sono obbligatori.</h4>                   

                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="accent" @click="insert">Inserire cliente</v-btn>
            </v-card-actions>
        </v-card>

  </div>
</template>

<script>

export default {
  
  data() {
    return {

        rules: {
          required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
          email: [ value => (value && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) ? 'Formato non valido' : true],
          phone: [ value => (value && !/^\d+$/.test(value)) ? 'Inserire i numeri senza spazi né caratteri speciali' : true],
        },

        form: false,
        showForm: true,
        values: {
            name: null,
            surname: null,
			confirmed: 0,
            email: null,
            phone: null,
            cellphone: null,
            address: null,
            zip: null,
            city: null,
            province: null,
            privateClient: 1,
            // Private only
            fiscalCode: null,
            privateAddress: null,
            // Business only
            businessName: null,
            businessAddress: null,
            uniqueCode: null,
            pec: null,
        },
      
      }
  },

  methods: {

      insert() {
        
        if (!this.$refs.form.validate())
            return;

        // Send new account to database
        this.$emit('showProgress');
        this.$api.client.createNew(this.values).then(data => {
            this.$emit('hideProgress');

            if (!data.ok) {
                this.$emit('showSnack', data.values.msg, 'error');
                return;
            }

            // Ok
            this.$refs.form.reset();
            this.values.privateClient = 1;
            this.$emit('showSnack', 'Cliente inserito correttamente', 'success');

        });
      },

  },

  watch: {

  },

}

</script>