<template>
    <div id="app" v-if="showSite">

        <v-app>

            <v-navigation-drawer app v-model="menuDrawer">
                <v-list dense nav>

                    <!-- Logo removed -->
                    <!--
                    <v-row>
                        <v-col class="text-center">                                                        
                            <img src="@/assets/logo.png" class="header-logo"/>                            
                        </v-col>                        
                    </v-row>
                    -->

                    <div v-if="!$store.getters.loggedIn">
                        <!-- Divider removed -->
                        <!--
                        <v-divider></v-divider>
                        -->
                        <v-list-item key="logout" link @click="navigate('Login')">
                            <v-list-item-icon>
                                <v-icon>mdi-account</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Login</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>

                    <!-- Users menu -->
                    <div v-if="$store.getters.loggedIn">
                        <!-- Divider removed -->
                        <!--
                        <v-divider></v-divider>
                        -->

                        <!-- Anagrafica clienti -->
                        <v-list-group :value="false" prepend-icon="mdi-account-box" no-action>
                            <template v-slot:activator>
                                <v-list-item-title>Anagrafica Cliente</v-list-item-title>
                            </template>

                            <v-list-item link @click="navigate('ClientInsert')"
                                         v-if="$store.getters.rules.clients.edit">
                                <v-list-item-content>
                                    <v-list-item-title>Inserisci</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item link @click="navigate('ClientSearch')">
                                <v-list-item-content>
                                    <v-list-item-title>Cerca</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item link @click="navigate('ClientIndex')">
                                <v-list-item-content>
                                    <v-list-item-title>Elenco</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-group>
                        <!-- End Anagrafica clienti -->

                        <!-- Appuntamenti -->
                        <v-list-group :value="false" prepend-icon="mdi-notebook-outline" no-action
                                      v-if="$store.getters.rules.appointments.view">
                            <template v-slot:activator>
                                <v-list-item-title>Appuntamenti</v-list-item-title>
                            </template>

                            <v-list-item link @click="navigate('AppointmentInsert')"
                                         v-if="$store.getters.rules.appointments.create">
                                <v-list-item-content>
                                    <v-list-item-title>Inserisci</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item link @click="navigate('AppointmentIndex')">
                                <v-list-item-content>
                                    <v-list-item-title>Elenco</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-group>
                        <!-- End Appuntamenti -->

                        <!-- Orders -->
                        <v-list-group :value="false" prepend-icon="mdi-message-bulleted" no-action>
                            <template v-slot:activator>
                                <v-list-item-title>Ordini</v-list-item-title>
                            </template>

                            <v-list-item link @click="navigate('InsertOrder')"
                                         v-if="$store.getters.rules.orders.create">
                                <v-list-item-content>
                                    <v-list-item-title>Inserisci</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item link @click="navigate('Orders')">
                                <v-list-item-content>
                                    <v-list-item-title>Elenco</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-group>
                        <!-- End Orders -->

                        <!-- Magazzino -->
                        <v-list-group :value="false" v-if="$store.getters.rules.orders.manageMaterials" prepend-icon="mdi-dolly" no-action>
                            <template v-slot:activator>
                                <v-list-item-title>Magazzino</v-list-item-title>
                            </template>

                            <v-list-item link @click="navigate('ManageOrdersMaterials')">
                                <v-list-item-content>
                                    <v-list-item-title>Nuovo carico</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item link @click="navigate('MaterialsHistory')">
                                <v-list-item-content>
                                    <v-list-item-title>Storico</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-group>
                        <!-- End Magazzino -->

                        <!-- Memo -->
                        <v-list-group :value="false" prepend-icon="mdi-note-outline" no-action>
                            <template v-slot:activator>
                                <v-list-item-title>Memo</v-list-item-title>
                            </template>

                            <v-list-item link @click="navigate('MemoInsert')">
                                <v-list-item-content>
                                    <v-list-item-title>Inserisci</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item link @click="navigate('MemoIndex')">
                                <v-list-item-content>
                                    <v-list-item-title>Elenco</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-group>

                        <!-- logVehicle -->
                        <v-list-group :value="false" prepend-icon="mdi-notebook-outline" no-action
                        v-if="$store.getters.rules.logVehicle != null">
                            <template v-slot:activator>
                                <v-list-item-title>Log Automezzi</v-list-item-title>
                            </template>

                            <v-list-item link @click="navigate('LogVehicleInsert')"
                            v-if="$store.getters.rules.logVehicle != null">
                                <v-list-item-content>
                                    <v-list-item-title>Inserisci</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item link @click="navigate('LogVehicleIndex')">
                                <v-list-item-content>
                                    <v-list-item-title>Elenco</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-group>
                        <!-- End logVehicle -->

                        <!-- logWorkers -->
                        <v-list-group :value="false" prepend-icon="mdi-notebook-outline" no-action
                                      v-if="$store.getters.rules.logWorkers">
                            <template v-slot:activator>
                                <v-list-item-title>Log Operai</v-list-item-title>
                            </template>

                            <v-list-item link @click="navigate('LogWorkersInsert')"
                                         v-if="$store.getters.rules.logWorkers">
                                <v-list-item-content>
                                    <v-list-item-title>Inserisci</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item link @click="navigate('LogWorkersIndex')">
                                <v-list-item-content>
                                    <v-list-item-title>Elenco</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <!-- End logWorkers -->

                        <!-- Risorse -->
                        <v-list-item link @click="navigate('Resources')" v-if="$store.getters.rules.resources.menu">
                            <v-list-item-icon>
                                <v-icon>mdi-hammer-screwdriver</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Risorse</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- End Risorse -->

                        <!-- Box -->
                        <v-list-item link @click="navigate('ManageBoxes')" v-if="$store.getters.rules.boxes">
                            <v-list-item-icon>
                                <v-icon>mdi-dropbox</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Deposito</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- End Box -->

                        <v-list-item link @click="navigate('AdminLog')" v-if="$store.getters.rules.adminLog != null">
                            <v-list-item-icon>
                                <v-icon>mdi-table-eye</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Log</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item link @click="navigate('AgentsStats')" v-if="$env.siteA && $store.getters.rules.agentsStats != null">
                            <v-list-item-icon>
                                <v-icon>mdi-face-agent</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Stat Agenti</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        

                        <!-- Lavorazione giornaliere -->
                        <v-list-item v-if="$store.getters.rules.viewDailyWorksMenu" link
                                     @click="navigate('DailyWorks')">
                            <v-list-item-icon>
                                <v-icon>mdi-account-hard-hat</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Lavorazione giornaliere</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- End Lavorazione giornaliere -->

                        <!-- Submenu planning -->
                        <v-list-group :value="false" prepend-icon="mdi-calendar" no-action>
                            <template v-slot:activator>
                                <v-list-item-title>Planning</v-list-item-title>
                            </template>

                            <v-list-item link @click="navigate('PlanningWorks')">
                                <v-list-item-content>
                                    <v-list-item-title>Lavorazioni</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item link @click="navigate('PlanningAppointments')">
                                <v-list-item-content>
                                    <v-list-item-title> Appuntamenti</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item link @click="navigate('PlanningMemo')">
                                <v-list-item-content>
                                    <v-list-item-title> Memo</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-group>
                    </div>

                    <!-- Risorse -->
                    <v-list-item link @click="navigate('AdminSiteB')" v-if="$store.getters.rules.manageSiteB && $env.siteA">
                        <v-list-item-icon>
                            <v-icon>mdi-hammer-screwdriver</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>Gestire</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- End Risorse -->

                    <!-- Logout -->
                    <div v-if="$store.getters.loggedIn">
                        <v-divider></v-divider>
                        <v-list-item key="logout" link @click="navigate('Logout')">
                            <v-list-item-icon>
                                <v-icon>mdi-account-cancel</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Logout</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                    <!-- End Logout -->

                    <!-- Dark mode button -->

                    <v-list-item>
                        <v-switch @click="toggleDarkMode"></v-switch>
                        <v-icon>mdi-invert-colors</v-icon>
                    </v-list-item>
                </v-list>

            </v-navigation-drawer>

            <v-app-bar app dense collapse-on-scroll style="background-color: rgba(220, 220, 220, 0.3)">
                <!-- -->
                <v-app-bar-nav-icon @click.stop="menuDrawer = !menuDrawer"></v-app-bar-nav-icon>
                <v-spacer></v-spacer>
                <!-- Title removed -->
                <!--
                <v-toolbar-title>
                    <span>LA NUOVA CAMPANIA TRASLOCHI</span>
                </v-toolbar-title>
                -->
            </v-app-bar>

            <v-main>

                <!-- Provides the application the proper gutter -->
                <v-container fluid>

                    <!-- Overlay for progress -->
                    <v-overlay :value="progressOverlay">
                        <v-progress-circular
                            indeterminate
                            color="secondary"
                        ></v-progress-circular>
                    </v-overlay>

                    <div v-if="debugLogin">
                        debug<br/>
                        logged in: {{ $store.getters.loggedIn }}
                        <br/>
                        username: {{ $store.getters.username }}
                        <br/>
                        rules: {{ $store.getters.rules }}
                    </div>

                    <router-view :key="$route.fullPath" v-on="listeners"></router-view>
                </v-container>
            </v-main>

            <!-- Snack bar -->
            <v-snackbar
                v-model="snackbar.show"
                :color="snackbar.color"
            >
                {{ snackbar.text }}

                <template v-slot:action="{ attrs }">
                    <v-btn
                        text
                        v-bind="attrs"
                        @click="snackbar.show = false"
                    >
                        Chiudere
                    </v-btn>
                </template>
            </v-snackbar>
            <!-- End snack bar -->

            <v-footer app>
                <!-- -->
                © 2024 v1.0327.1
                <!-- -->
                <v-btn v-if="SHOW_DEBUG_BUTTON" text @click="$store.dispatch('toggleDebug')">debug</v-btn>
            </v-footer>
        </v-app>

    </div>
</template>

<script>

export default {

    // Data
    data() {
        return {
            showSite: false,

            SHOW_DEBUG_BUTTON: false,

            debugLogin: false,

            snackbar: {
                show: false,
                color: 'dark',
                text: ''
            },

            menuDrawer: null,
            progressOverlay: false,
            darkMode: false,

            // Listeners
            listeners: {
                'showProgress': () => this.showProgress(),
                'hideProgress': () => this.hideProgress(),
                'showSnack': (msg, color) => this.showSnack(msg, color),
            },

        }
    },

    // Methods
    methods: {
        navigate(route) {
            // Quit if progress screen is showing
            if (this.progressOverlay)
                return 0;
            if (this.$route.name != route) {
                this.$router.push({name: route});
            }
        },
        showProgress() {
            this.progressOverlay = true;
        },
        showSnack(msg, color = 'dark') {
            this.snackbar.text = msg;
            this.snackbar.color = color;
            this.snackbar.show = true;
        },
        hideProgress() {
            this.progressOverlay = false;
        },
        toggleDarkMode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            //this.darkmodeNotification = true;
        },
    },

    // Events
    mounted() {
        // Check login status on server session
        /*
        this.$api.authCheck().then(response => {
          if (response.loggedIn) {
            // Logged in OK
            this.$store.dispatch('login');
          }
        });
        */

        if(this.$env.siteA) {
            this.showSite = true;
            return;
        }

        // Check flag to display page
        this.$axios.post(this.$env.api+'SiteB/', {funct: 'show'}).then(({data}) => {
            if (data.ok) {
                this.showSite = true;
            } else {
                this.showSite = false;
            }      
        });
    },

    // Components
    components: {},

}

</script>

<style>

.header-logo {
    width: 80%;
    margin-bottom: 20px;
}

.v-overlay__content {
    max-height: 90vh;
    overflow-y: scroll;
}

.no-y-scroll .v-overlay__content {
    max-height: 90vh;
    overflow-y: hidden;
}

</style>
