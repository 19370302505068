<template>
    <div>

        <!-- nav -->
        <v-card flat class="mb-0">

            <!-- Show current week and buttons to navigate weeks -->
            <!-- For mobile version, reduce texts -->
            <v-card-actions class="pt-0">
                <v-btn @click="goPreviousWeek" text color="primary">
                    <v-icon class="mr-3">mdi-arrow-left</v-icon>

                    <span v-if="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'">
                        settimana precedente
                    </span>
                </v-btn>
                <v-spacer></v-spacer>
                    <span class="week-date">
                        {{ stringMonday }}/{{ stringFriday }} {{ stringYear }}
                    </span>
                <v-spacer></v-spacer>
                <v-btn @click="goNextWeek" text color="primary">
                    <v-icon class="mr-3">mdi-arrow-right</v-icon>

                    <span v-if="$vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'">
                        prossima settimana
                    </span>
                </v-btn>
            </v-card-actions>

        </v-card>

        <!-- Container for colors legend and filter -->
        <v-container fluid class="py-0">
            <v-row>
                <!-- Colors -->
                <v-col cols="12" md="10" class="pb-0">
                    <v-row class="pb-0">
                        <v-col md="auto" cols="4">
                            <Legend color="red" label="Risorse giornaliere" />
                        </v-col>
                        <v-col md="auto" cols="4">
                            <Legend color="grey" label="Lavorazioni" />
                        </v-col>
                        <v-col md="auto" cols="4">
                            <Legend color="orange" label="Memo" />
                        </v-col>
                        <v-col md="auto" cols="4">
                            <Legend color="green" label="App.Claudio" />
                        </v-col>
                        <v-col md="auto" cols="4">
                            <Legend color="purple" label="App.Patrizio" />
                        </v-col>
                        <v-col md="auto" cols="4">
                            <Legend color="blue" label="App.Nessun agente" />
                        </v-col>
                    </v-row>
                </v-col>
                <!-- Filter -->
                <v-col cols="12" md="2" class="pb-0">
                    <v-select style="height: 50px" v-model="filter" :items="filterItems" outlined dense label="Filtri"></v-select>
                </v-col>
            </v-row>
        </v-container>

        <v-container fluid v-if="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'">
            <v-row>
                <v-col class="text-center">
                    <WeekDayBtn day="L" :date="weekDates.monday" :currentDate="today" @change="setWeekDay" />
                    <WeekDayBtn day="M" :date="weekDates.tuesday" :currentDate="today" @change="setWeekDay" />
                    <WeekDayBtn day="M" :date="weekDates.wednesday" :currentDate="today" @change="setWeekDay" />
                    <WeekDayBtn day="G" :date="weekDates.thursday" :currentDate="today" @change="setWeekDay" />
                    <WeekDayBtn day="V" :date="weekDates.friday" :currentDate="today" @change="setWeekDay" />
                    <WeekDayBtn day="S" :date="weekDates.saturday" :currentDate="today" @change="setWeekDay" />
                    <WeekDayBtn day="D" :date="weekDates.sunday" :currentDate="today" @change="setWeekDay" />
                </v-col>
            </v-row>
        </v-container>

        <template>
            <v-row>
                <v-col>
                    <v-sheet>
                        <v-calendar
                            locale="it"
                            ref="calendar"
                            :value="today"
                            color="accent"
                            first-interval="0"
                            interval-count="0"
                            :type="
                            ($vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm')
                            ?
                            'week'
                            :
                            'day'
                            "
                            event-end="end"
                            :events="events"
                            event-overlap-mode="stack"
                            event-overlap-threshold="30"
                            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                        >

                            <template v-slot:day-header="{ date }">

                                <v-divider></v-divider>

                                <div class="eventContainer resourcesColor"
                                     v-if="$env.siteA && (resource = resources.find(el => el.date==date)) && (filter==0 || filter==1)">
                                    <div v-for="(eachRes, index) in resource.values" v-bind:key="index">
                                        <v-row v-if="eachRes>0" class="eventRow eventRowResources">
                                            <v-col class="lessPadding">{{ resourcesLabels[index] }}</v-col>
                                            <v-col class="text-right lessPadding">{{ eachRes }}

                                                <!-- Hide total amount if Operai (Op) -->
                                                <span v-if="resourcesLabels[index] != 'Op'">
                                                /
                                                {{ resourcesTotals[index] }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>

                                <!-- Show current day events -->
                                <!-- Works -->
                                <div @click="processEvent(event)" class="eventContainer workColor" v-for="event in events.filter(el => {
                                      if (el.date==date && el.type=='work')
                                        return true;
                                      else
                                        return false;
                                    })" v-bind:key="event.id">

                                    <v-row class="eventRow eventRowWork">
                                        <v-col class="lessPadding col-12">

                                            {{ event.hour }} {{ event.name }}

                                            <br/>

                                            <div class="col-12 workMaterials pa-0" v-if="event.resources">
                                                <!-- Resources list -->
                                                <span
                                                    v-for="(resource, index) in event.resources.filter(el => el.value>0)"
                                                    v-bind:key="index">
                                                    {{ resource.name }} {{ resource.value }},
                                                </span>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </div>

                                <!-- Appointments -->
                                <div @click="processEvent(event)"
                                                     :class="'eventContainer appointmentColor-' + event.id_agent" v-for="event in events.filter(el => {
                                      if (el.date==date && el.type=='appointment')
                                        return true;
                                      else
                                        return false;
                                    })" v-bind:key="event.id">

                                    <v-row class="eventRow eventRowAppointment"
                                           v-if="($store.getters.rules.planning.onlyClaudio) ? (event.id_agent=='0') : true">
                                        <v-col class="col-2 lessPadding">
                                            {{ event.hour }}                                            
                                            <span v-if="event.hour_end">
                                                ~
                                                {{ event.hour_end }}
                                            </span>
                                        </v-col>
                                        <v-col class="text-right lessPadding">{{ event.name }}</v-col>
                                    </v-row>
                                </div>

                                <!-- Memo -->
                                <div @click="showMemo(event.id)" class="eventContainer containerMemo" v-for="event in events.filter(el => {
                                      return (el.date==date && el.type=='memo');
                                    })" v-bind:key="event.id">
                                    <v-row class="eventRow">
                                        <v-col class="text-left lessPadding">
                                                {{event.hour}}
                                        </v-col>
                                        <v-col class="text-right lessPadding">{{ event.name }}</v-col>
                                    </v-row>
                                </div>

                            </template>

                        </v-calendar>
                    </v-sheet>
                </v-col>
            </v-row>
        </template>

        <v-overlay v-if="overlayEventAppointment">
            <v-card class="mx-auto" width="800" light>
                <v-card-title>Appuntamento</v-card-title>
                <v-card-text>
                    <v-container>

                        <v-row>
                            <v-col>
                                <b>
                                    Data / ora:
                                </b>
                            </v-col>
                            <v-col>
                                {{ appointment.dateTime }}
                                <span v-if="appointment.time_end">
                                    ~ {{appointment.time_end}}
                                </span>
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <b>
                                    Cliente:
                                </b>
                            </v-col>
                            <v-col>
                                {{ appointment.client }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <b>
                                    Agente:
                                </b>
                            </v-col>
                            <v-col>
                                {{ appointment.agent }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <b>
                                    Descrizione:
                                </b>
                            </v-col>
                            <v-col>
                                {{ appointment.description }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <b>
                                    Stato:
                                </b>
                            </v-col>
                            <v-col>
                                {{ appointment.state }}
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="success" v-if="$store.getters.rules.appointments.edit && !appointment.order_created"
                           @click="$router.push({path: '/appointment/modify/' + appointment.id})">
                        <v-icon>mdi-pencil</v-icon>
                        Appuntamento
                    </v-btn>
                    <v-btn color="success"
                     v-if="$store.getters.rules.clients.edit"
                     @click="$router.push({path: '/client/modify/'+appointment.id_client})"
                     >
                        <v-icon>mdi-account</v-icon>
                        Cliente
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="info" @click="overlayEventAppointment=false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>


        <v-overlay v-if="overlayEventMemo">
            <v-card class="mx-auto" width="800" light>
                <v-card-title>Memo</v-card-title>
                <v-card-text>
                    <v-container>

                        <v-row>
                            <v-col>
                                <b>
                                    Scritto da:
                                </b>
                            </v-col>
                            <v-col>
                                {{ memo.email }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <b>
                                    Titolo:
                                </b>
                            </v-col>
                            <v-col>
                                {{ memo.title }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <b>
                                    Descrizione:
                                </b>
                            </v-col>
                            <v-col>
                                {{ memo.description }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <b>
                                    Creato il:
                                </b>
                            </v-col>
                            <v-col>
                                {{ memo.date_created }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="$router.push({path: '/memo/modify/' + memo.id})">
                        <v-icon>mdi-pencil</v-icon>
                        Modifica
                    </v-btn>
                    <v-btn color="info" @click="overlayEventMemo=false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>

        <v-overlay v-if="overlayEventWork">
            <v-card class="mx-auto" width="800" light>
                <v-card-title>Lavorazione</v-card-title>
                <v-card-text>
                    <v-container>

                        <v-row>
                            <v-col>
                                <b>
                                    Data / ora:
                                </b>
                            </v-col>
                            <v-col>
                                {{ work.dateTime }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <b>
                                    Cliente:
                                </b>
                            </v-col>
                            <v-col>
                                {{ work.client }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <b>
                                    Cellulare:
                                </b>
                            </v-col>
                            <v-col>
                                {{ work.cellphone || '-' }}
                            </v-col>
                        </v-row>                        

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <b>
                                    Tipologia:
                                </b>
                            </v-col>
                            <v-col>
                                {{ work.type }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <b>
                                    Luogo:
                                </b>
                            </v-col>
                            <v-col>
                                {{ work.place }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row v-if="$env.siteA">
                            <v-col>
                                <b>
                                    Note:
                                </b>
                            </v-col>
                            <v-col>
                                {{ work.notes }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <b>
                                    Autorizzazione Vigili Urbani:
                                </b>
                            </v-col>
                            <v-col>
                                {{ (work.police_permission==0) ? '-' : work.police_permission }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row v-if="$env.siteA">
                            <v-col>
                                <b>
                                    Renata:
                                </b>
                            </v-col>
                            <v-col>
                                {{ (work.flag_renata == '1') ? 'Sì' : 'No' }}
                            </v-col>
                        </v-row>

                        <v-divider></v-divider>

                        <v-row>
                            <v-col>
                                <b>
                                    Risorse:
                                </b>
                            </v-col>
                            <v-col v-html="work.resources">

                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>
                <v-card-actions>

                    <v-btn color="success" v-if="$store.getters.rules.workersVehicles.edit"
                        @click="$router.push({path: '/orders/works/modify/' + work.id})">
                        <v-icon>mdi-pencil</v-icon>
                        Lavorazione
                    </v-btn>
                    <v-btn color="success"
                     v-if="$store.getters.rules.clients.edit"
                     @click="$router.push({path: '/client/modify/'+work.id_client})"
                     >
                        <v-icon>mdi-account</v-icon>
                        Cliente
                    </v-btn>
                    <v-btn color="info" class="white--text" v-if="$store.getters.rules.orders.view"
                           @click="$router.push({path: '/orders/' + work.id_order})">
                        <v-icon>mdi-text-box-outline</v-icon>
                        Scheda ordine
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn color="success" @click="overlayEventWork=false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>

        <v-overlay v-model="showMemoOverlay">
            <v-card class="mx-auto" min-width="800" max-width="800" light>
                <v-card-title>
                    {{selectedMemo.title}}
                </v-card-title>
                <v-card-text>
                    <b>Data e ora:</b> {{selectedMemo.date}} {{selectedMemo.time}}
                    <br /><br />
                    {{selectedMemo.description}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="modifyMemo(selectedMemo.id)">Modifica</v-btn>
                    <v-btn color="info" @click="showMemoOverlay=false">Chiudi</v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>

        <v-overlay v-model="editMemoOverlay">
            <v-card class="mx-auto" min-width="800" max-width="800" light>
                <v-card-title>
                    Modifica memo
                </v-card-title>
                <v-card-text>
                        <v-form ref="formMemo">
                        <v-text-field
                            v-model="selectedMemo.title"
                            :rules="rules.required"
                            label="Titolo*"
                            required
                        ></v-text-field>

                        <template>
                            <v-menu
                                ref="calendarMenu"
                                v-model="calendarMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="selectedMemo.date"
                                        label="Data*:"
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="rules.required && rules.date" 
                                        required
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                ref="picker"
                                v-model="dateOriginalFormat"
                                min="1950-01-01"
                                @change="calendarSave"
                                locale="it"
                                :first-day-of-week="1"
                                ></v-date-picker>
                            </v-menu>
                        </template>

                        <v-text-field
                            v-model="selectedMemo.time"
                            label="Ora*:"
                            v-bind="attrs"
                            v-on="on"
                            :rules="rules.required && rules.hour"
                            required
                        ></v-text-field>

                        <v-textarea
                            v-model="selectedMemo.description"
                            label="Contenuto">
                        </v-textarea>
                        </v-form>
                        <h4>I campi contrassegnati * sono obbligatori.</h4>  
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="editMemoOverlay=false">Annulla</v-btn>
                    <v-btn color="success" @click="confirmModify(selectedMemo.id)">Salva</v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>

    </div>
</template>

<script>
import Legend from '@/components/planning/Legend'
import WeekDayBtn from '@/components/planning/WeekDayBtn'

export default {

    components: {Legend, WeekDayBtn},

    data() {
        return {
            rules: {
                required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
                hour: [ value => (!/^([0-1]?[0-9]a2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true ],
                date: [ value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true ],
            },

            // Days of week for mobile daily calendar
            weekDates: {                
                monday: null,
                tuesday: null,
                wednesday: null,
                thursday: null,
                friday: null,
                saturday: null,
                sunday: null,
            },

            // Memo
            date: null,
            calendarMenu: false,
            dateOriginalFormat: '',
            hourMenu: false,
            on: null,
            attrs: null,

            selectedMemo: {
                title: null,
                description: null,
                date: null,
                time: null
            },
            showMemoOverlay: false,
            editMemoOverlay: false,

            filter: 0,
            filterItems: [
                {text: 'Tutto', value: 0},
                {text: 'Lavorazioni', value: 1},
                {text: 'Appuntamenti', value: 2},
                {text: 'Memo', value: 3},
            ],

            today: null,
            overlayEventAppointment: false,
            overlayEventWork: false,
            overlayEventMemo: false,

            appointment: {
                dateTime: null,
                time_end: null,
                client: null,
                agent: null,
                description: null,
                state: null,
            },

            memo: {
                user_id: null,
                title: null,
                description: null,
                date_created: null,
                email: null,
            },

            eventsRaw: [],

            work: {
                dateTime: null,
                client: null,
                type: null,
                place: null,
                notes: null,
                police_permission: null,
                resources: null,
            },

            resourcesLabels: [],
            resourcesTotals: [],

            months: [
                'gennaio',
                'febbraio',
                'marzo',
                'aprile',
                'maggio',
                'giugno',
                'luglio',
                'agosto',
                'settembre',
                'ottobre',
                'novembre',
                'dicembre'
            ],

            resources: [
                /*
                {
                  date: '2021-04-27',
                  values: [
                    {id:'1', ammount:'5'},
                    {id:'3', ammount:'17'},
                  ]
                },
                {
                  date: '2021-04-28',
                  values: [
                    {id:'8', ammount:'4'},
                    {id:'7', ammount:'25'},
                  ]
                },
                */
            ],

            // ITEMS
            itemsState: [
                'In trattativa',
                'Confermato',
                'Fallito'
            ],
            itemsAgents: [
                'Nessun Agente',
                'Patrizio Ponzo',
                'Claudio D’Auria',
                'Antonio Amodio',
            ],
            itemsWorkType: [],

        }
    },

    methods: {

        calendarSave (date) {
            this.$refs.calendarMenu.save(date)
        },    

        goNextWeek() {
            let dateToParse = this.today;
            let dateSplitted = dateToParse.split(' ')[0].split('-');
            let nextWeek = new Date(dateSplitted[0], dateSplitted[1] - 1, dateSplitted[2], '8');
            nextWeek.setDate(nextWeek.getDate() + 7);
            this.today = nextWeek.getFullYear() + '-' + (nextWeek.getMonth()+1) + '-' + nextWeek.getDate() + ' 08:00';
            this.getWeek();
        },

        goPreviousWeek() {
            let dateToParse = this.today;
            let dateSplitted = dateToParse.split(' ')[0].split('-');
            let prevWeek = new Date(dateSplitted[0], dateSplitted[1] - 1, dateSplitted[2], '8');
            prevWeek.setDate(prevWeek.getDate() - 7);
            this.today = prevWeek.getFullYear() + '-' + (prevWeek.getMonth() + 1) + '-' + prevWeek.getDate() + ' 08:00';
            this.getWeek();
        },

        getWeek() {
            // Adjust to IOS format
            let dateToParse = this.today;            
            let dateSplitted = dateToParse.split(' ')[0].split('-');

            let date = new Date(dateSplitted[0], dateSplitted[1] - 1, dateSplitted[2], '8');            
            let lastMondayDate = new Date(dateSplitted[0], dateSplitted[1] - 1, dateSplitted[2], '8');
            lastMondayDate.setDate(date.getDate() + (1 - date.getDay()));           

            let monday = lastMondayDate.getFullYear() + '-' + (lastMondayDate.getMonth() + 1) + '-' + lastMondayDate.getDate();  // 2021-4-30

            // Set week dates for mobile daily calendar
            let weekDates = [];
            for (let n = 1; n<7; n++) {
                let dayDate = new Date(lastMondayDate);
                dayDate.setDate(lastMondayDate.getDate() + n);
                weekDates[n + 1] = dayDate.getFullYear() + '-' + (dayDate.getMonth() + 1) + '-' + dayDate.getDate() + ' 08:00';
            }

            this.weekDates.monday = monday + ' 08:00';
            this.weekDates.tuesday = weekDates[2];
            this.weekDates.wednesday = weekDates[3];
            this.weekDates.thursday = weekDates[4];
            this.weekDates.friday = weekDates[5];
            this.weekDates.saturday = weekDates[6];
            this.weekDates.sunday = weekDates[7];
                        
            //Old format
            /*
            let date = new Date(this.today);
            let lastMondayDate = new Date(this.today);
            lastMondayDate.setDate(lastMondayDate.getDate() + (1 - date.getDay()));

            let monday = lastMondayDate.getFullYear() + '-' + (lastMondayDate.getMonth() + 1) + '-' + lastMondayDate.getDate();  // 2021-4-30
            */        
            this.$api.calendar.getWeek(monday).then(data => {

                this.resourcesLabels = [];
                this.resourcesTotals = [];

                data.values.resourcesLabels.forEach(el => {
                    this.resourcesLabels[el.id] = el.name;
                    this.resourcesTotals[el.id] = el.ammount;
                });

                this.resources = data.values.resources;

                // Reset events
                this.eventsRaw = [];

                // Add works events
                data.values.works.forEach(el => {
                    // Adjust date string
                    let jsDate = el.date.split('-').reverse().join('-');

                    let newWork = {
                        id: el.id,
                        name: this.$options.filters.clientName(el, true),
                        start: jsDate + ' ' + el.hour,
                        date: jsDate,
                        hour: el.hour,
                        type: 'work',
                        resources: el.resources,
                    };
                    this.eventsRaw.push(newWork);
                });

                // Add appointments events
                data.values.appointments.forEach(el => {
                    // Adjust date string
                    let jsDate = el.date.split('-').reverse().join('-');

                    let newAppointment = {
                        id: el.id,
                        name: this.$options.filters.clientName(el),
                        date: jsDate,
                        start: jsDate + ' ' + el.time,
                        end: (el.time_end) ? jsDate + ' ' + el.time_end : jsDate, // If end time is set, add to event
                        hour: el.time,
                        hour_end: el.time_end,
                        type: 'appointment',
                        id_agent: el.id_agent
                    };
                    this.eventsRaw.push(newAppointment);
                });

                // Add memo events
                data.values.memos.forEach(el => {
                    // Adjust date string
                    let jsDate = el.date.split('-').reverse().join('-');

                    let newMemo = {
                        id: el.id,
                        name: el.title,
                        date: jsDate,
                        start: jsDate + ' ' + el.time,
                        hour: el.time,
                        type: 'memo',
                    };
                    this.eventsRaw.push(newMemo);
                });

            });
        },

        processEvent(event) {
            if (event.type == 'appointment')
                this.showAppointment(event.id);
            else if (event.type == 'work')
                this.showWork(event.id);
        },


        showAppointment(id) {
            this.$emit('showProgress');

            this.$api.calendar.getAppointment(id).then(data => {
                this.$emit('hideProgress');

                let ap = data.values.appointment[0];

                this.appointment = {
                    id_client: ap.id_client,
                    id: id,
                    dateTime: ap.date + ' ' + ap.time,
                    time_end: ap.time_end,
                    client: this.$options.filters.clientName(ap, true),
                    agent: this.itemsAgents[ap.id_agent],
                    description: ap.description,
                    state: this.itemsState[ap.status],
                    order_created: ap.order_created,
                };

                this.overlayEventAppointment = true;
            });
        },

        showWork(id) {
            this.$emit('showProgress');

            this.$api.calendar.getWork(id).then(data => {
                this.$emit('hideProgress');
                let work = data.values.work[0];
                let place = data.values.place;

                let newPlace = place.address + ', ' + place.city + ' ' + place.zip;

                let newResources = '';

                // Write resources
                data.values.resources.forEach(el => {
                    if (el.value > 0)
                        newResources += '<b>' + el.name + ':</b> ' + el.value + '<br />';
                });

                let newWork = {
                    id,
                    id_client: work.id_client,
                    dateTime: work.date + ' ' + work.hour,
                    client: this.$options.filters.clientName(work, true),
                    cellphone: work.cellphone,
                    type: this.itemsWorkType[work.type],
                    place: newPlace,
                    notes: work.notes,
                    police_permission: work.police_permission,
                    flag_renata: work.flag_renata,
                    resources: newResources,
                    id_order: work.id_order
                };

                this.work = newWork;

                /*
                        work: {
                          dateTime: null,
                          client: null,
                          type: null,
                          place: null,
                          notes: null,
                          police_permission: null,
                          resources: null,
                        },
                */

                this.overlayEventWork = true;

            });
        },

        checkFilters(section) {

            // Check filters
            if (section == 'PlanningWorks')
                this.filter = 1;

            if (section == 'PlanningAppointments')
                this.filter = 2;

            if (section == 'PlanningMemo')
                this.filter = 3;
        },

        showMemo(id) {
            this.$emit('showProgress');
            this.$api.memo.getFromId(id).then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.selectedMemo = data.values.memo;
                this.showMemoOverlay = true;
            });
        },

        modifyMemo(id) {
            this.$emit('showProgress');
            this.showMemoOverlay = false;
            this.$api.memo.getFromId(id).then(data => {
                this.$emit('hideProgress');
                this.selectedMemo = data.values.memo;

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.editMemoOverlay = true;
            });
        },

        confirmModify() {
            if (!this.$refs.formMemo.validate())
                return;

            this.$emit('showProgress');
            this.$api.memo.updateMemo(this.selectedMemo).then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.$emit('showSnack', 'Memo aggiornato', 'success');
                this.editMemoOverlay = false;
                this.getWeek();
            });
        },

        // Change day of week in mobile calendar
        setWeekDay(day) {
            this.today = day;
        },

        // Load work types from DB
        getWorkTypes() {
            this.$axios.post(this.$env.api+'Works/', {funct: 'getWorkTypes'}).then(({data}) => {
                if (data.ok) {
                    this.itemsWorkType = data.values.workTypes.map(el => el.text);
                }                    
            });
        }
    },

    watch: {
        // Date memo
        dateOriginalFormat(val) {
          this.selectedMemo.date = val.split('-').reverse().join('-');
        },

        '$route.name': {
            handler: function (section) {
                this.checkFilters(section);
            }
        },
        /*
            '$route.name'(section => {
              alert(section);
              this.checkFilters(section);
            },
            */

    },

    // Events
    mounted() {
        // Get work types
        this.getWorkTypes();

        // Get dates
        let date = new Date();
        this.today = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' 08:00';  // 2021-4-30 08:00

        this.checkFilters(this.$route.name);

        this.getWeek();
    },

    computed: {

        events() {
            // Filters

            // Only works
            if (this.filter == 1)
                return this.eventsRaw.filter(el => {
                    if (el.type == 'work')
                        return true;
                    else
                        return false;
                });

            // Only appointments
            if (this.filter == 2)
                return this.eventsRaw.filter(el => {
                    if (el.type == 'appointment')
                        return true;
                    else
                        return false;
                });

            // Only memo
            if (this.filter == 3)
                return this.eventsRaw.filter(el => {
                    if (el.type == 'memo')
                        return true;
                    else
                        return false;
                });

            // Default. Show all
            return this.eventsRaw;
        },

        stringMonday() {
            // Adjust date to IOS
            // this.today = yyyy-m-g hh:mm
            if (!this.today)
                return '';

            let dateToParse = this.today;
            let dateSplitted = dateToParse.split(' ')[0].split('-');
            let date = new Date(dateSplitted[0], dateSplitted[1] - 1, dateSplitted[2], '8');            
            date.setDate(date.getDate() + (1 - date.getDay()));            

            return this.months[parseInt(date.getMonth())] + ' ' + (date.getDate());  // 2021-4-30
        },

        stringFriday() {
            if (!this.today)
                return '';

            let dateToParse = this.today;
            let dateSplitted = dateToParse.split(' ')[0].split('-');
            let date = new Date(dateSplitted[0], dateSplitted[1] - 1, dateSplitted[2], '8');
            date.setDate(date.getDate() + (5 - date.getDay()));
            return this.months[parseInt(date.getMonth())] + ' ' + (date.getDate());  // 2021-4-30
        },

        stringYear() {
            let date = new Date();
            return date.getFullYear();
        },

    }

}

</script>

<style>

.week-date {
    font-size: 100%;
}

@media (max-width:768px) {
    .week-date {
        font-size: 80%;
    }
}

.eventContainer {
    font-size: 90%;
    cursor: pointer;
}

.eventRow {
    margin: 0px !important;
}

.eventRowResources {
    border-bottom: 1px solid #be1a1f;
}

.eventRowWork {
    border-bottom: 1px solid #777;
}

.eventRowAppointment {
    border-bottom: 1px solid rgb(0, 114, 0);
}

.resourcesColor {
    background-color: #ec1c24;
    color: #fff;
}

.workColor {
    background-color: #999;
    color: #fff;
}

/* No agent */
.appointmentColor-0 {
    background-color: #00aeef;
    color: #fff;
}

/* Agent Patrizio */
.appointmentColor-1 {
    background-color: #800080;
    color: #fff;
}

/* Agent claudio */
.appointmentColor-2 {
    background-color: #090;
    color: #fff;
}

.eventAppointment {
    font-size: 90%;
    height: 50px;
    background-color: #090;
    color: #fff;
    border: 1px solid #070;
}

.eventWork {
    font-size: 90%;
    height: 50px;
    background-color: #999;
    border: 1px solid #777;
    color: #fff;
}

.lessPadding {
    font-size: 100%;
    padding: 3px 6px !important;
}

.workMaterials {
    font-size: 85%;
}

.containerMemo {
    background-color: #ff9800;
    color: #fff;
    border: 1px solid #ffb488;
}

.v-calendar-daily__intervals-head {
    display: none;
}

/* Hide left panel and header for mobile daily calendar */
@media (max-width: 920px) {
    .v-calendar-daily_head-weekday {
        display: none;
    }

    .v-calendar-daily_head-day-label {
        display: none;
    }
}

</style>
