<template>
  <div>  

    <br />

    <v-subheader><h1 class="accent--text">Ordini</h1></v-subheader>

      <div v-if="orders">
        <v-container>
          <v-row>
            <v-col>

                <v-data-table
                  :headers="headers"
                  :items="orders"
                  :items-per-page="25"
                  class="elevation-5"
                >

                  <template v-slot:item.id="{ item }">
                    #{{item.id}}
                  </template>

                  <template v-slot:item.client="{ item }">
                    {{item | clientName}}
                  </template>

                  <template v-slot:item.actions="{ item }">   

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="$router.push({path: '/orders/'+item.id})" class="mr-3" color="info" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-wrench</v-icon></v-btn>
                      </template>
                      <span>Gestire ordine</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="$store.getters.rules.orders.edit || ($store.getters.rules.orders.deleteRenata && item.flag_renata==1)">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="error" @click="showDeleteOrderDialog(item.id)" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
                      </template>
                      <span>Elimina lavorazione</span>
                    </v-tooltip>

                    <!-- <v-btn color="error" x-small fab><v-icon>mdi-delete</v-icon></v-btn> -->
                  </template>  

                </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-overlay v-model="overlayConfirmDeleteOrder">
        <v-card light min-width="600">
          <v-card-title>Elimina ordine</v-card-title>
          <v-card-text>
            Clicca "conferma" per eliminare l'ordine
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="overlayConfirmDeleteOrder = false">Annulla</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="confirmDeleteOrder">Conferma</v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay> 

  </div>
</template>

<script>

export default {
 
  data() {
    return {

      deleteOrderId: false,
      overlayConfirmDeleteOrder: false,

      headers: [
        {text:'Numero', value:'id'},
        {text:'Cliente', value:'client', sortable: false},
        {text:'Data creazione ordine', value:'date_created'},
        {text:'Azioni', value:'actions'},
      ],

      orders: [],

    }
  },

  components: {
    //NcOrderCard
  },

  methods: {

    showDeleteOrderDialog(id) {
      this.deleteOrderId = id;
      this.overlayConfirmDeleteOrder = true;
    },

    confirmDeleteOrder() {
      this.$emit('showProgress');

      this.$api.orders.deleteOrder(this.deleteOrderId).then(data => {
        this.$emit('hideProgress');
        if (data.ok) {
          this.overlayConfirmDeleteOrder = false;
          this.getIndex();
          this.$emit('showSnack', 'Ordine eliminato', 'success');
        } else {
          this.$emit('showSnack', data.values.msg, 'error');
        }
      }); 
    },

    getIndex() {
      this.$emit('showProgress');
      this.$api.orders.getIndex().then(data => {   
          this.orders = data.values.orders;
          this.$emit('hideProgress');
      });
    },

  },

  // Events
  mounted() {
    this.getIndex();
  },

}

</script>