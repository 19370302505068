<template>
  <div>

    <v-subheader><h1 class="accent--text">Storico movimenti deposito</h1></v-subheader>

    <div class="container mt-6">
        <template v-if="data">
            <v-data-table
                :headers="headers"
                :items="data"
                :items-per-page="20"
                class="elevation-1"
            >
                <template v-slot:item.order="{item}">
                    <span v-if="item.id_order">
                        #{{item.id_order}} - {{item.surname}}, {{item.name}}
                    </span>
                    <span v-else>
                        -
                    </span>
                </template>
                <template v-slot:item.used="{item}">
                    {{item.used}} %
                </template>
                <template v-slot:item.files="{item}">

                    <div v-if="item.files.length>0">
                        <div v-for="(file, index) in item.files" :key="index">
                            <a :href="'/api/uploads/box/'+file" target="_blank">
                                {{file}}
                            </a>
                            <br />
                        </div>
                    </div>
                    <div v-else>
                        -
                    </div>
                </template>
            </v-data-table>            
        </template>

    </div>    

  </div>
</template>

<script>
export default {

    data() {
        return {
            data: null,

            headers: [
                {text: 'Ordine', value: 'order'},
                {text: 'Titolo', value: 'title'},
                {text: 'Percentuale uso', value: 'used'},
                {text: 'Data', value: 'date'},
                {text: 'Documenti', value: 'files'},
            ],
        }
    },

    methods: {
        getData() {
            this.$emit('showProgress');
            this.$api.box.getLog().then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.data = data.values.data;
            });
        },
    },

    mounted() {
        this.getData();
    }

}
</script>

<style>

</style>