<template>
  <div>

      <v-card class="mx-auto" min-width="800" :light="!this.$vuetify.theme.dark">
        <v-card-title>
            {{ create ? 'Nuovo' : 'Modifica' }} luogo di carico
        </v-card-title>
        <v-card-text>

            <v-form ref="form" lazy-validation>
                <v-container>
                  <v-row>
                      <v-col cols="12">
                          <v-text-field v-model="values.address" :rules="[$rules.required]" label="Indirizzo*:"></v-text-field>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="7">
                          <v-autocomplete v-model="values.cityId" :rules="[$rules.required]" cache-items :search-input.sync="search" label="Comune*:" :items="items" @change="setLocationInfo()"></v-autocomplete>
                      </v-col>
                      <v-col cols="2">
                          <v-text-field v-model="values.zip" :rules="[$rules.required]" label="CAP*:"></v-text-field>
                      </v-col>
                      <v-col cols="3">
                          <v-text-field v-model="values.province" label="Provincia:"></v-text-field>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col>
                        <v-textarea v-model="values.notes" label="Note:">
                        </v-textarea>
                      </v-col>
                  </v-row>
                </v-container>

                <h4>I campi contrassegnati * sono obbligatori.</h4>                   
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="cancel()">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirm()">Conferma</v-btn>
        </v-card-actions>
      </v-card>

  </div>
</template>

<script>
export default {

    props: {
        // Create or modify model
        create: {
            type: Boolean,
            default: true,
        },

        // If create, user to add location
        userId: {
            default: null,
        },

        // If modify, model ID
        id: {
            default: null,
        }
    },

    data() {
        return {
            
            // Form input
            values: {
                address: null,                
                city: null,
                zip: null,
                province: null,
                notes: null,
                cityId: null, // Value only used on front-end
            },

            // holds comune information
            items: [
            ],

            // Value to be watched by autocomplete function
            search: null,

            // Checks if is autocomplete is loading new items
            isLoading: false,

        }
    },

    methods: {

        /**
         * Loads model data
         */
        getData(id) {
            this.$axios.post(this.$env.api+'Client/', {funct: 'getPickupFromId', id}).then(({data}) => {
                if (data.ok) {
                    console.log(data);
                    let values = data.values.pickup;

                    // Add defined city value as item
                    values.cityId = -1;

                    this.items = [
                        {value: -1, text: values.city}
                    ];

                    this.values = values;
                }                    
                return;
            });
        },

        /**
         * Triggered after selecting in autoselect in order to get (if possible) info about cap, province, etc.
         */
        setLocationInfo() {        
            if (!this.values.cityId)
                return;

            let locationInfo = this.items.find(el => el.value==this.values.cityId);

            if (!locationInfo)
                return;

            this.values.zip = locationInfo.cap;
            this.values.province = locationInfo.province;
            this.values.city = locationInfo.comune;
        },

        // Cancel form
        cancel() {
            this.$emit('cancel');
        },

        // Save model and notify parent
        confirm() {
            if (!this.$refs.form.validate())
                return;

            if (this.create) {
                this.$axios.post(this.$env.api+'Client/', {funct: 'addPickupPlace', id: this.userId, values: this.values}).then(({data}) => {
                    if (data.ok)
                        this.$emit('confirm');
                    return;
                });
            } else {
                this.values.id = this.id;
                this.$axios.post(this.$env.api+'Client/', {funct: 'updatePickup', values: this.values}).then(({data}) => {
                    if (data.ok)
                        this.$emit('confirm');
                    return;
                });    
            }
            
        },

    },

    created() {
        if (!this.create)
            this.getData(this.id);
    },

    watch: {

        // Dynamically get list of comune from DB
        search (query) {
            // Items have already been requested
            if (this.isLoading)
                return

            this.isLoading = true

            // Lazily load input items
            this.$axios.post(this.$env.api+'Comune/', {funct: 'search', query}).then(({data}) => {
                this.items = data.values.items;
            })
            .finally(() => {
                this.isLoading = false;
            });
        },

    },

}
</script>

<style>

</style>