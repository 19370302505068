<template>
  <div>

      <v-card class="mx-auto" min-width="500" max-width="500" :light="!this.$vuetify.theme.dark">
        <v-card-title>
            Condividi memo
        </v-card-title>
        <v-card-text class="pl-6 pt-3">

            <h3>Scegli uno o più utenti.</h3>

            <br />

            <v-container>                    
                <v-row >
                    <v-col class="username-col">
                        <v-checkbox
                            class="ma-0"
                            v-for="(user, index) in users" :key="index"
                            v-model="selectedUsers"
                            color="success"
                            multiple
                            :value="user.value"
                            :label="user.text"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-container>

        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="cancel()">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="confirm()" :disabled="selectedUsers.length==0 || loading">Condividi</v-btn>
        </v-card-actions>
      </v-card>

  </div>
</template>

<script>
export default {

    props: {
        id: Number
    },

    data() {
        return {            
            users: [],
            selectedUsers: [],

            loading: false,
        }
    },

    methods: {

        confirm() {
            if (this.selectedUsers.length == 0) {
                this.cancel();
                return;
            }

            this.loading = true;

            // Share memo
            this.$axios.post(this.$env.api+'Memo/', {funct: 'share', id: this.id, users: this.selectedUsers}).then(({data}) => {                
                if (data.ok) {
                    this.loading = false;
                    this.$emit('confirm');
                    return;
                }
            });
        },

        cancel() {
            this.$emit('cancel');
        },

        getUsers() {
            this.$axios.post(this.$env.api+'Account/', {funct: 'getAll'}).then(({data}) => {                
                if (data.ok) {
                    // Parse all users except current one and superadmin
                    data.values.accounts.forEach(user => {
                        if (user.id != this.$store.getters.user.id || user.id!=4) {
                            this.users.push({value: user.id, text: user.email});
                        }
                    });
                }                    
                return;
            });
        }

    },

    created() {
        this.getUsers();
    },

}
</script>

<style>

.username-col {
    font-size: 130% !important;
}

</style>