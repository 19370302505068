<template>
  <div>  

    <v-subheader><h2>Creare nuovo account</h2></v-subheader>

    <!-- Register -->

    <v-alert class="mx-auto" max-width="800" type="error" v-model="error.show">{{error.message}}</v-alert>
    <v-alert class="mx-auto" max-width="800" type="info" v-model="success.show">{{success.message}}</v-alert>

    <v-card class="mx-auto" max-width="800" v-if="showForm">
        <v-card-text>
            <v-form ref="form" v-model="values.form">
                <v-text-field label="Nome:" :rules="rules.required" v-model="values.name"></v-text-field>
                <v-text-field label="Cognome:" :rules="rules.required" v-model="values.surname"></v-text-field>
                <v-text-field label="E-mail:" :rules="rules.required" v-model="values.email"></v-text-field>
                <v-text-field label="Telefono:" :rules="rules.required" v-model="values.cellphone"></v-text-field>
                <v-select v-model="values.location" :rules="rules.required" label="Condominio" :items="availableLocations"></v-select>
                Tutti i campi sono obbligatori
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" @click="createAccount">Creare account</v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>

  </div>
</template>

<script>

export default {
  
  data() {
    return {
        availableLocations: [],
        showForm: true,
        error: {
          show: false,
          message: '',
        },
        success: {
          show: false,
          message: '',
        },
        values: {
          form: null,
          name: null,
          surname: null,
          email: null,
          cellphone: null,
          location: null,
        },

        rules: {
          required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
        },

      }
  },

  methods: {

    createAccount() {
      if (!this.$refs.form.validate())
        return;
      
      this.$emit('showProgress');

      this.$api.accounts.registerNew(this.values).then(data => {

        if (data.ok) {
          this.success.message = 'L\'account è in attesa di approvazione dell\'amministratore. Se la richiesta è accettata riceverà un\'e-mail con la sua password.';
          this.showForm = false;
          this.success.show = true;
        } else {
          this.error.message = data.values.msg;
          this.error.show = true;
        }

        this.$emit('hideProgress');
      });

      
    },

  },

  // Events
  mounted() {
    // Load locations
    this.$api.locations.getAll().then(data => {

      this.availableLocations = [];
      
      data.values.location.forEach(el => {
        this.availableLocations.push({
          text: `${el.name} - ${el.address}, ${el.city}`,
          value: el.id,
          });
      });

    });
  },

}

</script>