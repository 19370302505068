<template>
    <div>

        <br />

        <v-container>
            <v-row>
                <v-col>
                    <v-subheader><h1 class="accent--text">Elenco Log Automezzi</h1></v-subheader>


                    <div v-if="!isEmpty(logVehicle)">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <template>
                                        <v-data-table
                                            :headers="headers"
                                            :items="logVehicle"
                                            :items-per-page="10"
                                            class="elevation-5"
                                        >

                                            <template v-slot:[`item.id`]="{ item }">
                                                {{item.id}}
                                            </template>

                                            <template v-slot:[`item.name`]="{ item }">
                                                {{item.name}}
                                            </template>

                                            <template v-slot:[`item.plate`]="{ item }">
                                                {{item.plate}}
                                            </template>

                                            <template v-slot:[`item.intervention_type`]="{ item }">
                                                {{ truncateString(item.intervention_type, 45) }}
                                            </template>

                                            <template v-slot:[`item.description`]="{ item }">
                                                {{ truncateString(item.description, 100)}}
                                            </template>

                                            <template v-slot:[`item.date_created`]="{ item }">
                                                {{ item.date_created }}
                                            </template>

                                            <template v-slot:[`item.actions`]="{ item }">


                                                 <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="mr-3" color="info" @click="$router.push({path: '/logVehicle/show/'+item.id})" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-text-box-outline</v-icon></v-btn>
                                                    </template>
                                                    <span>Vedi log veicolo</span>
                                                </v-tooltip>

                                                  <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="mr-3" color="success" @click="$router.push({path: '/logVehicle/modify/'+item.id})" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
                                                    </template>
                                                    <span>Modifica log veicolo</span>
                                                </v-tooltip>

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn color="error" @click="deleteLogVehicle(item.id)" x-small fab v-bind="attrs" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
                                                    </template>
                                                    <span>Eliminare log veicolo</span>
                                                </v-tooltip>

                                            </template>


                                        </v-data-table>
                                    </template>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                    <!-- fine logVehicle  -->
                    <div v-else>
                        <v-container class="box-no-vehicle">
                            <v-row>
                                <v-col>
                                    <div class="d-flex justify-center align-center flex-column">
                                        <div class="box-title">
                                            <p>Non ci sono ancora dati</p>
                                        </div>
                                        <div class="box-btn">
                                            <v-btn color="primary" @click="$router.push({path: '/logVehicle/insert'})" class="btn-no-memo"><v-icon>mdi-plus</v-icon>Aggiungi dati</v-btn>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <!-- Delete -->
        <v-overlay v-model="deleteLogVehicleOverlay">
            <v-card class="mx-auto" max-width="800" light>
                <v-card-title>
                    Elimina log veicolo
                </v-card-title>
                <v-card-text>
                    Sei sicuro di voler eliminare questa log veicolo?
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="deleteLogVehicleOverlay = false">No</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="confirmDeleteLogVehicle">Si</v-btn>
                </v-card-actions>
            </v-card>
        </v-overlay>

    </div>
</template>

<script>
export default {

    data() {
        return {

            deleteLogVehicleOverlay: false,
            deleteAppointmentId: false,

            headers: [
                {text: 'id', value: 'id'},
                {text: 'Automezzo ', value: 'name'},
                {text: 'Targa ', value: 'plate'},
                //{text: 'Autore', value: 'user_id'},
                {text: 'Tipologia intervento', value: 'intervention_type'},
                {text: 'Descrizione', value: 'description'},
                //{text: 'Data', value: 'date'},
                {text: 'Data completa', value:'date_created'},
                {text: 'Azioni', value:'actions'},
            ],

            logVehicle: null,

        }
    },

    methods: {
        truncateString(str, num) {
            if (num > str.length) {
                return str;
            } else {
                str = str.substring(0, num);
                return str + "...";
            }

        },

        getAll() {
            this.$emit('showProgress');
            this.$api.logVehicle.getAll().then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                this.logVehicle = data.values.logVehicle;
            });
        },

        getLogVehicleFromUser() {
            this.$emit('showProgress');
            this.$api.logVehicle.getLogVehicleFromUser().then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;
                }

                console.log(data.values.logVehicle);

                this.logVehicle = data.values.logVehicle;
            });
        },


        // Delete logVehicle
        deleteLogVehicle(id) {
            this.deleteLogVehicleId = id;
            this.deleteLogVehicleOverlay = true;
        },

        confirmDeleteLogVehicle() {
            this.$api.logVehicle.deleteLogVehicle(this.deleteLogVehicleId).then(data => {
                if (!data.ok) {
                    this.$emit('showSnack', 'Error connecting to database', 'error');
                    return;
                }

                // Update logVehicle list from id
                this.getLogVehicleFromUser();

                this.deleteLogVehicleOverlay = false;

                this.$emit('hideProgress');
                this.$emit('showSnack', 'Memo eliminata', 'success');
            });
        },

        isEmpty(str) {
            return (!str || str.length === 0 );
        },
    },

    mounted() {
        this.getLogVehicleFromUser();
    },
}
</script>

<style scoped>
.box-no-vehicle {
    margin-top: 50px;
}

.box-no-vehicle p {
    font-size: 18px;
    margin: 0;
}

.box-no-vehicle .box-btn {
    margin-top: 20px;
}
</style>
