<template>
  <div>  

    <v-subheader><h2>Chiudere sessione</h2></v-subheader>

    <br />

    <v-card class="mx-auto" max-width="800">
      <v-card-text class="text-center">
        <br />
        <h4>Premi il pulsante per chiudere la sessione</h4>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="accent"
          @click="logout"
        >
          Logout
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>

export default {
  
  data() {
    return {
      
      }
  },

  methods: {
      logout() {
        this.$api.authLogout()
            .then(response => {
                if (response=='OK') {
                  this.$emit('showSnack', 'La sessione è stata chiusa', 'success');
                    this.$store.dispatch('logout');
                    this.$router.push({name: 'Home'});
                } else {
                    // Logout not OK
                }
            });
      }
  },

}

</script>