<template>
  <div>  

    <v-container fluid>
      <v-row>
        <v-col cols="12" md="3">

          <!-- Input date card -->
          <v-card class="mx-auto" max-width="1000" flat>   
              <v-card-text>
                <v-form ref="searchForm" @submit="getWorks">
                  <v-text-field dense :rules="rules.required && rules.date" v-model="date" label="Inserisci una data (GG/MM/AAAA):" placeholder="GG/MM/AAAA"></v-text-field>
                  <v-btn color="accent" @click="getWorks">Cerca</v-btn>
                </v-form>
              </v-card-text>
            </v-card>

            <!-- Select workers and vehicles table -->
            <v-row v-if="works.length>0">

              <v-col cols="6" class="pr-0 workers-vehicles-left-list">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <!-- Show title + total green workers and total workers requested for the current day -->
                      <td colspan="2" class="darkBorderRight"><h4>Operai 
                      {{usedWorkers.reduce((workers, el) => workers += (el.timesUsed>0) ? 1 : 0, 0)}}
                      /
                      {{this.works.reduce((workers, el) => workers + parseInt(el.requiredWorkers), 0)}}</h4>
                      </td>
                    </tr>

                    <template v-for="(worker, index) in workers">  
                      <!-- Leave white space between lines -->
                      <tr v-if="workersWhitespace.indexOf(worker.id_frontend) != -1" v-bind:key="index+'-whitespace'">
                          <td class="whitespace">
                          </td>
                          <td class="whitespace">
                          </td>
                      </tr>
                      <tr 
                        v-if="worker.deleted==0"                        
                        v-bind:key="index+'-worker'"
                        :class="[
                          (usedWorkers.find(el => el.id == worker.value) && usedWorkers.find(el => el.id == worker.value).timesUsed == 1) ? 'usedWorker' : '',
                          (usedWorkers.find(el => el.id == worker.value) && usedWorkers.find(el => el.id == worker.value).timesUsed == 2) ? 'usedWorker-1' : '',
                          (usedWorkers.find(el => el.id == worker.value) && usedWorkers.find(el => el.id == worker.value).timesUsed > 2) ? 'usedWorker-2' : '',
                        ]"
                      >              
                        <td v-if="!worker.justForTheDay"
                          :class="
                            'id-frontend-cell-' + getTheme() + ' leftListCell text-center leftListBorderRight'
                            "
                            width="15%"
                        >
                          {{ worker.id_frontend }}             
                        </td>
                        <td v-if="!worker.justForTheDay" class="leftListCell pl-2 darkBorderRight">
                          {{ worker.text }}<span v-if="debug.showTimesUsed && usedWorkers.find(el => el.id == worker.value)">({{ usedWorkers.find(el => el.id == worker.value).timesUsed || '0' }})</span>
                        </td>              

                        <!-- Just for the day -->
                        <td v-if="worker.justForTheDay" :class="'id-frontend-cell-' + getTheme() + ' leftListCell text-center leftListBorderRight'" width="15%">
                          {{ worker.id_frontend }}                    
                        </td>
                        <td v-if="worker.justForTheDay" class="leftListCell pl-2 darkBorderRight">
                          {{ worker.text }}
                          <span v-if="debug.showTimesUsed && usedWorkers.find(el => el.id == worker.value)">({{ usedWorkers.find(el => el.id == worker.value).timesUsed || '0' }})</span>             
                          <v-btn icon x-small @click="deleteDailyWorker(worker.value)"><v-icon small color="red">mdi-close</v-icon></v-btn>
                        </td>  
                      </tr>
                    </template>

                    <tr>
                      <td colspan="2" class="text-center">
                        <v-btn v-if="$store.getters.rules.modifyDailyWorks" text block @click="showAddWorkerDialog=true"><v-icon>mdi-plus</v-icon></v-btn>
                      </td>
                    </tr>

                  </tbody>
                </v-simple-table>
              </v-col>

              <v-col cols="6" class="pl-0 workers-vehicles-left-list">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td colspan="2"><h4>Automezzi</h4></td>
                    </tr>

                    <tr 
                      v-for="(vehicle, index) in vehicles" 
                      v-bind:key="index" 
                      :class="(usedVehicles.find(el => el.id == vehicle.value)) ? 'usedVehicle' + ( (usedVehicles.find(el => el.id == vehicle.value).timesUsed==2) ? '-1' : '' ) + ( (usedVehicles.find(el => el.id == vehicle.value).timesUsed>2) ? '-2' : '' ) : ''"
                    >

                      <td v-if="!vehicle.justForTheDay" :class="'id-frontend-cell-' + getTheme() + ' leftListCell text-center leftListBorderRight'" width="18%">{{ vehicle.id_frontend }}</td>
                      <td v-if="!vehicle.justForTheDay" class="leftListCell pl-2">{{ vehicle.text }}<span v-if="debug.showTimesUsed && usedVehicles.find(el => el.id == vehicle.value)">({{ usedVehicles.find(el => el.id == vehicle.value).timesUsed || '0' }})</span></td>              

                      <!-- Just for the day -->
                      <td v-if="vehicle.justForTheDay" :class="'id-frontend-cell-' + getTheme() + ' leftListCell text-center leftListBorderRight'" width="18%">{{ vehicle.id_frontend }}</td>
                      <td v-if="vehicle.justForTheDay" class="leftListCell pl-2">
                        {{ vehicle.text }}<span v-if="debug.showTimesUsed && usedVehicles.find(el => el.id == vehicle.value)">({{ usedVehicles.find(el => el.id == vehicle.value).timesUsed || '0' }})</span>
                        <v-btn icon x-small @click="deleteDailyVehicle(vehicle.value)"><v-icon small color="red">mdi-close</v-icon></v-btn>
                      </td>              

                    </tr>

                    <tr>
                      <td colspan="2" class="text-center">
                        <v-btn v-if="$store.getters.rules.modifyDailyWorks" text block @click="showAddVehicleDialog=true"><v-icon>mdi-plus</v-icon></v-btn>
                      </td>
                    </tr>

                  </tbody>
                </v-simple-table>
                
              </v-col>
              
            </v-row>
            <!-- End select workers and vehicles table -->

            <!-- Colors reference tab -->
            <v-card v-if="works.length>0" class="mt-6 text-center mx-auto">
              <v-card-text class="pa-0">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td class="referenceTable pl-5 pr-5">
                        <v-icon color="black">mdi-square-outline</v-icon> Libero
                      </td>
                      <td class="referenceTable pr-10">
                        <v-icon class="usedWorker">mdi-crop-square</v-icon> 1
                      </td>
                      <td class="referenceTable pr-10">
                        <v-icon class="usedWorker-1">mdi-crop-square</v-icon> 2
                      </td>
                      <td class="referenceTable pr-10">
                        <v-icon class="usedWorker-2">mdi-crop-square</v-icon> 3+
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>

        </v-col>
        
        <v-col cols="12" md="9">

          <!-- Individual works cards -->
          <v-row>
            <v-col cols="12" md="3" class="list-text" style="padding: 0px" v-for="(work, index) in works" v-bind:key="index">
              <v-card class="mx-auto mb-5" outlined elevation="0">

                <v-card-actions>
                  <!-- Icons -->
                  <v-row style="padding-left: 6px; padding-right: 10px; padding-top: 0px; padding-bottom: 0px">

                    <v-col class="button-col">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small                    
                          @click="showOrderNotes(work.id_order, work.id_work)"
                          color="green" icon v-bind="attrs" v-on="on">
                          <v-icon small>
                            mdi-text-box-outline
                          </v-icon></v-btn>
                        </template>
                        <span>
                          Note ordine
                        </span>
                      </v-tooltip>
                    </v-col>

                    <v-col class="button-col">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <a :href="'/api/works/pdf/' + work.id_work" target="_blank">
                            <v-btn
                            small
                            color="red" icon v-bind="attrs" v-on="on">
                            <v-icon small>
                              mdi-file-pdf-outline
                            </v-icon></v-btn>
                          </a>
                        </template>
                        <span>
                          Stampa scheda lavorazione 
                        </span>
                      </v-tooltip> 
                    </v-col>

                    <v-col class="button-col">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <a :href="'/api/works/pdf-single/' + work.id_work" target="_blank">
                            <v-btn
                            small
                            color="indigo" icon v-bind="attrs" v-on="on">
                            <v-icon small>
                              mdi-file-pdf-outline
                            </v-icon></v-btn>
                          </a>
                        </template>
                        <span>
                          Scheda lavorazione singola
                        </span>
                      </v-tooltip> 
                    </v-col>

                    <v-col class="button-col" v-if="$env.siteA">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small
                          @click="showResources(work.id_work)"
                          color="blue" icon v-bind="attrs" v-on="on">
                          <v-icon small>
                            mdi-hammer-screwdriver
                          </v-icon></v-btn>
                        </template>
                        <span>
                          Risorse
                        </span>
                      </v-tooltip> 
                    </v-col>

                    <v-col class="button-col" v-if="$env.siteA && $store.getters.rules.editResourcesB">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small
                          @click="showResourcesB(work.id_work)"
                          color="warning" icon v-bind="attrs" v-on="on">
                          <v-icon small>
                            mdi-hammer-screwdriver
                          </v-icon></v-btn>
                        </template>
                        <span>
                          Risorse B
                        </span>
                      </v-tooltip> 
                    </v-col>

                    <v-col class="button-col">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small
                          @click="showMaterialsDialog(work.id_work)"
                          color="purple" icon v-bind="attrs" v-on="on">
                          <v-icon small>
                            mdi-package-variant-closed
                          </v-icon></v-btn>
                        </template>
                        <span>
                          Materiali
                        </span>
                      </v-tooltip> 
                    </v-col>

                    <v-col class="button-col">

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <a :href="'/api/materiali/pdf/' + work.id_work" target="_blank">
                            <v-btn small
                            color="error" icon v-bind="attrs" v-on="on">
                              <v-icon small color="blue">
                                mdi-file-pdf-outline
                              </v-icon>
                            </v-btn>
                          </a>
                        </template>
                        <span>
                          Riepilogo materiali
                        </span>
                      </v-tooltip> 
      
                    </v-col>

                    <v-col class="button-col">

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <a href="/assets/moduli_vuoti.pdf" target="_blank">
                            <v-btn small
                              icon v-bind="attrs" v-on="on">
                              <v-icon small color="pink">
                                mdi-file-document-edit-outline
                              </v-icon>
                            </v-btn>
                          </a>
                        </template>
                        <span>
                          Scarica scheda di trasporto
                        </span>
                      </v-tooltip> 
      
                    </v-col>                    

                  </v-row>
                </v-card-actions>

                <v-card-text class="work-text pt-0">                    
                  <v-row>
                    <v-col class="listTable">
                      <h4 :class="'text-color-'+getTheme()">
                        {{work.name}} {{work.surname}} <span :class="'hour-color-'+getTheme()">(h. {{work.hour | hour }})</span>
                      </h4>

                      <v-simple-table class="table-client">
                        <tr>
                          <td class="table-client-col-left pl-1 table-client-bottom">
                            Via
                          </td>
                          <td class="pl-1 table-client-bottom" colspan="2">
                            {{work.address}}, {{work.city}} ({{work.province}})
                          </td>
                        </tr>

                        <!-- Show flag permesso -->
                        <!--
                        <tr v-if="work.flag_in_schermata_lavorazioni == 1">
                          <td colspan="2" class="table-client-col-left table-client-bottom">
                            <v-btn color="orange" tile x-small class="white--text pa-1">
                              Permesso
                              <v-icon small color="success">mdi-check</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                        -->

                        <tr>
                          <td class="table-client-col-left pl-1 table-client-bottom">
                            Tel.
                          </td>
                          <td class="pl-1 table-client-bottom">
                            {{ (!work.phone && !work.cellphone) ? '-' : '' }}  
                            {{ (work.phone) ? work.phone : '' }} {{ (work.cellphone) ? work.cellphone : '' }}  
                          </td>
                          <!-- Show flag permesso -->
                          <td class="text-right table-client-bottom">
                            <v-btn v-if="work.flag_in_schermata_lavorazioni == 1" color="orange" tile x-small class="white--text pa-1">
                              Permesso
                              <v-icon small color="success">mdi-check</v-icon>
                            </v-btn>
                          </td>                
                        </tr>
                        <!-- Attached files -->
                        <tr v-if="work.documents.length > 0 && $env.siteA">
                          <td colspan="3" class="pt-1">
                            <i :class="'text-color-'+getTheme()">Documenti allegati</i>

                            <div v-for="(document, index) in work.documents.filter(el => el.showInCharge == 1)" v-bind:key="index">
                              <v-divider></v-divider>
                              <a :href="'/api/uploads/documents/'+document.filename" target="_blank">{{document.filename}}</a>
                            </div>
                          </td>
                        </tr>
                        <!--End attached files -->
                      </v-simple-table>
                    </v-col>
                  </v-row>

                  <v-row v-if="$env.siteA">
                    <v-col class="pr-0 pb-0 pl-0 less-line-height">
                      <span :class="'text-color-' + getTheme()">
                        Risorse: operai <strong>({{work.requiredWorkers}})</strong>, automezzi:

                        <template v-if="work.requiredVehicles!=0">
                          <span v-for="(vehicle, vehicleIndex) in work.requiredVehicles" :key="'vehicle-'+vehicleIndex">
                            {{vehicle.name}} <strong>({{vehicle.value}})</strong>{{ (vehicleIndex!=work.requiredVehicles.length-1) ? ', ' : ''}}
                          </span>
                        </template>      
                        <template v-else> -</template>
                      </span>
                    </v-col>
                  </v-row>

                  <!-- New list -->
                  <v-row>
                    <v-col cols="6" class="pr-0 pl-0">
                      <v-simple-table>                    
                        <tbody>
                          <tr>
                            <td class="windowListCell darkBorderRight">
                              Operai

                              <!-- Show check if number of workers is same as requested in resources for current work -->
                              <v-icon
                              v-if="
                                (work.totalResources.filter(el => el.id_worker != null) || []).length == work.requiredWorkers
                              "
                              color="green" small>mdi-check</v-icon>
                            </td>
                          </tr>
                          <tr v-for="(resource, index) in work.totalResources" v-bind:key="index">
                            <td width="15%" :class="'worker-cell-' + getTheme() + ' windowListCell darkBorderRight text-center'">

                              <!-- Show background white for last text-input -->
                              <v-text-field                              
                                :readonly="!$store.getters.rules.modifyDailyWorks"
                                v-model="resource.id_worker_frontend"
                                :suffix="(workers.find(el => el.id_frontend==resource.id_worker_frontend.toUpperCase())) ? workers.find(el => el.id_frontend==resource.id_worker_frontend.toUpperCase()).text.substr(0, 15) : ''"
                                solo
                                flat 
                                dense
                                placeholder="-"
                                hide-details="true"

                                :background-color="
                                  (getTheme() != 'light') ? '#333' : (
                                    (index != work.totalResources.length-1) ? '#ffa' : '#fff'
                                  )
                                "

                                class="font-size-individual"
                                @change="adjustWorker($event, work.id_work, index)"
                                
                              ></v-text-field>

                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>

                    <v-col cols="6" class="pl-0 pr-0">
                      <v-simple-table>                    
                        <tbody>
                          <tr>
                            <td class="windowListCell">
                              Automezzi

                              <!-- Show check if number of vehicles is same as requested in resources for current work -->
                              <v-icon
                              v-if="
                                (work.totalResources.filter(el => el.id_vehicle != null) || []).length == work.requiredVehicles.reduce((prev, curr) => {
                                return prev + parseInt(curr.value)
                              }, 0) 
                              "
                              color="green" small>mdi-check</v-icon>
                            </td>
                            
                          </tr>
                          <tr v-for="(resource, index) in work.totalResources" v-bind:key="index">
                            <td width="15%" :class="'vehicle-cell-' + getTheme() + ' windowListCell text-center'">
                              <v-text-field       
                                :readonly="!$store.getters.rules.modifyDailyWorks"
                                placeholder="-"                       
                                v-model="resource.id_vehicle_frontend"
                                :suffix="(vehicles.find(el => el.id_frontend==resource.id_vehicle_frontend.toUpperCase())) ? vehicles.find(el => el.id_frontend==resource.id_vehicle_frontend.toUpperCase()).text.substr(0, 15) : ''"
                                solo
                                flat 
                                dense
                                hide-details="true"

                                :background-color="
                                  (getTheme() != 'light') ? '#555' : (
                                    (index != work.totalResources.length-1) ? '#cdf' : '#fff'
                                  )
                                "

                                class="font-size-individual"
                                @change="adjustVehicle($event, work.id_work, index)"
                              ></v-text-field>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>

                  </v-row>                  

                </v-card-text>
              </v-card>
            </v-col>          
            <!-- End individual works cards -->


            <!-- Jolly workers -->            
            <v-col cols="12" md="3" class="list-text" style="padding: 0px" v-if="jollyResources.length > 0">
              <v-card class="mx-auto mb-5" outlined elevation="0">

                <v-card-text class="work-text pt-0">   

                  <p>
                      Operai jolly tra lavorazioni, in sede, depositi, etc.                 
                </p>

                  <!-- New list -->
                  <v-row>
                    <v-col cols="6" class="pr-0 pl-0">
                      <v-simple-table>                    
                        <tbody>
                          <tr>
                            <td class="windowListCell darkBorderRight">
                              Operai

                              <!-- Show check if number of workers is same as requested in resources for current work -->
                              <v-icon
                              color="green" small>mdi-check</v-icon>
                            </td>
                          </tr>
                          <tr v-for="(resource, index) in jollyResources" v-bind:key="index">
                            <td width="15%" :class="'worker-cell-' + getTheme() + ' windowListCell darkBorderRight text-center'">

                              <!-- Show background white for last text-input -->
                              <v-text-field                              
                                :readonly="!$store.getters.rules.modifyDailyWorks"
                                v-model="resource.id_worker_frontend"
                                :suffix="(workers.find(el => el.id_frontend==resource.id_worker_frontend.toUpperCase())) ? workers.find(el => el.id_frontend==resource.id_worker_frontend.toUpperCase()).text.substr(0, 15) : ''"
                                solo
                                flat 
                                dense
                                placeholder="-"
                                hide-details="true"

                                :background-color="
                                  (getTheme() != 'light') ? '#333' : (
                                    (index != jollyResources.length-1) ? '#ffa' : '#fff'
                                  )
                                "

                                class="font-size-individual"                                
                              ></v-text-field>

                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>

                    <v-col cols="6" class="pl-0 pr-0">
                      <v-simple-table>                    
                        <tbody>
                          <tr>
                            <td class="windowListCell">
                              Automezzi

                              <!-- Show check if number of vehicles is same as requested in resources for current work -->
                            </td>
                            
                          </tr>
                          <tr v-for="(resource, index) in jollyResources" v-bind:key="index">
                            <td width="15%" :class="'vehicle-cell-' + getTheme() + ' windowListCell text-center'">
                              <v-text-field       
                                :readonly="!$store.getters.rules.modifyDailyWorks"
                                placeholder="-"                       
                                v-model="resource.id_vehicle_frontend"
                                :suffix="(vehicles.find(el => el.id_frontend==resource.id_vehicle_frontend.toUpperCase())) ? vehicles.find(el => el.id_frontend==resource.id_vehicle_frontend.toUpperCase()).text.substr(0, 15) : ''"
                                solo
                                flat 
                                dense
                                hide-details="true"

                                :background-color="
                                  (getTheme() != 'light') ? '#555' : (
                                    (index != jollyResources.length-1) ? '#cdf' : '#fff'
                                  )
                                "

                                class="font-size-individual"
                              ></v-text-field>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>

                  </v-row>                  

                </v-card-text>
              </v-card>
            </v-col>     
            <!-- End Jolly workers -->









          </v-row>

          <!-- Text no works / insert date and button to add work -->
          <v-row>
            <v-col>
              <h4 v-if="works.length==0 && oldDate">
                Nessuna lavorazione disponibile per la data {{oldDate}}              
              </h4>

              <h4 v-if="works.length==0 && !oldDate">
                Inserisci una data.
              </h4>
            </v-col>
          </v-row>
          <v-row v-if="$store.getters.rules.addWorkInDailyWork">
            <v-col>
              <AddWork v-if="oldDate" :today="oldDate.replace(/\//g, '-')" @refresh="loadDate(oldDate)" />
            </v-col>
          </v-row>

          <!-- Icons reference tab -->
          <v-card v-if="works.length>0" class="mt-6 text-center mx-auto" max-width="700">
            <v-card-text class="pa-0">
              <v-simple-table>
                <thead>
                  <tr>
                    <td class="referenceTable pl-5 pr-5">
                      <v-icon color="green">mdi-text-box-outline</v-icon><br /> Note ordine
                    </td>
                    <td class="referenceTable pr-5">
                      <v-icon color="red">mdi-file-pdf-outline</v-icon><br /> Stampa scheda lavorazione
                    </td>
                    <td class="referenceTable pr-5">
                      <v-icon color="indigo">mdi-file-pdf-outline</v-icon><br /> Scheda lav. singola
                    </td>
                    <td class="referenceTable pr-10">
                      <v-icon color="orange">mdi-hammer-screwdriver</v-icon><br /> Risorse
                    </td>
                    <td class="referenceTable pr-5">
                      <v-icon color="purple">mdi-package-variant-closed</v-icon><br /> Materiali
                    </td>
                    <td class="referenceTable">
                      <v-icon color="blue">mdi-file-pdf-outline</v-icon><br /> Riepilogo materiali
                    </td>
                    <td class="referenceTable pr-5">
                      <v-icon color="pink">mdi-file-document-edit-outline</v-icon><br /> Scheda di trasporto
                    </td>                    
                  </tr>
                </thead>
              </v-simple-table>
            </v-card-text>
          </v-card>

          <!-- Download empty form -->
          <v-card class="mt-6 text-center mx-auto" max-width="700" elevation="0">
            <v-card-text class="pa-0">
              <a href="/assets/moduli_vuoti.pdf" target="_blank">SCARICARE SCHEDA DI TRASPORTO</a>
            </v-card-text>
          </v-card>

        </v-col>

      </v-row>
    </v-container>

  <!-- Add worker or vehicle for current day -->
  <v-dialog v-model="showAddWorkerDialog" max-width="600">
    <v-card>
      <v-card-title>
        Inserisci operaio per la giornata
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="addWorkerName" label="Nome operaio"></v-text-field>
      </v-card-text>      
      <v-card-actions>
        <v-btn color="error" @click="addWorkerName=''; showAddWorkerDialog=false">Annulla</v-btn>          
        <v-spacer></v-spacer>
        <v-btn color="success" @click="addDailyWorker">Inserisci</v-btn>          
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showAddVehicleDialog" max-width="600">
    <v-card>
      <v-card-title>
        Inserisci automezzo per la giornata
      </v-card-title>
      <v-card-text>
        <v-text-field v-model="addVehicleName" label="Nome automezzo"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="addVehicleName=''; showAddVehicleDialog=false">Annulla</v-btn>          
        <v-spacer></v-spacer>
        <v-btn color="success" @click="addDailyVehicle">Inserisci</v-btn>          
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog.show" scrollable max-width="800px">
    <v-card class="mx-auto" max-width="800">
      <v-card-title>
        {{dialog.title}}
      </v-card-title>
      <v-card-text>
        {{dialog.content}}
      </v-card-text>
      <v-card-actions>
        <v-btn color="accent" @click="dialog.show = false">Chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- All notes dialog -->
  <v-dialog v-model="notesDialog.enabled" scrollable max-width="800px">
    <v-card class="mx-auto" max-width="800">
      <v-card-title>
        Note
      </v-card-title>
      <v-card-text>

        <v-simple-table>
          <tbody>
            <tr>
              <td width="30%">
                <h3>Note ordine</h3>
              </td>
              <td>
                {{ workNotes.orderNotes || '-' }}
              </td>
            </tr>
            <tr>
              <td width="30%">
                <h3>Note Patrizio</h3>
              </td>
              <td>
                {{ workNotes.orderNotesPatrizio || '-' }}
              </td>
            </tr>
            <tr>
              <td width="30%">
                <h3>Note Lavorazione</h3>
              </td>
              <td>
                {{ workNotes.workNotes || '-' }}
              </td>
            </tr>
            <tr>
              <td width="30%">
                <h3>Note Claudio</h3>
              </td>
              <td>
                <div v-if="!editNotesClaudio">
                  {{ workNotes.workNotesClaudio || '-' }}
                </div>
                <v-textarea v-if="editNotesClaudio" v-model="workNotes.workNotesClaudio">
                  
                </v-textarea>              
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-right">
                
                <v-btn small v-if="!editNotesClaudio && $store.getters.rules.modifyDailyWorks" color="success" @click="editNotesClaudio = true">
                  <v-icon small>mdi-pencil</v-icon> Modifica
                </v-btn>

                <v-btn class="mr-2" small v-if="editNotesClaudio" color="error" @click="editNotesClaudio = false; showOrderNotes(notesDialog.idOrder, notesDialog.idWork)">
                  <v-icon small>mdi-close</v-icon>
                  Annulla
                </v-btn>

                <v-btn small v-if="editNotesClaudio && $store.getters.rules.modifyDailyWorks" color="success" @click="editNotesClaudio = false; saveNotesClaudio(notesDialog.idWork)">
                  <v-icon small>mdi-content-save</v-icon>
                  Salva
                </v-btn>

              </td>
            </tr>
          </tbody>
        </v-simple-table>

      </v-card-text>
      <v-card-actions>
        <v-btn color="accent" @click="notesDialog.enabled = false">Chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Resources -->
  <v-dialog
    v-model="overlayResources"
    scrollable
    max-width="600px"
  >
    <v-card min-width="600">
      <v-card-title>Gestire risorse</v-card-title>
      <v-card-text>

        <v-simple-table>
          <thead>
            <tr>
              <th>
                Risorsa
              </th>
              <th>
                Quantità
              </th>
              <th>
                Disp. / Totali
              </th>  
            </tr>
          </thead>
          <tbody>
            <tr v-for="(resource, index) in resourcesLabels" v-bind:key="index">
              <td class="dense-cell">
                {{resource.name}}
              </td>
              <td class="dense-cell">
                <v-text-field type="number" hide-details dense outlined v-model="resourcesValues[resource.id]" v-if="$store.getters.rules.works.edit"></v-text-field>
                <span v-if="!$store.getters.rules.works.edit">
                  {{ resourcesValues[resource.id] }}
                </span>
              </td>
              <td class="dense-cell">
                <span :class="(resourcesAvailable[resource.id]<0) ? 'red--text' : ''">
                  {{resourcesAvailable[resource.id]}}
                  
                  <!-- Hide total if resource is Operai (Op) -->
                  <span v-if="resource.name != 'Op'">
                    / {{resource.ammount}}
                  </span>
                  </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="overlayResources = false">Annulla</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveResources" v-if="$store.getters.rules.works.edit">Conferma</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>  

      <resources-b-dialog
        :overlayResourcesB="overlayResourcesB"
        :resourcesLabels="resourcesLabels"
        :resourcesValues="resourcesValues"
        :resourcesAvailable="resourcesAvailable"

        @close="overlayResourcesB = false"
        @save="saveResourcesB"
    />

  <!-- Workers and vehicles -->
  <!--
  <v-dialog
    v-model="overlayEditWorkers"
    scrollable
    max-width="800px"
  >
  <v-card light min-width="800">
      <v-card-title>Gestire operai ({{selectedWorkers.length}})</v-card-title>
      <v-card-text>
        <br />
        <h4>Operai: </h4>
        <br />
        <div v-if="selectedWorkers.length>0">
          <h3>{{ selectedWorkersString }}</h3>
        </div>
        <div v-if="selectedWorkers.length==0">
          Nessun operaio selezionato.
        </div>
        <br />
        <div v-if="$store.getters.rules.workersVehicles.edit">
          <v-divider></v-divider>
          <br />
          <v-form ref="formWorkers">

            <v-row>
              <v-col cols="6" v-for="(worker, index) in workers" v-bind:key="index" style="padding: 5px">
                <v-checkbox  :value="worker.value" v-model="selectedWorkers" dense style="margin: 0px" :label="worker.text"></v-checkbox>
              </v-col>
            </v-row>            

          </v-form>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="overlayEditWorkers=false">Annulla</v-btn>
        <v-spacer></v-spacer>
        <v-btn small text @click="selectedWorkers=[]" v-if="$store.getters.rules.workersVehicles.edit"><v-icon color="red">mdi-close</v-icon> Cancella tutto</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="confirmSelectedWorkers" v-if="$store.getters.rules.workersVehicles.edit">Salva</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  -->

  <!--
  <v-dialog
    v-model="overlayEditVehicles"
    scrollable
    max-width="800px"
  >
  <v-card light min-width="800">
      <v-card-title>Gestire automezzi ({{selectedVehicles.length}})</v-card-title>
      <v-card-text>

        <br />
        <h4>Automezzi: </h4>
        <br />
        <div v-if="selectedVehicles.length>0">
          <h3>{{ selectedVehiclesString }}</h3>
        </div>
        <div v-if="selectedVehicles.length==0">
          Nessun automezzo selezionato.
        </div>
        <br />
        <div v-if="$store.getters.rules.workersVehicles.edit">
          <v-divider></v-divider>
          <br />

          <v-form ref="formVehicles">

            <v-row>
              <v-col cols="6" v-for="(vehicle, index) in vehicles" v-bind:key="index" style="padding: 5px">
                <v-checkbox :value="vehicle.value" v-model="selectedVehicles" dense style="margin: 0px" :label="vehicle.text"></v-checkbox>
              </v-col>
            </v-row>  

          </v-form>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="overlayEditVehicles=false">Annulla</v-btn>
        <v-spacer></v-spacer>
        <v-btn small text @click="selectedVehicles=[]" v-if="$store.getters.rules.workersVehicles.edit"><v-icon color="red">mdi-close</v-icon> Cancella tutto</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="confirmSelectedVehicles" v-if="$store.getters.rules.workersVehicles.edit">Salva</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  -->

  <!-- Materials dialog -->
  <v-dialog
    v-model="materials.showPopup"
    scrollable
    max-width="600px"
  >
    <v-card min-width="600">
      <v-card-title>Gestire materiali</v-card-title>
      <v-card-text>
        <v-form ref="inOutMaterialsForm">

          <v-simple-table>
            <thead>
              <tr>
                <td class="col-6">Materiale</td>
                <td>In uscita</td>
                <td>In ingresso</td>
              </tr>          
            </thead>
            <tbody>
              <tr v-for="(material, index) in materials.materialsList" v-bind:key="index" class="pa-0">
                <td class="dense-cell">
                  {{ material.name }}
                </td>

                <!-- Select 'SI' 'NO' for avvitatore e trapano, ferri, coperte -->
                <template v-if="['Avvitatore e Trapano', 'Ferri', 'Coperte'].find(el => el == material.name)">
                  <td colspan="2" class="dense-cell">
                    <v-select :items="yesNoOptions" v-model="material.value_out" class="h-35">
                    </v-select>
                  </td>
                  <td>
                  </td>
                </template>

                <template v-else>
                  <td class="dense-cell">
                    <v-text-field type="number" class="pa-0 h-35" v-model="material.value_out" :rules="(index!==0) ? [...rules.numbersOnly, ...rules.integer, ...rules.positiveNumbersOnly] : []">
                    </v-text-field>
                  </td>
                  <td class="dense-cell">
                    <v-text-field type="number" class="pa-0 h-35" v-model="material.value_in" :rules="(index!==0) ? [...rules.numbersOnly, ...rules.integer, ...rules.positiveNumbersOnly] : []">
                    </v-text-field>
                  </td>
                </template>

              </tr>
            </tbody>
          </v-simple-table>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="materials.showPopup = false">Annulla</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="$store.getters.rules.modifyDailyWorks" color="success" @click="saveMaterials()">Salva</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  </div>
</template>

<script>

// TODO: remove
import {workers, vehicles} from '../global';

import AddWork from '@/components/dailyWorks/AddWork.vue'
import ResourcesBDialog from '@/components/ResourcesBDialog'

export default {

  components: {
      AddWork,
      ResourcesBDialog
    },

  // Data
  data() {
    return {

      // Leave white space between workers
      // '20', '33', '44'
      workersWhitespace: [],

      yesNoOptions: [
        {value:'0', text:'No'},
        {value:'1', text:'Sì'},
      ],

      materials: {
        showPopup: false,
        workId: null,
        materialsList: [],
      },

      workerMouseOver: false,

      debug: {
        showTimesUsed: false,
      },

      oldDate: false,

      // These variables manages what delete button to show
      showDeleteWork: false,
      showDeleteVehicle: false,

      /* Resources */
      currentWorkId_resource: false,
      resourcesValues: [],
      resourcesAvailable: [],
      resourcesLabels: [],
      overlayResources: false,
      overlayResourcesB: false,

      overlayEditWorkers: false,
      overlayEditVehicles: false,
      currentWorkId: false,
      selectedVehicles: [],
      selectedWorkers: [],

      // Define already used workers and vehicles
      usedVehicles: [],
      userdWorkers: [],

      dialog: {
        show: false,
        title: '',
        content: '',
      },
      
      date: null,
      workers,
      vehicles,
      on: false,
      attrs: false,
      // debug
      //works:  [ { "id_work": "35", "id_order": "25", "id_appointment": "13", "name": "Manuel", "surname": "Gimenez", "phone": "03401493338", "cellphone": "15510271", "address": "Via Collegno 1", "zip": "10093", "city": "Collegno", "province": "TO", "workers": [ "2", "0", "4" ], "vehicles": [ "4", "6", "7", "9", "11" ] } ] ,
      works: [],

      rules: {
        required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
        date: [ value => (!/((0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/[12]\d{3})/.test(value)) ? 'Formato non valido. (gg/mm/aaaa)' : true ],
        numbersOnly: [ value => (isNaN(value)) ? 'Inserire un valore numerico' : true ],
        integer: [ value => (parseInt(value) != value) ? 'Inserire un numero intero uguale o maggiore di zero' : true ],
        positiveNumbersOnly: [ value => (parseInt(value) < 0) ? 'Inserire un numero intero uguale o maggiore di zero' : true ]
      },

      workNotes: {
        orderNotes: null,
        orderNotesPatrizio: null,
        workNotes: null,
        workNotesClaudio: null,
      },

      notesDialog: {
        enabled: false,
        idOrder: null,
        idWork: null,
      },

      editNotesClaudio: false,

      avoidDuplication: {
        lastWorkId: null,
        lastWorkerValue: null,
        lastVehicleValue: null,
        lastIndex: null,
      },

      showAddWorkerDialog: false,
      addWorkerName: '',
      showAddVehicleDialog: false,
      addVehicleName: '',

      jollyResources: [],

    }
  },

  methods: {

    getWorks() {
      if (!this.$refs.searchForm.validate())
        return;

      this.$router.push({path: '/dailyWorks/'+ this.date.replace(/\//g, '-') });
    },

    test(id) {
     console.log(id);
    },

    getResourcesLabels() {
      // Type 0 = worker
      // Type 1 = vehicle
      this.$api.works.getResourcesLabels().then(data => {
        this.resourcesLabels = data.values.resources;
      });               
    },

    showDialog(title, content) {
      this.dialog = {
        show: true,
        title,
        content
      };
    },

    showOrderNotes(id_order, id_work) {

      this.editNotesClaudio = false;

      this.$api.orders.getFromId(id_order).then(data => {
        this.workNotes.orderNotes = data.values.order.notes;
        this.workNotes.orderNotesPatrizio = data.values.order.notes_patrizio;

        this.$api.orders.getWork(id_work).then(data => {
          this.workNotes.workNotes = data.values.work.notes;
          this.workNotes.workNotesClaudio = data.values.work.notes_claudio;

          this.notesDialog.idOrder = id_order;
          this.notesDialog.idWork = id_work;
          this.notesDialog.enabled = true;
        });    
      });    
    },

    saveNotesClaudio(id_work) {
      this.$api.orders.saveNotesClaudio(id_work, this.workNotes.workNotesClaudio).then(data => {
        if (data.ok)
          this.$emit('showSnack', 'Note Claudio aggiornate', 'success');
        else
          this.$emit('showSnack', 'Errore inserimento note Claudio', 'error');
      });   
    },

    showOrderNotesPatrizio(id) {
      this.$api.orders.getFromId(id).then(data => {
        this.showDialog('Note ordine - Patrizio', data.values.order.notes_patrizio);
      });    
    },

    showWorkNotes(id) {
      this.$api.orders.getWork(id).then(data => {
        this.showDialog('Note lavorazione', data.values.work.notes);
      });    
    },

    showResources(id) {
      this.currentWorkId_resource = id;
      // Load resources values for current work

      this.$api.orders.getWorkResources(id).then(data => {
        if (data.ok) {
          // Set all values to 0
          // Set availability by default to total ammount
          this.resourcesValues = [];
          this.resourcesAvailable = [];
          this.resourcesLabels.forEach(el => {
            this.resourcesValues[el.id] = 0;
            this.resourcesAvailable[el.id] = el.ammount;
          });        

          data.values.resources.forEach(el => {
            this.resourcesValues[el.id_resource] = el.value;
          });
          
          // Get used resources and then do available - used
          if (data.values.availability) {
            data.values.availability.forEach(el => {
              // This will show me all used resources during the day of current order
              /*
                Example
                  id_resource	  used 	
                  4 			      2
                  5 			      2
              */
              let resourceTotal = this.resourcesAvailable[el.id_resource];             
              this.resourcesAvailable[el.id_resource] = parseInt(resourceTotal) - parseInt(el.used);
            });
          }

          // Show dialog
          this.overlayResources = true;
        } else {
          this.$emit('showSnack', data.values.msg, 'Errore');
        }
      }); 
    },   

    showResourcesB(id) {
      this.currentWorkId_resource = id;
      // Load resources values for current work

      this.$api.orders.getWorkResourcesB(id).then(data => {
        if (data.ok) {
          // Set all values to 0
          // Set availability by default to total ammount
          this.resourcesValues = [];
          this.resourcesAvailable = [];
          this.resourcesLabels.forEach(el => {
            this.resourcesValues[el.id] = 0;
            this.resourcesAvailable[el.id] = el.ammount;
          });        

          data.values.resources.forEach(el => {
            this.resourcesValues[el.id_resource] = el.value;
          });
          
          // Get used resources and then do available - used
          if (data.values.availability) {
            data.values.availability.forEach(el => {
              // This will show me all used resources during the day of current order
              /*
                Example
                  id_resource	  used 	
                  4 			      2
                  5 			      2
              */
              let resourceTotal = this.resourcesAvailable[el.id_resource];             
              this.resourcesAvailable[el.id_resource] = parseInt(resourceTotal) - parseInt(el.used);
            });
          }

          // Show dialog
          this.overlayResourcesB = true;
        } else {
          this.$emit('showSnack', data.values.msg, 'Errore');
        }
      }); 
    },   

  saveResources() {
    this.$emit('showProgress');

    // Create array of resource ID and new value
    let values = [];
    this.resourcesValues.forEach((el, index) => {
      values.push({id_work: this.currentWorkId_resource, id_resource: index, value: el});
    });

    this.$api.orders.saveWorkResources(this.currentWorkId_resource, values).then(data => {
      this.$emit('hideProgress');
      if (data.ok) {
        // Hide dialog
        this.overlayResources = false;
        this.$emit('showSnack', 'Risorse aggiornate', 'success');
      } else {
        this.$emit('showSnack', data.values.msg, 'error');
      }
    }); 
  },

  saveResourcesB() {
    this.$emit('showProgress');

    // Create array of resource ID and new value
    let values = [];
    this.resourcesValues.forEach((el, index) => {
      values.push({id_work: this.currentWorkId_resource, id_resource: index, value: el});
    });

    this.$api.orders.saveWorkResourcesB(this.currentWorkId_resource, values).then(data => {
      this.$emit('hideProgress');
      if (data.ok) {
        // Hide dialog
        this.overlayResourcesB = false;
        this.$emit('showSnack', 'Risorse aggiornate', 'success');
      } else {
        this.$emit('showSnack', data.values.msg, 'error');
      }
    }); 
  },

    showEditWorkers(id) {
      this.currentWorkId = id; 
      
      // Load workers for current work
      this.$api.works.getWorkers(this.currentWorkId).then(data => {
        if (data.ok) {
          this.selectedWorkers = data.values.workers;
        } else {
          this.$emit('showSnack', 'Errore: lista operai non non trovata', 'error');
        }
      });

      this.overlayEditWorkers = true;
    },

    confirmSelectedWorkers() {
      this.$api.works.setWorkers(this.currentWorkId, this.selectedWorkers).then(data => {
        if (data.ok) {
          this.overlayEditWorkers = false;
          this.$emit('showSnack', 'Lista operai aggiornata', 'success');
        } else {
          this.$emit('showSnack', 'Errore: lista operai non aggiornata', 'error');
        }

        this.loadDate(this.date);
      });
    },

    showEditVehicles(id) {
      this.currentWorkId = id; 
      
      this.$api.works.getVehicles(this.currentWorkId).then(data => {
        if (data.ok) {
          this.selectedVehicles = data.values.vehicles;
        } else {
          this.$emit('showSnack', 'Errore: lista automezzi non trovata', 'error');
        }
      });

      this.overlayEditVehicles = true;
    },

    confirmSelectedVehicles() {
      this.$api.works.setVehicles(this.currentWorkId, this.selectedVehicles).then(data => {
        if (data.ok) {
          this.overlayEditVehicles = false;
          this.$emit('showSnack', 'Lista automezzi aggiornata', 'success');
        } else {
          this.$emit('showSnack', 'Errore: lista automezzi non aggiornata', 'error');
        }

        this.loadDate(this.date);
      });
    },

    loadDate(date) {
      // Get workers and vehicles list
      this.$api.works.getWorkersVehiclesList(date).then(workersVehiclesListData => {
        this.workers = workersVehiclesListData.values.workers.filter(el => el.deleted==0);

        /*
        if(!this.$env.siteA && this.workers.length > 25)
            this.workers = this.workers.splice(0, 25);
        */

        // Order vehicles alphabetically
        this.vehicles = workersVehiclesListData.values.vehicles.sort((a, b) => a.value.localeCompare(b.value));

        // Breakpoints for whitespaces
        let currentBreakpoint = false;
        let workersBreakpoints = ['Coop.Unica'];

        // Set worker value as DB id, and worker id_frontend as array index
        for (let index=0; index<this.workers.length; index++) {
          let worker = this.workers[index];
          this.workers[index].id_frontend = (index+1).toString();
          this.workers[index].value = worker.id;

          // Apply whitespaces
          if (workersBreakpoints.length > 0) {
            if (worker.text.indexOf(workersBreakpoints[0]) != -1) {
              this.workersWhitespace.push((index+1).toString());
              // Remove break point
              currentBreakpoint = workersBreakpoints.shift();
            }
          } else {
            // If current value isn't present anymore, leave whitespace
            if (currentBreakpoint && worker.text.indexOf(currentBreakpoint) == -1) {
              // Value is not present anymore. Add white space
              this.workersWhitespace.push((index+1).toString());
              currentBreakpoint = false;
            }
          }
        }
      
        // Set car value as DB id, and vehicle id_frontend as Db value (A01, A02, A03, etc...)
        for (let index=0; index<this.vehicles.length; index++) {
          this.vehicles[index].id_frontend = this.vehicles[index].value;
          this.vehicles[index].value = this.vehicles[index].id;
        }

        // Check what workers and vehicles are only for the day
        this.workers.forEach(el => {
          el.justForTheDay = Boolean (el.date);
        });

        this.vehicles.forEach(el => {
          el.justForTheDay = Boolean (el.date);
        });

        //this.works = [];
        this.oldDate = date;

        // Load works
        this.$api.dailyWorks.getWorks(date).then(data => {
          if (data.ok) {

            // Update list of used workers and vehicles
            this.usedVehicles = [];
            this.usedWorkers = [];

            data.values.works.forEach(work => {

              if (!work.requiredWorkers)
                work.requiredWorkers = 0;

              if (!work.requiredVehicles)
                work.requiredVehicles = 0;

              work.totalResources.forEach(resource => {

                // Check if values exist
                if (resource.id_vehicle!==null) {
                  resource.id_vehicle_frontend = this.vehicles.find(el => el.value==resource.id_vehicle).id_frontend;

                  // Add to selected vehicles
                  // Check if vehicle already exists, so add +1 to timesUsed. Otherwise, set timesUsed to 1 and push into array
                  if (this.usedVehicles.find(el => el.id === resource.id_vehicle)) {
                    // Exists
                    this.usedVehicles.find(el => el.id === resource.id_vehicle).timesUsed++;
                  } else {
                    // Add new
                    let selected = {
                      id: resource.id_vehicle,
                      timesUsed: 1,
                    };
                    this.usedVehicles.push(selected);
                  }
                } else {
                  resource.id_vehicle_frontend = '';
                }

                if (resource.id_worker!==null && resource.id_worker!=0 && this.workers.find(el => el.value==resource.id_worker)) {
                  resource.id_worker_frontend = this.workers.find(el => el.value==resource.id_worker).id_frontend;
                  // Add to selected workers

                  // Check if worker already exists, so add +1 to timesUsed. Otherwise, set timesUsed to 1 and push into array
                  if (this.usedWorkers.find(el => el.id === resource.id_worker)) {
                    // Exists
                    this.usedWorkers.find(el => el.id === resource.id_worker).timesUsed++;
                  } else {
                    // Add new
                    let selected = {
                      id: resource.id_worker,
                      timesUsed: 1,
                    };
                    this.usedWorkers.push(selected);
                  }

                } else {
                  resource.id_worker_frontend = '';
                }

                  
              });
            });

            // Filter works with flag_renata 0
            // Get workers where flag_renata 0
            if(!this.$env.siteA) {
                let jollyResources = [];

                data.values.works.forEach(work => {
                    if(work.private) {
                        work.requiredWorkers = work.requiredWorkers_b;
                        work.requiredVehicles = work.requiredVehicles_b;
                    }

                    if(work.work_flag_renata == 0 && work.private)
                        work.totalResources.forEach(resource => {
                            if(resource.id_worker)
                                jollyResources.push(resource)
                        })
                })

                // Remove works where flag renata = 0 and client is not private
                data.values.works = data.values.works.filter(el => el.work_flag_renata == 1);

                this.jollyResources = jollyResources;
            }

            this.works = data.values.works;

            this.checkAllWorksHaveEmptyLine();
          } else {
            this.works = [];
            this.checkAllWorksHaveEmptyLine();
          }
        });   

      }); // End API call get workers list

    },

    // Functions with workers and vehicles
    addWorker(workId, lineId, workerId) {
      this.$api.works.addSingleWorker(workId, lineId, workerId).then(data => {
        if (data.ok) {
          this.loadDate(this.date);
        }
      });
    },

    // Functions with workers and vehicles
    addVehicle(workId, lineId, vehicleId) {
      this.$api.works.addSingleVehicle(workId, lineId, vehicleId).then(data => {        
        if (data.ok) {
          this.loadDate(this.date);
        }
      });
    },

    // Remove vehicles and workers from lines
    deleteWorker(lineId) {      
      this.$api.works.deleteSingleWorker(lineId).then(data => {
        if (data.ok) {
          this.loadDate(this.date);
        }
      });
    },

    deleteVehicle(lineId) {
      this.$api.works.deleteSingleVehicle(lineId).then(data => {
        if (data.ok) {
          this.loadDate(this.date);
        }
      });
    },

    setFrontendIds() {

      /**
       * TOIMPROVE
       * There was a problem with vehicles value / id that can also happen with workers
       * 
       * Before, I used 'value' as ID because values were only numbers
       * 
       * Now, values can also have letters (A01, S02). In DB these are saved as 'values' but I used the column id to identify them
       * 
       * So, in frontend, vehicles.value is actually the column ID and id_frontend is the column 'value' in database
       * 
       * 11/05/2022 Same criteria applied to workers
       */

      // Set worker value as DB id, and worker id_frontend as Db value
      for (let index=0; index<this.workers.length; index++) {
        this.workers[index].id_frontend = this.workers[index].value;
        this.workers[index].value = this.workers[index].id;
      }
    
      // Set car value as DB id, and vehicle id_frontend as Db value
      for (let index=0; index<this.vehicles.length; index++) {
        this.vehicles[index].id_frontend = this.vehicles[index].value;
        this.vehicles[index].value = this.vehicles[index].id;
      }
    },

    adjustVehicle(value, id_work, index) {
      // Avoid double execution
      value = value.toUpperCase();
      if (value === this.avoidDuplication.lastVehicleValue && id_work === this.avoidDuplication.lastWorkId && index === this.avoidDuplication.lastIndex) {
        return;
      } else {
        this.avoidDuplication.lastVehicleValue = value;
        this.avoidDuplication.lastWorkId = id_work;
        this.avoidDuplication.lastIndex = index;
      }

      if (!value) {
        // Delete
        let lineToDelete = this.works.find(el => el.id_work == id_work).totalResources[index].id;

        this.$api.works.deleteSingleVehicle(lineToDelete).then(data => {
          if (!data.ok) {
            this.$emit('showSnack', 'Errore collegamento database', 'error');
          }
          this.loadDate(this.date);
        });
        
        // Check if we delete entire row
        if (this.works.find(el => el.id_work == id_work).totalResources[index].id_worker_frontend == '')
          this.works.find(el => el.id_work == id_work).totalResources.splice(index, 1);
        return;
      }
      
      // Check if value exists
      let vehicle = this.vehicles.find(el => el.id_frontend.toUpperCase() == value.toUpperCase());

      if (vehicle) {
        // Vehicle valid. Set to upper case
        this.works.find(el => el.id_work == id_work).totalResources[index].id_vehicle_frontend = value.toUpperCase();

        // Check if vehicle already selected and then show message
        if (this.usedVehicles.find(el => el.id === vehicle.value)) {
          this.$emit('showSnack', 'Attenzione: automezzo già assegnato ad un\'altra lavorazione', 'warning');
        }

        // Add to database
        let id_line = this.works.find(el => el.id_work == id_work).totalResources[index].id;
        this.$api.works.addSingleVehicle(id_work, id_line, vehicle.value).then(data => {
          if (!data.ok) {
            this.$emit('showSnack', 'Errore collegamento database', 'error');
          }
          this.loadDate(this.date);
        });
      } else {
        // Vehicle not valid        
        this.$emit('showSnack', 'Automezzo non valido', 'error');
        // Reset value       
        this.works.find(el => el.id_work == id_work).totalResources[index].id_vehicle_frontend = '';

        // Delete
        let lineToDelete = this.works.find(el => el.id_work == id_work).totalResources[index].id;

        this.$api.works.deleteSingleVehicle(lineToDelete).then(data => {
          if (!data.ok) {
            this.$emit('showSnack', 'Errore collegamento database', 'error');
          }
          this.loadDate(this.date);
        });

        // Check if we delete entire row
        if (this.works.find(el => el.id_work == id_work).totalResources[index].id_worker_frontend == '')
          this.works.find(el => el.id_work == id_work).totalResources.splice(index, 1);
      }

    },

    adjustWorker(value, id_work, index) {
      value = value.toUpperCase();
      // Avoid double execution
      if (value === this.avoidDuplication.lastWorkerValue && id_work === this.avoidDuplication.lastWorkId && index === this.avoidDuplication.lastIndex) {
        return;
      } else {
        this.avoidDuplication.lastWorkerValue = value;
        this.avoidDuplication.lastWorkId = id_work;
        this.avoidDuplication.lastIndex = index;
      }

      if (!value) {
        // Delete
        let lineToDelete = this.works.find(el => el.id_work == id_work).totalResources[index].id;

        this.$api.works.deleteSingleWorker(lineToDelete).then(data => {
          if (!data.ok) {
            this.$emit('showSnack', 'Errore collegamento database', 'error');
          }
          this.loadDate(this.date);
        });
        
        // Check if we delete entire row
        if (this.works.find(el => el.id_work == id_work).totalResources[index].id_vehicle_frontend == '')
          this.works.find(el => el.id_work == id_work).totalResources.splice(index, 1);
        return;
      }
      
      // Check if value exists
      let worker = this.workers.find(el => el.id_frontend.toUpperCase() == value.toUpperCase());

      if (worker) {
        // Worker valid. Set to upper case
        this.works.find(el => el.id_work == id_work).totalResources[index].id_worker_frontend = value.toUpperCase();

        // Check if worker already selected and then show message
        if (this.usedWorkers.find(el => el.id === worker.value))
          this.$emit('showSnack', 'Attenzione: operaio già assegnato ad un\'altra lavorazione', 'warning');

        // Add to database
        let id_line = this.works.find(el => el.id_work == id_work).totalResources[index].id;
        this.$api.works.addSingleWorker(id_work, id_line, worker.value).then(data => {
          if (!data.ok) {
            this.$emit('showSnack', 'Errore collegamento database', 'error');
          }
          this.loadDate(this.date);
        });

      } else {
        // Worker not valid        
        this.$emit('showSnack', 'Operaio non valido', 'error');
        // Reset value       
        this.works.find(el => el.id_work == id_work).totalResources[index].id_worker_frontend = '';

        // Delete
        let lineToDelete = this.works.find(el => el.id_work == id_work).totalResources[index].id;

        this.$api.works.deleteSingleWorker(lineToDelete).then(data => {
          if (!data.ok) {
            this.$emit('showSnack', 'Errore collegamento database', 'error');
          }
          this.loadDate(this.date);
        });

        // Check if we delete entire row
        if (this.works.find(el => el.id_work == id_work).totalResources[index].id_vehicle_frontend == '')
          this.works.find(el => el.id_work == id_work).totalResources.splice(index, 1);        
      }

    },

    checkAllWorksHaveEmptyLine() {

      this.works.forEach(work => {
        if (work.totalResources.length == 0) {
          work.totalResources.push({
            id: '',
            id_work: null,
            id_worker_frontend: '',
            id_vehicle: null,
            id_vehicle_frontend: '',
          });
        } else if (work.totalResources[work.totalResources.length - 1].id_worker_frontend != '' || work.totalResources[work.totalResources.length - 1].id_vehicle_frontend != '') {
          // Check if at least one of the values is used so create new line
          work.totalResources.push({
            id: '',
            id_work: null,
            id_worker_frontend: '',
            id_vehicle: null,
            id_vehicle_frontend: '',
          });
        }
      });

    },

    addDailyWorker() {
      if (!this.addWorkerName || this.addWorkerName=='') {
        this.$emit('showSnack', 'Nome operaio non valido', 'error');        
        return;
      }

      this.$api.works.addDailyWorker(this.date, this.addWorkerName).then(data => {
        if (data.ok) {
          this.$emit('showSnack', 'Operaio inserito', 'success');
          this.showAddWorkerDialog = false;
          this.loadDate(this.date);
        } else {
          this.$emit('showSnack', 'Errore collegamento database', 'error');
        }
      });

    },

    /**
     * Adds car for current date only
     */
    addDailyVehicle() {

      if (!this.addVehicleName || this.addVehicleName=='') {
        this.$emit('showSnack', 'Nome automezzo non valido', 'error');        
        return;
      }

      this.$api.works.addDailyVehicle(this.date, this.addVehicleName).then(data => {
        if (data.ok) {
          this.$emit('showSnack', 'Automezzo inserito', 'success');
          this.showAddVehicleDialog = false;
          this.loadDate(this.date);
        } else {
          this.$emit('showSnack', 'Errore collegamento database', 'error');
        }
      });

    },

    deleteDailyWorker(id_worker) {
      this.$api.works.deleteDailyWorker(this.date, id_worker).then(data => {
        if (data.ok) {
          this.$emit('showSnack', 'Operaio eliminato', 'success');
          this.loadDate(this.date);
        } else {
          this.$emit('showSnack', 'Errore collegamento database', 'error');
        }
      });
    },

    deleteDailyVehicle(id_vehicle) {
      this.$api.works.deleteDailyVehicle(this.date, id_vehicle).then(data => {
        if (data.ok) {
          this.$emit('showSnack', 'Automezzo eliminato', 'success');
          this.loadDate(this.date);
        } else {
          this.$emit('showSnack', 'Errore collegamento database', 'error');
        }
      });
    },

    getTheme() {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light';
    },

    // Materials
    showMaterialsDialog(id_work) {

      this.materials.workId = id_work;

      this.$api.works.getMaterials(id_work).then(data => {
        if (data.ok) {

          // Remove "Camion"
          this.materials.materialsList = data.values.materials.filter(el => el.name!="Camion");

          // Normalize Avvitatore e Trapano, Ferri, Coperte
          let valuesToNormalize = ['Avvitatore e Trapano', 'Ferri', 'Coperte'];

          this.materials.materialsList.map(el => {
            if (valuesToNormalize.find(value => value == el.name)) {
              if (el.value_out !== '0' && el.value_out !== '1')
                el.value_out = '1';
            }
          });

          // Adjust order
          let finalOrder = [
                'Avvitatore e Trapano',
                'Coperte',
                'Ferri',
                'Scotch',
                'Coperte Matrimoniali',
                'Coperte Singole',
                'Scatole Barili',
                'Scatole Fragili',
                'Scatole Libri',
                'Bauletti',
                'Giornali',
                'Pluriball',
          ];

          let materialsSorted = [];

          finalOrder.forEach(material => {
            let index = this.materials.materialsList.findIndex(el => {
              return el.name==material;
            });
            
            if (index!==-1) {
              materialsSorted.push(this.materials.materialsList[index]);
            }
          })

          this.materials.materialsList = materialsSorted;

          this.materials.showPopup = true;
        } else {
          this.$emit('showSnack', 'Errore collegamento database', 'error');
        }
      });

    },

    saveMaterials() {
      if (!this.$refs.inOutMaterialsForm.validate())
        return;

      this.$api.works.saveMaterials(this.materials.workId, this.materials.materialsList).then(data => {
        if (data.ok) {          
          this.materials.showPopup = false;
          this.$emit('showSnack', 'Database aggiornato', 'success');
        } else {
          this.$emit('showSnack', 'Errore collegamento database', 'error');
        }
      });
    },

  },

  // Events
  mounted() {

    // Set default date as tomorrow
    //date
    const date = new Date();
    date.setDate(date.getDate() + 1)
    this.date = date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear();

    // Get date
    if (this.$route.params.date) {
      this.date = this.$route.params.date.replace(/-/g, '/');
      this.loadDate(this.date);
    }

    this.getResourcesLabels();
  },

}

</script>

<style scoped>

 .hour-color-light {
   color: #ec1c24;
   font-weight: bold;
 }

 .hour-color-dark {
   color: #ff9800;
   font-weight: bold;
 }

  .id-frontend-cell-light {
    background-color: #ff7;
    color: #f00;
  }

  .id-frontend-cell-dark {   
    color: #fa0;
    background-color: #222;
  }

  .text-color-light {
    color: #333;
  }

  .text-color-dark {
    color: #fff;
  }

  .work-text {
    font-size: 80%;
    color: #333 !important;
  }

  .list-text {
    font-size: 90% !important;
  }

  .table-client {
    border: 1px solid #ccc;
    border-radius: 0px;
  }

  .table-client-col-left {
    border-right: 1px solid #ccc;
  }

  .table-client-bottom {
    border-bottom: 1px solid #ccc;
  }

  .table-no-bottom {
    border-bottom: 0px;
  }

  .button-col {
    width: 12.5%;
    padding: 0px;
    padding-top: 5px;
    text-align: center;
  }

  .usedWorker {
    background-color: #5c5;
    color: #000;
  }

  .usedWorker-1 {
    background-color: #faa;
    color: #000;
  }

  .usedWorker-2 {
    background-color: #a6f;
    color: #000;
  }

  .usedVehicle {
    background-color: #5c5;
    color: #000;
  }

  .usedVehicle-1 {
    background-color: #faa;
    color: #000;
  }

  .usedVehicle-2 {
    background-color: #a6f;
    color: #000;
  }

  .workerCellHeader {
    background-color: #f7e4ff;
    color: #000;
  }

  .vehicleCellHeader {
    background-color: #c3ffff;
  }

  .vehicleCell {
    background-color: #c3ffff;
  }

  .workerCell {
    background-color: #f7e4ff;
    color: #000;
  }

  .dropZone {
    background-color: #afa;
    color: #050;
    text-align: center;
    border: 1px dashed #0f0;
  }

  .dropZoneHover {
    border: 1px dashed #f70;
    color: #f70;
    background-color: #fc9;  
  }

  .deleteButton {
    display: inline;
    cursor: pointer;
  }

  .leftListCell {
    font-size: 80% !important;
    padding: 0px !important;
    margin: 0px !important;
    height: 0px !important;
  }

  .referenceTable {
    font-size: 90% !important;
    padding: 0px !important;
  }

  .workerVehicleCard {
    padding: 0px !important;
  }

  .listTable {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .leftListBorderRight {
    border-right: 1px solid #ccc;
  }

  .darkBorderRight {
    border-right: 1px solid #000;
  }

  .windowListCell {
    font-size: 100% !important;
    height: 25px !important;
    padding: 2px !important;
  }

  .font-size-individual {
    font-size: 85%;
  }

  .worker-cell-light {
    background-color: #ffa;
  }

  .worker-cell-dark {
    background-color: #333;
  }

  .vehicle-cell-light {
    background-color: #cdf;
  }

  .vehicle-cell-dark {
    background-color: #555;
  }

  .h-35 {
    height: 35px !important;
    border: 0px !important;
  }

</style>

<style>
  .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 15px !important;
  }

  .v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix, .v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix, .v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 0px;
  }

  .dense-cell {
    font-size: 100% !important;
    height: 22px !important;
  }

  .less-line-height {
    line-height: 95%;
  }

  .workers-vehicles-left-list {
    font-size: 90%;
  }

  .whitespace {
    height: 20px !important;
  }
</style>