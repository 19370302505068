<script>
    export default {
      props: [
          'overlayResourcesB',
          'resourcesLabels',
          'resourcesValues',
          'resourcesAvailable',
      ],	  
    }
</script>

<template>
    <!-- Resources -->
    <v-dialog
      v-model="overlayResourcesB"
      scrollable
      max-width="800px"
    >
      <v-card light min-width="800">
        <v-card-title>Gestire risorse B</v-card-title>
        <v-card-text>

         <p>
          <b>Attenzione:</b> queste info hanno effetto solo per i clienti privati e non per enti e aziende
         </p>

          <v-simple-table>
            <thead>
              <tr>
                <th>
                  Risorsa
                </th>
                <th>
                  Quantità
                </th>
                <th>
                  Disp. / Totali
                </th>  
              </tr>
            </thead>
            <tbody>
              <tr v-for="(resource, index) in resourcesLabels" v-bind:key="index">
                <td>
                  {{resource.name}}
                </td>
                <td>
                  <v-text-field type="number" hide-details dense outlined v-model="resourcesValues[resource.id]" v-if="$store.getters.rules.works.edit"></v-text-field>
                  <span v-if="!$store.getters.rules.works.edit">
                    {{ resourcesValues[resource.id] }}
                  </span>
                </td>
                <td>
                  <span :class="(resourcesAvailable[resource.id]<0) ? 'red--text' : ''">
                    {{resourcesAvailable[resource.id]}}

                    <!-- Hide total amount if resource is Operai (Op) -->
                    <span v-if="resource.name != 'Op'">
                      / {{resource.ammount}}
                    </span>
                    </span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="$emit('close')">Annulla</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="$emit('save')" v-if="$store.getters.rules.works.edit">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

</template>