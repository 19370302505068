<template>
  <div>

      <v-container v-if="works.length>0">
        <v-row>

          <v-col cols="6" class="list-text">
            <v-row>
              <v-col>
                <h3>Operai</h3>
                <br />
                <div :draggable="(usedWorkers.find(el => el == worker.value)) ? 'false' : 'true'" @dragover.prevent @dragenter.prevent @dragstart="dragStart('worker', worker.value)" @dragend="dragEnd" v-for="(worker, index) in workers" v-bind:key="index" :class="(usedWorkers.find(el => el == worker.value)) ? 'usedWorker' : 'draggable'">
                  {{ worker.text }}
                  <v-divider></v-divider>
                </div>
              </v-col>
              <v-col>
                <h3>Automezzi</h3>
                <br />
                <div :draggable="(usedVehicles.find(el => el == vehicle.value)) ? 'false' : 'true'" @dragover.prevent @dragenter.prevent @dragstart="dragStart('vehicle', vehicle.value)" @dragend="dragEnd" v-for="(vehicle, index) in vehicles" v-bind:key="index" :class="(usedVehicles.find(el => el == vehicle.value)) ? 'usedVehicle' : 'draggable'">
                  {{ vehicle.text }}
                  <v-divider></v-divider>
                </div>
              </v-col>
            </v-row>
          </v-col>    

          <v-col>
            <v-row class="ml-2">

              <v-col cols="12" v-for="(work, index) in works.filter(el => el.id_work==currentWorkId)" v-bind:key="index">
              <v-card class="mx-auto mb-5" max-width="400">

                <v-card-actions>
                  <v-row style="padding-left: 6px; padding-right: 10px">

                    <v-col class="button-col">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                          @click="showOrderNotes(work.id_order)"
                          :disabled="!work.notes_order"
                          color="info" icon v-bind="attrs" v-on="on">
                          <v-icon>
                            mdi-text-box-outline
                          </v-icon></v-btn>
                        </template>
                        <span>
                          Note ordine
                        </span>
                      </v-tooltip>
                    </v-col>

                    <v-col class="button-col">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                          @click="showOrderNotesPatrizio(work.id_order)"
                          :disabled="!work.notes_patrizio"
                          color="purple" icon v-bind="attrs" v-on="on">
                          <v-icon>
                            mdi-clipboard-account-outline
                          </v-icon></v-btn>
                        </template>
                        <span>
                          Note Patrizio
                        </span>
                      </v-tooltip>
                    </v-col>

                    <v-col class="button-col">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                          @click="showWorkNotes(work.id_work)"
                          :disabled="!work.notes_work"
                          color="green" icon v-bind="attrs" v-on="on">
                          <v-icon>
                            mdi-message-bulleted
                          </v-icon></v-btn>
                        </template>
                        <span>
                          Note lavorazione
                        </span>
                      </v-tooltip>
                    </v-col>

                    <v-col class="button-col">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                          @click="$router.push({path: '/documents/'+ work.id_appointment })"
                          color="info" icon v-bind="attrs" v-on="on">
                          <v-icon>
                            mdi-file
                          </v-icon></v-btn>
                        </template>
                        <span>
                          Allegati
                        </span>
                      </v-tooltip>
                    </v-col>

                    <!--
                      Workers and vehicles buttons turned off
                    <v-col class="button-col">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                          @click="showEditWorkers(work.id_work)"
                          color="orange" icon v-bind="attrs" v-on="on">
                          <v-icon>
                            mdi-account-hard-hat
                          </v-icon></v-btn>
                        </template>
                        <span>
                          Assegna operai
                        </span>
                      </v-tooltip>
                    </v-col>

                    <v-col class="button-col">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                          @click="showEditVehicles(work.id_work)"
                          color="blue-grey" icon v-bind="attrs" v-on="on">
                          <v-icon>
                            mdi-truck
                          </v-icon></v-btn>
                        </template>
                        <span>
                          Assegna automezzi
                        </span>
                      </v-tooltip> 
                    </v-col>
                    -->

                    <v-col class="button-col">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <a :href="'/api/works/pdf/' + work.id_work" target="_blank">
                            <v-btn
                            color="error" icon v-bind="attrs" v-on="on">
                            <v-icon>
                              mdi-file-pdf-outline
                            </v-icon></v-btn>
                          </a>
                        </template>
                        <span>
                          Stampa scheda lavorazione 
                        </span>
                      </v-tooltip> 
                    </v-col>

                    <v-col class="button-col">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                          @click="showResources(work.id_work)"
                          color="info" icon v-bind="attrs" v-on="on">
                          <v-icon>
                            mdi-hammer-screwdriver
                          </v-icon></v-btn>
                        </template>
                        <span>
                          Risorse
                        </span>
                      </v-tooltip> 
                    </v-col>

                  </v-row>
                </v-card-actions>

                  <v-card-text class="work-text">                    
                    <v-row>
                      <v-col>
                        <h3>
                          {{work.name}} {{work.surname}}
                        </h3>

                        <v-simple-table class="table-client">
                          <tr>
                            <td class="table-client-col-left pl-1 table-client-bottom">
                              Via
                            </td>
                            <td class="pl-1 table-client-bottom">
                              {{work.address}}
                            </td>
                          </tr>
                          <tr>
                            <td class="table-client-col-left pl-1 table-client-bottom">
                              Città
                            </td>
                            <td class="pl-1 table-client-bottom">
                              {{work.city}} ({{work.province}})
                            </td>
                          </tr>
                          <tr>
                            <td class="table-client-col-left pl-1">
                              Tel.
                            </td>
                            <td class="pl-1">
                              {{ (work.phone) ? work.phone : '-' }} {{ (work.cellphone) ? work.cellphone : '-' }}  
                            </td>
                          </tr>
                        </v-simple-table>
                      </v-col>
                    </v-row>

                    <v-row @mouseleave="showDeleteWork=false; showDeleteVehicle=false">
                      <v-col>
                        <v-simple-table class="table-client table-no-bottom">
                          <tr>
                            <td class="table-client-col-left pl-1 table-client-bottom workerCellHeader" width="50%">
                              <b>Operai</b>
                            </td>
                            <td class="pl-1 table-client-bottom vehicleCellHeader">
                              <b>Automezzi</b>
                            </td>
                          </tr>
                          <tr v-for="(resource, index) in work.totalResources" v-bind:key="index">
                            <td 
                              @drop="(!resource.id_worker) ? droppedInline(work.id_work, resource.id) : ''; $event.target.classList.remove('dropZoneHover')" @dragover.prevent @dragenter.prevent
                              class="table-client-col-left pl-1 table-client-bottom workerCell" 
                              @dragenter="(draggingType=='worker' && !resource.id_worker) ? $event.target.classList.add('dropZoneHover') : ''" 
                              @mouseout="$event.target.classList.remove('dropZoneHover')"
                              @dragleave="$event.target.classList.remove('dropZoneHover')"
                              @mouseover="showDeleteWork=resource.id; showDeleteVehicle=false"
                            >
                              {{ (resource.id_worker) ? (workers.find(el => el.value==resource.id_worker).text) : '-' }}
                              <div v-if="showDeleteWork==resource.id && resource.id_worker" class="deleteButton" @click="deleteWorker(resource.id)">
                                <v-icon color="red" small>mdi-close</v-icon>
                              </div>
                            </td>
                            <td
                              @drop="(!resource.id_vehicle) ? droppedInline(work.id_work, resource.id) : ''; $event.target.classList.remove('dropZoneHover')" @dragover.prevent @dragenter.prevent 
                              class="pl-1 table-client-bottom vehicleCell" 
                              @dragenter="(draggingType=='vehicle' && !resource.id_vehicle) ? $event.target.classList.add('dropZoneHover') : ''"
                              @mouseout="$event.target.classList.remove('dropZoneHover')"
                              @dragleave="$event.target.classList.remove('dropZoneHover')"
                              @mouseover="showDeleteWork=false; showDeleteVehicle=resource.id"
                            >
                              {{ (resource.id_vehicle) ? (vehicles.find(el => el.value==resource.id_vehicle).text) : '-' }}
                              <div v-if="showDeleteVehicle==resource.id && resource.id_vehicle" class="deleteButton" @click="deleteVehicle(resource.id)">
                                <v-icon color="red" small>mdi-close</v-icon>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="draggingElement" @drop="droppedNew(work.id_work)" @dragover.prevent @dragenter.prevent
                            @dragenter="$event.target.classList.add('dropZoneHover')"
                            @dragover="$event.target.classList.add('dropZoneHover')"
                            @dragleave="$event.target.classList.remove('dropZoneHover')"
                            @mouseout="$event.target.classList.remove('dropZoneHover')"
                          >
                            <td
                              colspan="2"
                              class="dropZone" 
                            >
                              + Aggiungi
                            </td>
                          </tr>
                        </v-simple-table>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </v-container>  
  

  </div>
</template>

<script>

import {workers, vehicles} from '../global';

export default {

  props: {
    workId: String,
    workDate: String,
  },

  data() {
    return {
  
      // These variables manages what delete button to show
      showDeleteWork: false,
      showDeleteVehicle: false,

      draggingElement: false,
      draggingType: false,
      draggingId: false,

      /* Resources */
      currentWorkId_resource: false,
      resourcesValues: [],
      resourcesAvailable: [],
      resourcesLabels: [],
      overlayResources: false,

      overlayEditWorkers: false,
      overlayEditVehicles: false,
      currentWorkId: this.workId,
      currentWorkDate: this.workDate,
      selectedVehicles: [],
      selectedWorkers: [],

      // Define already used workers and vehicles
      usedVehicles: [],
      userdWorkers: [],

      dialog: {
        show: false,
        title: '',
        content: '',
      },
      
      date: null,
      workers,
      vehicles,
      on: false,
      attrs: false,
      // debug
      //works:  [ { "id_work": "35", "id_order": "25", "id_appointment": "13", "name": "Manuel", "surname": "Gimenez", "phone": "03401493338", "cellphone": "15510271", "address": "Via Collegno 1", "zip": "10093", "city": "Collegno", "province": "TO", "workers": [ "2", "0", "4" ], "vehicles": [ "4", "6", "7", "9", "11" ] } ] ,
      works: [],

    }
  },

  methods: {


    getWorks() {
      if (!this.$refs.searchForm.validate())
        return;

      this.$router.push({path: '/dailyWorks/'+ this.date.replace(/\//g, '-') });
    },

    getResourcesLabels() {
      this.$api.works.getResourcesLabels().then(data => {
        this.resourcesLabels = data.values.resources;
      });               
    },

    showDialog(title, content) {
      this.dialog = {
        show: true,
        title,
        content
      };
    },

    showOrderNotes(id) {
      this.$api.orders.getFromId(id).then(data => {
        this.showDialog('Note ordine', data.values.order.notes);
      });    
    },

    showOrderNotesPatrizio(id) {
      this.$api.orders.getFromId(id).then(data => {
        this.showDialog('Note ordine - Patrizio', data.values.order.notes_patrizio);
      });    
    },

    showWorkNotes(id) {
      this.$api.orders.getWork(id).then(data => {
        this.showDialog('Note lavorazione', data.values.work.notes);
      });    
    },

    showResources(id) {
      this.currentWorkId_resource = id;
      // Load resources values for current work

      this.$api.orders.getWorkResources(id).then(data => {
        if (data.ok) {
          // Set all values to 0
          // Set availability by default to total ammount
          this.resourcesValues = [];
          this.resourcesAvailable = [];
          this.resourcesLabels.forEach(el => {
            this.resourcesValues[el.id] = 0;
            this.resourcesAvailable[el.id] = el.ammount;
          });        

          data.values.resources.forEach(el => {
            this.resourcesValues[el.id_resource] = el.value;
          });
          
          // Get used resources and then do available - used
          if (data.values.availability) {
            data.values.availability.forEach(el => {
              // This will show me all used resources during the day of current order
              /*
                Example
                  id_resource	  used 	
                  4 			      2
                  5 			      2
              */
              let resourceTotal = this.resourcesAvailable[el.id_resource];             
              this.resourcesAvailable[el.id_resource] = parseInt(resourceTotal) - parseInt(el.used);
            });
          }

          // Show dialog
          this.overlayResources = true;
        } else {
          this.$emit('showSnack', data.values.msg, 'Errore');
        }
      }); 
    },   

    showEditWorkers(id) {
      this.currentWorkId = id; 
      
      // Load workers for current work
      this.$api.works.getWorkers(this.currentWorkId).then(data => {
        if (data.ok) {
          this.selectedWorkers = data.values.workers;
        } else {
          this.$emit('showSnack', 'Errore: lista operai non non trovata', 'error');
        }
      });

      this.overlayEditWorkers = true;
    },

    confirmSelectedWorkers() {
      this.$api.works.setWorkers(this.currentWorkId, this.selectedWorkers).then(data => {
        if (data.ok) {
          this.overlayEditWorkers = false;
          this.$emit('showSnack', 'Lista operai aggiornata', 'success');
        } else {
          this.$emit('showSnack', 'Errore: lista operai non aggiornata', 'error');
        }

        this.loadDate(this.date);
      });
    },

    showEditVehicles(id) {
      this.currentWorkId = id; 
      
      this.$api.works.getVehicles(this.currentWorkId).then(data => {
        if (data.ok) {
          this.selectedVehicles = data.values.vehicles;
        } else {
          this.$emit('showSnack', 'Errore: lista automezzi non trovata', 'error');
        }
      });

      this.overlayEditVehicles = true;
    },

    confirmSelectedVehicles() {
      this.$api.works.setVehicles(this.currentWorkId, this.selectedVehicles).then(data => {
        if (data.ok) {
          this.overlayEditVehicles = false;
          this.$emit('showSnack', 'Lista automezzi aggiornata', 'success');
        } else {
          this.$emit('showSnack', 'Errore: lista automezzi non aggiornata', 'error');
        }

        this.loadDate(this.date);
      });
    },

    loadDate(date) {
      //this.works = [];
      this.oldDate = date;

      // Load works
      this.$api.dailyWorks.getWorks(date).then(data => {
        if (data.ok) {

          // Update list of used workers and vehicles
          this.usedVehicles = [];
          this.usedWorkers = [];

          data.values.works.forEach(work => {
            work.totalResources.forEach(resource => {
              if (resource.id_vehicle)
                this.usedVehicles.push(resource.id_vehicle);
              if (resource.id_worker)
                this.usedWorkers.push(resource.id_worker);
            });
          });

          this.works = data.values.works;
        } else {
          this.works = [];
        }
      });   
    },

    // Drag & Drop
    dragStart(type, id) {
      console.log(id);
      this.draggingType = type;
      this.draggingId = id;
      this.draggingElement = true;
    },

    dragEnd() {
      this.draggingElement = false;
    },

    dropped() {
   
    },

    // Element dropped into "Aggiungi". id = id_work
    droppedNew(id) {
      if (this.draggingType=='worker') {
        this.addWorker(id, false, this.draggingId);
      } else if (this.draggingType=='vehicle') {
        this.addVehicle(id, false, this.draggingId);
      }
    },

    // Element dropped over line. id = id_line
    droppedInline(workId, lineId) {
      if (this.draggingType=='worker') {
        this.addWorker(workId, lineId, this.draggingId);
      } else if (this.draggingType=='vehicle') {
        this.addVehicle(workId, lineId, this.draggingId);
      }
    },

    // Functions with workers and vehicles
    addWorker(workId, lineId, workerId) {
      this.$api.works.addSingleWorker(workId, lineId, workerId).then(data => {
        if (data.ok) {
          this.loadDate(this.date);
        }
      });
    },

    // Functions with workers and vehicles
    addVehicle(workId, lineId, vehicleId) {
      this.$api.works.addSingleVehicle(workId, lineId, vehicleId).then(data => {
        if (data.ok) {
          this.loadDate(this.date);
        }
      });
    },

    // Remove vehicles and workers from lines
    deleteWorker(lineId) {      
      this.$api.works.deleteSingleWorker(lineId).then(data => {
        if (data.ok) {
          this.loadDate(this.date);
        }
      });
    },

    deleteVehicle(lineId) {
      this.$api.works.deleteSingleVehicle(lineId).then(data => {
        if (data.ok) {
          this.loadDate(this.date);
        }
      });
    },

  },

  mounted() {

    // Get date
    if (this.currentWorkDate) {
      this.date = this.currentWorkDate.replace(/-/g, '/');
      this.loadDate(this.date);
    }

    this.getResourcesLabels();
  },

  watch: {
 
    workId: function (val) {
      this.currentWorkId = val;
      this.date = this.currentWorkDate.replace(/-/g, '/');
      this.loadDate(this.date);
    },

    workDate: function (val) {
      this.currentWorkDate = val;
      this.date = this.currentWorkDate.replace(/-/g, '/');
      this.loadDate(this.date);
    },

  },

}
</script>

<style scoped>
  .work-text {
    font-size: 80%;
    color: #333 !important;
  }

  .list-text {
    font-size: 90% !important;
  }

  .table-client {
    border: 1px solid #ccc;
    border-radius: 0px;
  }

  .table-client-col-left {
    border-right: 1px solid #ccc;
  }

  .table-client-bottom {
    border-bottom: 1px solid #ccc;
  }

  .table-no-bottom {
    border-bottom: 0px;
  }

  .button-col {
    width: 12.5%;
    padding: 0px;
    padding-top: 5px;
    text-align: center;
  }

  .draggable {
    cursor: pointer;
  }

  .usedWorker {
    background-color: #ffa;
    color: #888;
  }

  .usedVehicle {
    background-color: #ffa;
    color: #888;
  }

  .workerCellHeader {
    background-color: #f7e4ff;
    color: #000;
  }

  .vehicleCellHeader {
    background-color: #c3ffff;
  }

  .vehicleCell {
    background-color: #c3ffff;
  }

  .workerCell {
    background-color: #f7e4ff;
    color: #000;
  }

  .dropZone {
    background-color: #afa;
    color: #050;
    text-align: center;
    border: 1px dashed #0f0;
  }

  .dropZoneHover {
    border: 1px dashed #f70;
    color: #f70;
    background-color: #fc9;  
  }

  .deleteButton {
    display: inline;
    cursor: pointer;
  }
</style>
