<template>
    <div>

        <br />

      <v-card class="mx-auto" max-width="800">
            <v-card-title>
                Inserisci memo
            </v-card-title>
            <v-card-text>
                <v-form ref="newMemo" v-model="form" lazy-validation>


                    <v-text-field
                        v-model="values.title"
                        :rules="rules.required"
                        label="Titolo*"
                        required
                    ></v-text-field>

                    <template>
                        <v-menu
                            ref="calendarMenu"
                            v-model="calendarMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="values.date"
                                    label="Data*:"
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="rules.required && rules.date" 
                                    required
                                ></v-text-field>
                            </template>
                            <v-date-picker
                            ref="picker"
                            v-model="dateOriginalFormat"
                            min="1950-01-01"
                            @change="calendarSave"
                            locale="it"
                            :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </template>

                    <v-text-field
                        v-model="values.time"
                        label="Ora*:"
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules.required && rules.hour"
                        required
                    ></v-text-field>

                    <v-textarea
                        v-model="values.description"
                        label="Contenuto">
                    </v-textarea>
                    <h4>I campi contrassegnati * sono obbligatori.</h4>  
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" @click="$router.go(-1)">Annulla</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="insertMemo">Inserire memo</v-btn>
            </v-card-actions>
        </v-card>

    </div>
</template>

<script>
export default {

    data() {
        return {
            on: null,
            attrs: null,

            // Date select
            date: null,
            calendarMenu: false,
            dateOriginalFormat: '',
            hourMenu: false,

            rules: {
                required: [ value => (!value) ? 'Il campo non può essere vuoto.' : true ],
                hour: [ value => (!/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)) ? 'Formato non valido. (hh:mm)' : true ],
                date: [ value => (!/((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/.test(value)) ? 'Formato non valido. (gg-mm-aaaa)' : true ],
            },

            form: false,
            showForm: true,
            values: {
                title: null,
                date: null,
                description: null,
                time: null
            },

        }
    },

    methods: {
        calendarSave (date) {
            this.$refs.calendarMenu.save(date)
        },

        insertMemo() {
            if (!this.$refs.newMemo.validate())
                return;

            // Send new memo to database
            this.$emit('showProgress');
            this.$api.memo.createNew(this.values).then(data => {
                this.$emit('hideProgress');

                if (!data.ok) {
                    this.$emit('showSnack', data.values.msg, 'error');
                    return;

                }


                // Ok
                this.$refs.newMemo.reset();
                this.$emit('showSnack', 'Memo inserita', 'success');

            });
        },
    },

    watch: {
        dateOriginalFormat(val) {
          this.values.date = val.split('-').reverse().join('-');
      },
    },

    mounted() {

    }
}
</script>

<style scoped>

</style>
