<template>
  <div>  

    <br />

    <v-subheader><h1 class="accent--text">Log</h1></v-subheader>

      <div v-if="logs">
        <v-container>
          <v-row class="mt-2 mb-5">
              <v-col md="4" sm="12">                   
                  <v-select :items="users" v-model="selectedUser" placeholder="Segli utente">
                      <template slot="prepend">
                          Filtra:
                      </template>
                  </v-selecT>
              </v-col>
          </v-row>
          <v-row>
            <v-col>

                <v-data-table
                  :headers="headers"
                  :items="logs.filter(el => el.id_user==selectedUser || selectedUser==null)"
                  :items-per-page="30"
                  class="elevation-5"
                >

                </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </div>

  </div>
</template>

<script>

export default {
 
  data() {
    return {

      users: [
          {text:'Tutti', value:null}
      ],

      selectedUser: null,

      headers: [
        {text:'User', value:'user'},
        {text:'ID Operazione', value:'id_operation'},
        {text:'IP', value:'ip'},
        {text:'Note', value:'notes'},
        {text:'Data', value:'date_created'},
      ],

      logs: [],

    }
  },

  components: {

  },

  methods: {

    getUsers() {
      this.$api.accounts.getAll().then(data => {   
          data.accounts.forEach(account => {
              this.users.push({text:account.email, value:account.id});
          });
      });  
    },

    getLogs() {
      this.$emit('showProgress');
      this.$api.log.getAll().then(data => {   
          this.logs = data.values.logs;
          this.$emit('hideProgress');
      });
    },

  },

  // Events
  mounted() {
    this.getUsers();
    this.getLogs();
  },

}

</script>